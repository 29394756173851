export function checkNumber(num) {
  if (num > 0) {
    return num + " $";
  } else {
    return "-";
  }
}
export function calculateColumnTotal(data, columnName) {
  let total = 0;

  data.forEach((row) => {
    total += row[columnName];
  });

  return total;
}


export function getFirstLetters(inputString) {
  // Split the input string into an array of words

  if (inputString != undefined) {
    const words = inputString.split(' ');

    // Check the number of words
    if (words.length === 1) {
      // If there's only one word, return the first letter
      return words[0].charAt(0);
    } else if (words.length === 2) {
      // If there are two words, return the first letter of each word
      return words[0].charAt(0) + words[1].charAt(0);
    } else {
      // If there are more than two words, return an error message or handle as needed
      return 'Error: Input should have either one or two words.';
    }
  }
}

export const RenderHTMLString = (htmlString) => {
  // Your function logic goes here
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

export const RenderHTMLStringWithoutDiv = (htmlString) => {
  // Your function logic goes here
  return (
    <div className='row p-0 m-0' dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};
