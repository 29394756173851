import React, { useEffect, useState } from "react";
import PageTitle from "./PageTitle";
import { toast, ToastContainer } from "react-toastify";
import config from "../../Config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Form, Modal, Spinner } from "react-bootstrap";
import { NumberFormat } from "../../components/Dashboard/General/Functions";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import FranchiseCard from "./FranchiseCard";
import { AtSymbolIcon, BanknotesIcon, BuildingLibraryIcon, CalculatorIcon, ChatBubbleLeftRightIcon, CheckCircleIcon, ShieldCheckIcon, UserIcon, XCircleIcon } from "@heroicons/react/24/outline";

// cash/check

function FranchiseDashboard(props) {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [Dashboard_data, setDashboard_data] = useState('');
  const [PackageActivated, setPackageActivated] = useState(false);

  useEffect(() => {
    document.title = "MAG Franchise";
  }, []);

  let navigate = useNavigate();

  useEffect(() => {


    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetFranchiseDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setDashboard_data(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/franchise/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [show, setShow] = useState(false);
  const [UserData, setUserData] = useState('');
  const [VerifyLoading, setVerifyLoading] = useState(false);
  const [BalaceLoading, setBalaceLoading] = useState(false);
  const [UserVerify, setUserVerify] = useState(null);
  const [Verified, setVerified] = useState(null);
  const [Amount, setAmount] = useState('');
  const [Remarks, setRemarks] = useState('');
  const [CollectionType, setCollectionType] = useState('Cash');
  const [ChequeNo, setChequeNo] = useState('');
  const [BankName, setBankName] = useState('');

  const VerifyUser = () => {
    if (UserVerify != null) {

      setVerifyLoading(true);

      const downlineData = {
        Email_Address: UserVerify,
        AccessKey: AccessKey,
        UserID: UserID,
        Member_Type: 'Admin',
      };

      var api_config = {
        method: 'post',
        url: config.base_url + 'Users/GetUserInfo',
        headers: {
          'Content-Type': 'application/json'
        },
        data: downlineData
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            setUserData(response.data);
            setVerified(true);
            setVerifyLoading(false);
          }
          else {
            setVerified(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setVerifyLoading(false);
          }

        })
        .catch(function (error) {
          // console.log(error);
        });

    }
  }
  const handleSendBalace = () => {
    // setBalaceLoading(true);
    const Data = {
      Email_Address: UserVerify,
      AccessKey: AccessKey,
      UserID: UserID,
      ID: UserData.ID,
      AdminRemarks: Remarks,
      Amount: Amount,
      ChequeRemarks: '',
      CollectionType: CollectionType,
      ChequeNo: ChequeNo,
      BankName: BankName,
      ForPackage: PackageActivated,
    };
    // console.log(Data);
    var api_config = {
      method: 'post',
      url: config.base_url + 'UserCollection/AddCollection',
      headers: {
        'Content-Type': 'application/json'
      },
      data: Data
    };
    // console.log(Data, 'Data');

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setBalaceLoading(false);
        }

      })
      .catch(function (error) {
        // console.log(error);
      });


  }
  const valueNull = () => {
    setAmount('');
    setRemarks('');
    setUserData('');
    setVerified(null);
    setUserVerify(null);
    setCollectionType('');
    setChequeNo('');
    setBankName('');
    setPackageActivated(false);
  }

  return (
    <>
      <FranchiseLoginCheck />
      <ToastContainer />

      <Modal className="bs-modal" show={show} onHide={() => setShow(false)} onExit={valueNull}>
        <Modal.Header closeButton>
          <Modal.Title>Cash In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="verify d-flex gap-3 mb-3">
            <div className="icon-input m-0 w-100">
              <input type="text"
                value={UserVerify}
                onChange={(e) => {
                  setUserVerify(e.target.value);
                  setVerified(false);
                }}
                placeholder="Enter Username/Email"
                autoComplete="off"
              />
              <div className="icon">
                {
                  Verified ?
                    <CheckCircleIcon />
                    :
                    ''
                }
              </div>
            </div>
            <button
              onClick={VerifyUser}
              className="button button-outline-bs button-icon-bs input-height" style={{ borderRadius: '12px' }} type="button"
              disabled={Verified}
            >
              <span>{VerifyLoading ? 'Loading..' : 'Validate'}</span>
              <ShieldCheckIcon />
            </button>
          </div>
          {
            Verified ?
              <>
                <div className="icon-input">
                  <input type="text" placeholder='Name' disabled value={UserData.FullName} readOnly />
                  <div className="icon">
                    <UserIcon />
                  </div>
                </div>
                <div className="icon-input">
                  <input type="text" placeholder='Email Address' disabled value={UserData.Email_Address} readOnly />
                  <div className="icon">
                    <AtSymbolIcon />
                  </div>
                </div>
              </>
              :
              ''
          }
          {
            Verified &&
            <div className="text-center">
              <ul className="menu-option mb-3 mx-auto">
                <li>
                  <a className={`nav-link ${CollectionType == "Cash" ? "active" : ""}`} onClick={(e) => setCollectionType("Cash", e)} href="#">Cash</a>
                </li>
                <li>
                  <a className={`nav-link ${CollectionType == "Cheque" ? "active" : ""}`} onClick={(e) => setCollectionType("Cheque", e)} href="#">Cheque</a>
                </li>
              </ul>
            </div>
          }
          {
            Verified && CollectionType == "Cheque" ?
              <>

                <div className="icon-input">
                  <input type="text" placeholder='Bank Name' value={BankName} onChange={(e) => setBankName(e.target.value)} />
                  <div className="icon">
                    <BuildingLibraryIcon />
                  </div>
                </div>
                <div className="icon-input">
                  <input type="text" placeholder='cheque No' value={ChequeNo} onChange={(e) => setChequeNo(e.target.value)} />
                  <div className="icon">
                    <CalculatorIcon />
                  </div>
                </div>
              </>
              :
              ''
          }
          <div className="icon-input">
            <input type="text" placeholder='Amount' value={Amount} onChange={(e) => setAmount(e.target.value)} />
            <div className="icon">
              <BanknotesIcon />
            </div>
          </div>
          <div className="icon-input">
            <textarea type="text" placeholder='Remarks' value={Remarks} onChange={(e) => setRemarks(e.target.value)} />
            <div className="icon">
              <ChatBubbleLeftRightIcon />
            </div>
          </div>
          <div className={`d-flex ${(Verified && CollectionType == "Cash") ? 'justify-content-between' : 'justify-content-end'}  w-100 align-items-center text-end`}>
            {
              Verified && CollectionType == "Cash" ?
                <>
                  <Form.Check
                    inline
                    label="Package Activation"
                    checked={PackageActivated}
                    onChange={(e) => setPackageActivated(!PackageActivated)}
                    name="Package Activation"
                    type="checkbox"
                    id={`Package Activation`}
                  />
                </>
                :
                ''
            }

            <button className="button button-primary" onClick={handleSendBalace} disabled={BalaceLoading || !Verified}>
              {CollectionType === "Cheque" ? 'Pending Balance' : (CollectionType === 'Cash' && PackageActivated ? "Add Cash" : 'Send Balance')} {BalaceLoading ? '...' : ''}
            </button>

          </div>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>

          <div className="admin-title">
            <div className="row gy-3">
              <div className="col-md-6">
                <h4>Franchise Dashboard</h4>
              </div>
            </div>
          </div>
          <div className="admin-content">
            <div className="row">
              <div className="col-md-12">
                <div className="dash-bg-card mb-3">
                  <div className="row gy-3">
                    <div className="col-md-4">
                      <FranchiseCard
                        link="#/"
                        class="mb-0"
                        value={(Dashboard_data.BalanceAvailable)}
                        label="Available Balance"
                        icon="account_balance_wallet"
                      />
                    </div>
                    <div className="col-md-4">
                      <FranchiseCard
                        link="/franchise/received-balance"
                        class="mb-0"
                        value={(Dashboard_data.BalanceReceived)}
                        label="Total Received Balance"
                        icon="call_received"
                      />
                    </div>

                    <div className="col-md-4">
                      <FranchiseCard
                        link="/franchise/used-balance"
                        class="blue mb-0"
                        value={(Dashboard_data.BalanceUsed)}
                        label="Used Balance"
                        icon="motion_photos_paused"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="dash-bg-card mb-3">
                  <div className="row gy-3">
                    <div className="col-md-4">
                      <FranchiseCard
                        link="#/"
                        class="mb-0"
                        value={(Dashboard_data.CashBalance)}
                        label="Available Cash"
                        icon="money"
                      />
                    </div>

                    <div className="col-md-4">
                      <div className="franchise-card-popup">
                        <div className="popup-icon" onClick={() => setShow(true)}>
                          <span className="material-symbols-outlined">
                            add
                          </span>
                        </div>
                        <FranchiseCard
                          link="/franchise/cash-in-list"
                          class="mb-0"
                          value={(Dashboard_data.CashIn)}
                          label="Total Cash In"
                          icon="trackpad_input"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="franchise-card-popup">
                        <Link to={`/franchise/cash-out-form`} className="popup-icon" onClick={() => setShow(true)}>
                          <span className="material-symbols-outlined">
                            add
                          </span>
                        </Link>
                        <FranchiseCard
                          link="/franchise/cash-out-list"
                          class="mb-0"
                          value={(Dashboard_data.CashOut)}
                          label="Cash Out"
                          icon="shopping_cart_checkout"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <FranchiseCard
                  link="/franchise/withdrawal-dashboard/success"
                  class="blue"
                  value={(Dashboard_data.WithdrawalPaid)}
                  label="withdrawal Paid"
                  icon="payments"
                />
              </div>
              <div className="col-md-6">
                <FranchiseCard
                  link="/franchise/withdrawal-dashboard/initiated"
                  class="blue"
                  value={(Dashboard_data.WithdrawalPending)}
                  label="withdrawal Pending"
                  icon="hdr_strong"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FranchiseDashboard;

