import React from 'react';
import { Link } from 'react-router-dom';

function DashCard(props) {
    return (
        <>
            <Link to={props.link} className={`dash-card ${props.color}`}>
                <div className="content">
                    <p>{props.label}</p>
                    <h3>{props.currency} {props.value}</h3>
                </div>
                <div className="icon">
                    {props.icon}
                </div>
            </Link>
        </>
    );
}

export default DashCard;