import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import RewardMenu from './RewardMenu';
import TransactionMenu from './TransactionMenu';
import BsPagination from '../BsPagination';
import Config from '../../../Config';
import LoginCheck from '../General/LoginCheck';


function VoucherRedeemed(props) {

    const [userName, setuserName] = useState('ali imran');
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);



    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Wallet/GetOtherRewards',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };
 

        axios(api_config)
            .then(function (response) {
                setLedger(response.data.ListofOtherReward);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {

                    return (
                        <tr key={index}>
                            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                            <td>{moment(item.Date).format('DD/MM/YYYY')}</td>
                            <td>{item.Message}</td>
                            <td>{item.Mode}</td>
                            <td>PKR {item.RewardAmount}</td>
                            <td>Paid</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }
    }

    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <RewardMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Date</th>
                                <th>Message</th>
                                <th>Mode</th>
                                <th>Reward Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="6">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {renderLedger()}
                                    </>
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>

        </>
    );
}

export default VoucherRedeemed;