import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { Modal } from "react-bootstrap";
import { ArrowLongLeftIcon, ArrowRightCircleIcon, CheckCircleIcon, PaperAirplaneIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { JsonToExcel } from "react-json-to-excel";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";

function PendingDeposit(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  //   const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [companyName, setcompanyName] = useState("All");
  const [status, setStatus] = useState("Pending");
  const [paymentMode, setpaymentMode] = useState("All");

  const [GrandTotal, setGrandTotal] = useState('');
  const [ExcelArray, setExcelArray] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paymode, setPaymode] = useState("BTC");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const [showFilters, setShowFilters] = useState(true);
  const [ledgerOld, setLedgerOld] = useState([]);

  const [isFilteredByDate, setisFilteredByDate] = useState(false);

  const [show, setShow] = useState(false);
  const [DepositAmount, setDepositAmount] = useState('');
  const [DepositTID, setDepositTID] = useState('');
  const [DepositID, setDepositID] = useState('');
  const [Reference, setReference] = useState('');
  const [ReferenceValidate, setReferenceValidate] = useState(null);
  const [LoadingSuccess, setLoadingSuccess] = useState(false);
  const [LoadingReject, setLoadingReject] = useState(false);
  const [comment, setcomment] = useState('');


  let navigate = useNavigate();
  useEffect(() => {
    document.title = "Mag Holdings Admin";

    // setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      PaymentCompany: companyName,
      Status: status,
      PaymentMode: paymentMode,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetBankDeposits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          // console.log(response.data)
          setGrandTotal(response.data);
          setLedger(response.data.ListofRecords);
          setLedgerOld(response.data.ListofRecords)

          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          //    console.log(response.data.totalRecords);
        } else if (
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters") ||
          response.data.status_code === 0
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, status, paymode, companyName, status, paymentMode, LoadingSuccess, LoadingReject]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };
  const handleReference = (page) => {


    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      PAYMENT_BATCH_NUM: Reference,

    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/ValidateBankSlip",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data)
        if (response.data.status_code === 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setReferenceValidate(true);
        } else if (
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setReferenceValidate(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };



  const handleClose = () => setShow(false);
  const handleShow = (ID, DepositTID, DepositAmount, Reference) => {
    setShow(true);
    setDepositID(ID);
    setDepositTID(DepositTID);
    setDepositAmount(DepositAmount);
    setReference(Reference);
  };
  const ApprovedDeposit = () => {
    const confirm = window.confirm(`Are you sure you want to proceed with Approved status?`);
    if (confirm) {
      setLoadingSuccess(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: DepositID,
        Amount: DepositAmount,
      };

      // console.log(data)

      var api_config = {
        method: "post",
        url: config.base_url + "Users/SuccessBankDeposit",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code === 1) {
            toast.success("Approved Successfully !", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setShow(false);
            setLoadingSuccess(false);
          } else if (
            response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters"
          ) {
            toast.error("Failed  To Approve !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key."
          ) {
            navigate("/admin/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

  };
  const RejectedDeposit = () => {
    const confirm = window.confirm(`Are you sure you want to proceed with Rejected status?`);
    if (confirm) {
      setLoadingReject(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: DepositID,
        AdminRemarks: comment,
      };

      console.log(data)

      // Failed................
      var api_config = {
        method: "post",
        url: config.base_url + "Users/RejectBankDeposit",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          // console.log(response.data)
          if (response.data.status_code === 1) {
            setLoadingReject(false);
            toast.error("Cancled Successfully !", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setShow(false);
          } else if (
            response.data.status_code === 0 &&
            response.data.status_message === "Failed to cancel the request!"
          ) {
            toast.error("Failed  To Reject !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            (response.data.status_code === 0 &&
              response.data.status_message === "Invalid Access Key.") ||
            (response.data.status_code === 2 &&
              response.data.status_message === "Invalid Request Parameters")
          ) {
            navigate("/admin/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };
  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY HH:mm")}</td>
            <td>{item.User_Name}</td>
            <td>{item.PaymentID}</td>
            <td>{item.PAYMENT_BATCH_NUM}</td>
            <td>{item.AmountUSD} PKR</td>
            <td>{item.Message}</td>
            <td>{item.Status}</td>
            <td>
              <ul
                className="inline-action"
              >
                <li>
                  <button
                    onClick={(ID, DepositTID, DepositAmount, Reference) => { handleShow(item.ID, item.PaymentID, item.AmountUSD, item.PAYMENT_BATCH_NUM) }}
                    className="primary "
                  >
                    <span className="material-symbols-outlined">
                      view_in_ar
                    </span>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };


  // filter by date ...................

  const filterByDate = (e) => {
    let date = moment(e.target.value).format("MM DD YYYY");

    if (date != "") {
      let member = ledgerOld.filter(
        //console.log(moment(member.Transaction_Date).format('MM DD YYYY, h:mm:ss a'))
        (member) => moment(member.Created_Date).format("MM DD YYYY") == date
      );

      setLedger(member);
    } else {
      setLedger(ledgerOld);
    }

    setisFilteredByDate(true);
  };
  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      // console.log("filtered ", filteredArray);

      setLedger(filteredArray);
    } else {
      setLedger(ledgerOld);
    }
  };

  const handlenull = () => {
    setReferenceValidate(null);
    setcomment('');
  }


  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: 20000,
      PaymentCompany: companyName,
      Status: status,
      PaymentMode: paymentMode,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetDeposits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("response",response.data.ListofRecords)
        let data = response.data.ListofRecords;
        let Records = response.data.totalRecords;
        let count = 1;
        const listOfLedgerNew = data.map(({ ...item }) => ({
          Serial_No: Records - count++ + 1,
          Created_Date: moment(item.Created_Date).format("DD/MM/YYYY HH:mm"),
          Updated_Date: moment(item.Updated_Date).format("DD/MM/YYYY HH:mm"),
          Username: item.User_Name,
          Transaction_ID: item.PaymentID,
          Bank_Reference: item.PAYMENT_BATCH_NUM,
          Total_deposit_PKR: "PKR " + item.AmountUSD,
          Message: item.Message,
          Status: item.Status,
        }));
        setExcelArray(listOfLedgerNew)
      })
      .catch(function (error) {
      });
  }, [pageNo, pageSize, status, paymode, companyName, status, paymentMode, LoadingSuccess, LoadingReject]);


  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <Modal
        className="modal-theme modal-lg p-0"
        show={show} onHide={handleClose}
        onExit={handlenull}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deposit Slip</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <div className="row align-items-end">
            <div className="col-md-6">
              <img
                src={config.root + `Personal/MemberImages/Deposits/` + DepositTID + "DepositSlip" + ".jpg"}
                className="img-fluid"
                alt="Attachment"
              />
            </div>
            <div className="col-md-6">





              <div className="form_default my-3 position-relative">
                <div className="icon-form-group">
                  <label htmlFor="">Bank Reference</label>
                  <div className="icon-input">
                    <input type="text"
                      className="form-control"
                      defaultValue={Reference}
                    />
                    <div className="icon">
                      {ReferenceValidate == null ?
                        <button className="button button-primary button-icon-bs" onClick={handleReference}>Validate</button>
                        : ReferenceValidate == true ?
                          <CheckCircleIcon />
                          :
                          <XCircleIcon />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_default my-3 position-relative">
                <div className="form-group">
                  <label htmlFor="">Admin Remarks</label>
                  <div className="inputwrap">
                    <textarea type="text"
                      className="form-control"
                      onChange={(e) => setcomment(e.target.value)}
                      value={comment}
                    />
                  </div>
                </div>

              </div>
              <div className="form_default my-3 position-relative">
                <div className="form-group">
                  <label htmlFor="">Transfer/Modify Amount </label>
                  <div className="inputwrap">
                    <input type="text"
                      className="form-control"
                      defaultValue={DepositAmount}
                      onChange={(e) => setDepositAmount(e.target.value)}
                      value={DepositAmount}
                    />
                  </div>
                </div>

              </div>
              <div className="button-row justify-content-between">
                <button className="button button-primary"
                  disabled={LoadingReject}
                  onClick={RejectedDeposit}>
                  Rejected {LoadingReject ? '...' : ''}
                </button>
                <button className="button button-primary" onClick={ApprovedDeposit}
                  disabled={LoadingSuccess}
                >
                  Approved {LoadingSuccess ? '...' : ''}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              BANK DEPOSIT
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/deposit-dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3 gy-3 align-items-end">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search by Bank</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={companyName}
                          onChange={(e) => setcompanyName(e.target.value)}
                        >
                          <option value="All" selected>All</option>
                          <option value="NowPayments">Meezan  Bank</option>
                          <option value="CoinPayments">UBL Bank</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search by Status</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="Success"> Success </option>
                          <option value="Pending"> Pending </option>
                          <option value="Failed"> Failed </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Select Payment Mode</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={paymentMode}
                          onChange={(e) => setpaymentMode(e.target.value)}
                        >
                          <option value="All"> All </option>
                          <option value="">Internal Transfer </option>
                          <option value="">Cash Deposit</option>
                          <option value="">Cheque Deposit</option>
                          <option value="">Franchise Deposit</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default me-5 position-relative">
                      <div className="form-group search_box">
                        <label htmlFor="">Search Member</label>
                        <div className="inputwrap">
                          <input type="text"
                            className="form-control"
                            placeholder="Find by Username/Email/Member ID"

                          // onChange={SearchMembers}
                          />
                          <span className="material-symbols-outlined">search</span>
                        </div>
                      </div>
                      {
                        // SearchdMembers.length > 0 &&
                        <ul className="tree_search_wrap shadow-sm" id="searched-members">
                          {/* {renderSearchedMembers()} */}
                        </ul>
                      }
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <ul className="downloads">
                      <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                        <Link target="_blank"
                          to={`/admin/deposit-dashboard/pending-deposit-pdf/?companyName=${companyName}&status=${status}&paymentMode=${paymentMode}&StartDate=${StartDate}&EndDate=${EndDate}`}>PDF</Link>
                      </li>
                      <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                        <JsonToExcel
                          title="EXCEL"
                          data={ExcelArray}
                          fileName="BANK PENDING DEPOSIT"
                          btnClassName="excel-button"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th>Username</th>
                  <th>Transaction ID</th>
                  <th>Bank Reference</th>
                  <th>Total deposit PKR</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Slip</th>
                </tr>
              </thead>
              <tbody>



                <tr className="filter-row">
                  <td></td>


                  <td>
                    <div className="d-flex">
                      <div className="inputwrap">
                        <input
                          type="date"
                          style={{ width: "180px" }}
                          onChange={filterByDate}
                          className="form-control"
                        />
                      </div>
                      {isFilteredByDate === true && (
                        <button
                          type="button"
                          className="button p-0 bg-transparent"
                          onClick={() => {
                            setisFilteredByDate(false);
                            setLedger(ledgerOld);
                          }}
                        >
                          <span
                            style={{ lineHeight: "unset" }}
                            className="material-symbols-outlined"
                          >
                            close
                          </span>
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        style={{ width: "120px" }}
                        placeholder="Username"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        style={{ width: "150px" }}
                        type="text"
                        placeholder="Transaction ID"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Bank Reference"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Total deposit PKR"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Message"
                        className="form-control"
                        rea
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Status"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Slip"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </td>
                </tr>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                    {renderLedger()}
                    {
                      listOfLedger != null && listOfLedger.length > 0 &&
                      <>
                        <tr style={{ borderButtom: "none !important" }}>
                          <th colSpan={4} className="border-bottom-0 pt-3"></th>
                          <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'AmountUSD'))}</th>
                          <td className="border-bottom-0 pt-3"></td>
                        </tr>
                        <tr className="" style={{ borderButtom: "1px solid !important" }}>
                          <th className="pt-3" colSpan={'4'}></th>
                          <th className="text-center">Grand Total: </th>
                          <th>PKR {NumberFormat(GrandTotal.grandTotal)}</th>
                          <td style={{ borderButtom: "1px solid !important" }}></td>
                        </tr>
                      </>
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PendingDeposit;
