import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import bonus from "../../../assets/images/admin/icons/bonus.png";
import global_share from "../../../assets/images/admin/icons/global_share.png";
import AdminRightCheck from "../AdminRightsCheck";

import AdminHeader from "../AdminHeader";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function OtherRewardBonusDashboard() {
  return (
    <>
    <AdminRightCheck /> 

      <ToastContainer />
      <PageTitle title="OTHER REWARDS TRANSACTIONS" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="/admin/other-reward-bonus/list"
              class="green"
              value="50$"
              label="CASHBACK
              BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="12"
              label="INVESTUS POOL
              BONUS
"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="50$"
              label="LIFESTYLE
              BONUS
              "
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="10$"
              label="GLOBAL SHARE"
              icon={global_share}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="90%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="4$"
              label="INVESTUS
              BONUS"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="60%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          

         
        </div>
      </div>
    </>
  );
}

export default OtherRewardBonusDashboard;
