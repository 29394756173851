import React from 'react';
import { NavLink } from 'react-router-dom';

function ActiveWalletMenu(props) {
    return (
        <>
            <ul className="menu-option">
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/smart-wallet/direct-bonus">Direct Bonus</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/smart-wallet/referral-bonus" >Referral Bonus</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/smart-wallet/cashback-bonus">Cashback Bonus</NavLink>
                </li>
            </ul>
        </>
    );
}

export default ActiveWalletMenu;