import React, { useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import imgCoins from "../../../assets/images/icons/coins.svg"
import imgTime from "../../../assets/images/icons/time.svg"
import imgAgreement from "../../../assets/images/icons/agreement.svg"
import imgWallet from "../../../assets/images/icons/wallet.svg"
import config from '../../../Config';

function DetailModal(props) {

    const [isCopied, setIsCopied] = useState(false);

    return (
        <>
            <Modal size="xl" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.status_message}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-3">
                                <div className="col-md-8">
                                    <h3 className="card-title-dash">How to Pay?</h3>
                                    <ol className="text-muted" style={{ fontSize: ".9rem" }}>
                                        <li>Copy the below wallet address and pay through your application of the selected currency wallet. After payment, the transfer process will be started. It will take 30 minutes to 2 hours depending upon
                                            the currency used for transfer.</li>
                                        <li>
                                            The payment will be automatically transferred back to your account.&nbsp;<strong>However if there is any delay,
                                                go to into <a href="#"> previous request </a> options and click on check status button.
                                            </strong>
                                        </li>
                                        <li>
                                            Thank you for sending money in ictrader
                                        </li>
                                    </ol>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="dash-icon-box bg-red mb-3">
                                                <div className="icon">
                                                    <img src={imgCoins} alt="" />
                                                </div>
                                                <div className="info">
                                                    <p>Payment ID</p>
                                                    <h1>
                                                        {props.Payment_ID}
                                                    </h1>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="dash-icon-box bg-red mb-3">
                                                <div className="icon">
                                                    <img src={imgTime} alt="" />
                                                </div>
                                                <div className="info">
                                                    <p>Payment Status</p>
                                                    <h1>
                                                        {props.Payment_Status}
                                                    </h1>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6  mb-3">
                                            <div className="dash-icon-box bg-red mb-3">
                                                <div className="icon">
                                                    <img src={imgAgreement} alt="" />
                                                </div>
                                                <div className="info">
                                                    <p>Reference No</p>
                                                    <h1>
                                                        {props.ID}
                                                    </h1>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6  mb-3">
                                            <div className="row align-items-center">
                                                <div className="col-md-6"><img src="/Personal/QRCodes/PN292.jpg" id="imgtransferCode" style={{ width: "150px" }} /></div>
                                                <div className="col-md-6">
                                                    <button id="copyImageLink" type="button" className="copy-link " data-link="https://www.ictrader.co/Personal/QRCodes/PN292.jpg">COPY QR</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="dash-icon-box bg-red mb-3">
                                                <button style={{ position: "absolute", top: "10px", right: "10px" }} type="button" className="copy-link button button-secondary"
                                                    onClick={
                                                        () => {
                                                            navigator.clipboard.writeText(props.Pay_Address);
                                                            setIsCopied(true);
                                                        }

                                                    }
                                                >{isCopied ? "COPIED" : "COPY ADDRESS"}</button>
                                                <div className="icon">
                                                    <img src={imgWallet} alt="" />
                                                </div>
                                                <div className="info">
                                                    <p>Wallet Address</p>
                                                    <div className="wallet-address">{props.Pay_Address}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-box">
                                        <p>Selected Currency</p>
                                        <div className="row mt-2 align-items-center">
                                            <div className="col-auto">
                                                <img src={config.path + "/images/coins/" + props.Pay_Currency + ".png"} style={{ width: "40px" }} />
                                            </div>
                                            <div className="col-auto">
                                                <h4 className="m-0">
                                                    {props.Pay_Currency}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <p>
                                            Transfer Amount in {props.Pay_Currency}
                                        </p>
                                        <h4>
                                            {props.Amount}
                                        </h4>
                                    </div>
                                    <div className="info-box">
                                        <p>
                                            Estimated Transaction Fee
                                        </p>
                                        <h4>
                                            {props.Transaction_Fee}
                                        </h4>
                                    </div>
                                    <div className="info-box">
                                        <p>
                                            Transferable Amount in USD (Estimated)
                                        </p>
                                        <h4>
                                            {props.Amount_Received}
                                        </h4>
                                    </div>
                                    <div className="mt-3">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default DetailModal;