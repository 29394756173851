import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Config from '../../Config';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const PrintAddress = () => {

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    const param = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        document.title = "MAG Franchise";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: param.id
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: Config.base_url + "Users/GetMemberKYC",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log('response', response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data);
                    setLoading(false);
                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate('franchise/login')
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [isUpdated]);


    return (
        <>
            <ToastContainer />
            <div className="page">

                {loading ?
                    <Spinner animation="border" role="status"></Spinner>
                    :
                    <div>
                        <h3>{listOfLedger.FirstName} {listOfLedger.LastName}</h3>
                        {listOfLedger.FatherName == null ? '' :
                            <h3>Father Name: {listOfLedger.FatherName}</h3>
                        }
                        <h3>Address: {listOfLedger.Address}</h3>
                        <h3>Mobile No: {listOfLedger.MobileNo}</h3>
                    </div>
                }
            </div>
        </>
    )
}

export default PrintAddress