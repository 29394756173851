import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo-login.png";
import { Form } from 'react-bootstrap';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink, useLocation } from "react-router-dom";
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/partner/login-bg.png';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import { Eye_icon } from "../Dashboard/UserControls/Icons";
import { generateRandomString } from "../Dashboard/General/Functions";
import { Eye_icon, Eye_Off, IconCancel, IconDone } from "../Dashboard/UserControls/Icons";
import { ArrowLeftEndOnRectangleIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon, SparklesIcon, UserCircleIcon } from "@heroicons/react/24/outline";


function ResetPassword(props) {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Confirmpassword, setConfirmpassword] = useState("");
    const [password, setPassword] = useState("");
    const [IsVerified, setIsVerified] = useState(false);
    const [IsPasswordChanged, setIsPasswordChanged] = useState(false);
    const [Passwordvalid, setPasswordvalid] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);




    let navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const SecurityCode = searchParams.get("AuthorizationCode");
    const UserName = searchParams.get("UserName");


    useEffect(() => {
        document.title = "Reset Password";




        if (SecurityCode && UserName) {
            setLoading(true);
            const data = {
                AuthorizationCode: SecurityCode,
                UserName: UserName,

            };


            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetResetUserInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    //console.log(response.data);

                    if (response.data.status_code == 1) {
                        setIsVerified(true);
                        setLoading(false);
                    }
                    else {
                        setIsVerified(false);
                        setLoading(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });


                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }
        else {
            setIsVerified(false);
            setLoading(false);
        }




    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            if (password != Confirmpassword) {

                toast.error('Password did\'t match ', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

            else {
                setLoading(true);
                const data = {
                    UserName: UserName,
                    NewPassword: event.target.Confirmpassword.value,
                    AuthorizationCode: SecurityCode,
                    UserName: UserName,
                };



                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/ChangePasswordWithoutKey',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(api_config)
                    .then(function (response) {


                        // console.log(response.data);
                        if (response.data.status_code == 1) {


                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            setIsPasswordChanged(true);


                            //navigate('/dashboard/home');


                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setIsPasswordChanged(false);
                        }

                        setLoading(false);


                    })
                    .catch(function (error) {
                        setLoading(false);
                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });

            }

        }

        setValidated(true);
    };


    const handleChange = (event) => {
        const { value } = event.target;
        setPassword(value.slice(0, 18));

        if (isPasswordValid(value)) {
            setPasswordvalid(false)
        }
        else {
            setPasswordvalid(true)
            return false
        }

    };

    const isPasswordValid = (password) => {
        // Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
        return passwordRegex.test(password);
    };

    return (
        <>
            <ToastContainer />
            <section className='business-signin-form login-page'>
                <img src={background} />
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className='container'>
                        <div className="login_box">
                            <div className="login_box_head">
                                <img src={logo} />
                                <h4>Reset Password</h4>
                            </div>
                            <div className="login_box_body">
                                {
                                    loading ?
                                        <div className="text-center">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </div>
                                        :
                                        <>
                                            {
                                                IsVerified ?
                                                    <>
                                                        {
                                                            IsPasswordChanged ?
                                                                <>
                                                                    <p>Your password has been changed succesfully.</p>
                                                                    <NavLink to="/login" className="button button-bs button-icon-bs w-100 justify-content-center"><span>Login Now</span> <ArrowLeftEndOnRectangleIcon /></NavLink>

                                                                </>
                                                                :
                                                                <>
                                                                    <div className="icon-input">
                                                                        <input
                                                                            type={showPassword ? "text" : "password"}
                                                                            name="password"
                                                                            placeholder='New Password'
                                                                            maxLength={18}
                                                                            value={password}
                                                                            onChange={handleChange}
                                                                            className=""
                                                                            required
                                                                        />
                                                                        <div className="icon" onClick={() => setShowPassword(!showPassword)} >
                                                                            {
                                                                                showPassword ?
                                                                                    <EyeSlashIcon />
                                                                                    :
                                                                                    <EyeIcon />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="icon-input">
                                                                        <input
                                                                            type={showConfirmPassword ? "text" : "password"}
                                                                            onChange={(e) => setConfirmpassword(e.target.value)}
                                                                            name="Confirmpassword"
                                                                            placeholder='Confirm Password'
                                                                            className=""
                                                                            required
                                                                        />
                                                                        <div className="icon" onClick={() => setshowConfirmPassword(!showConfirmPassword)} >
                                                                            {
                                                                                showConfirmPassword ?
                                                                                    <EyeSlashIcon />
                                                                                    :
                                                                                    <EyeIcon />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="button button-bs w-100"
                                                                        disabled={loading || Confirmpassword.length === 0}
                                                                    >
                                                                        Update {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                                                                    </button>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="icon-input">
                                                            <input
                                                                type={"text"}
                                                                placeholder='You are using invalid or expire link.'
                                                                readOnly
                                                            />
                                                            <div className="icon">
                                                                <ExclamationTriangleIcon />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            </section>
        </>
    );
}

export default ResetPassword;