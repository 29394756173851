import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Config from "../../../Config";

function AllFranchiseesDropDown(props) {

    const [City, setCity] = useState([]);
    const [Ledger, setLedger] = useState([]);
    const [loading, setLoading] = useState(false);

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));


    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            Member_Type: MemberType,
            UserID: UserID,
            MemberType: "Admin",

        };

        // console.log(data);

        var api_config = {
            method: "post",
            url: Config.base_url + "Security/GetAllUsers",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code === 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, []);

    if (loading) {
        return false;
    } else {
        return Ledger != null && Ledger.length > 0 && Ledger?.map((item) => (
            <option value={item.Group_Name} selected>{item.Group_Name}</option>
        ));
    }

}

export default AllFranchiseesDropDown
