import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Common/Util";
import { Modal } from "react-bootstrap";

const CashOutList = () => {


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [loading, setLoading] = useState(false);

    const [GrandTotal, setGrandTotal] = useState('');

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [listOfLedger, setLedger] = useState([]);
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [SearchMember, setSearchMember] = useState('');
    const [show, setShow] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [BalaceLoading, setBalaceLoading] = useState(false);
    const [paymentMode, setpaymentMode] = useState('');


    const [ImagePath, setImagePath] = useState('');


    const handlePageChange = (page) => {
        setPageNo(page);
    };


    useEffect(() => {


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            UserName: SearchMember,
            startDate: StartDate,
            endDate: EndDate,
            PaymentMode: paymentMode,
        };
        // console.log("data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "UserCollection/GetUserCashOuts",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data)
                    setLoading(false);
                } else if (response.data.status_code === 0 || response.data.status_code === 2) {
                    setLoading(false);
                    toast.error(response.data.status_code, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/franchise/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, paymentMode, StartDate, EndDate, BalaceLoading]);

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Dated).format("DD/MM/YYYY HH:mm")}</td>
                        <td>PKR {NumberFormat(item.Amount)}</td>
                        <td>{item.PaymentMode}</td>
                        <td>{item.PaymentMode == 'By Hand' ? (item.ExpenseType) : ''}</td>
                        <td>{item.PaymentMode == 'By Bank' ? (item.ExpenseType) : ''}</td>
                        <td>{item.PaymentMode == 'Office Expense' ? (item.ExpenseType) : ''}</td>
                        <td>{item.Remarks}</td>
                        <td>
                            <ul
                                className="inline-action"
                            >
                                <>
                                    <li>
                                        <button
                                            onClick={(ID, Path) => {
                                                handleShow(item.ID, item.FileAttached)
                                            }}
                                            className="primary"
                                        >
                                            <span className="material-symbols-outlined">
                                                view_in_ar
                                            </span>
                                        </button>
                                    </li>
                                </>
                            </ul>
                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (ID, Path) => {
        setShow(true);
        setImagePath(Path);
    }




    return (
        <>
            <FranchiseLoginCheck />
            <ToastContainer />
            <Modal
                className="bs-modal size-md p-0"
                show={show} onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cash Out</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-start">

                    <img src={`${config.path}SupportTicket/SupportFiles/${ImagePath}`} className="img-fluid rounded-3" alt="Attached File" />
                </Modal.Body>
            </Modal>

            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Cash Out Details
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/franchise/dashboard">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">

                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label htmlFor="">From</label>
                                            <div className="icon-input">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={StartDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label htmlFor="">To</label>
                                            <div className="icon-input">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={EndDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label htmlFor="">Payment Mode</label>
                                            <div className="icon-input">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={paymentMode}
                                                    onChange={(e) => setpaymentMode(e.target.value)}
                                                >
                                                    <option value="">All</option>
                                                    <option value="By Hand">By Hand</option>
                                                    <option value="By Bank">By Bank</option>
                                                    <option value="Office Expense">Office Expense</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Cash Out Date</th>
                                    <th>Amount</th>
                                    <th>Payment Mode</th>
                                    <th>Person Name</th>
                                    <th>Bank Name</th>
                                    <th>Expense Type</th>
                                    <th>Remarks</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="9">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger.length > 0 &&
                                            <>
                                                <tr>
                                                    <th colSpan={1} className="border-bottom-0 pt-3"></th>
                                                    <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</th>
                                                    <th className="border-bottom-0 pt-3" colSpan={6}></th>

                                                </tr>
                                                <tr className="">
                                                    <th colSpan={1}></th>
                                                    <th className="text-end">Grand Total: </th>
                                                    <th>PKR {NumberFormat(GrandTotal.totalCollection)}</th>
                                                    <th className="" colSpan={6}></th>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default CashOutList