import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Dashboard/General/Util";


function FeeReceive(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Package, setPackage] = useState('');
    //   console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [ledgerOld, setLedgerOld] = useState([]);
    const [Records, setRecords] = useState([])

    const [showFilters, setShowFilters] = useState(true);


    const [SecretID, setSecretID] = useState('');


    const handalsubmit = () => {
        document.title = "MAG Franchise";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Member_Type: 'Admin',
            SecretID: SecretID,
        };

        // console.log("data", data);



        var api_config = {
            method: "post",
            url: config.base_url + "Package/GetPackagebySecretID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setPackage(response.data.PackagePurchase);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(totalRecords);
                }
                // else if (response.data.status_message === "Invalid Access Key.") {
                //     navigate('/franchise/login')
                // }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };

    const handleAgreementFee = (id) => {
        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Member_Type: 'Admin',
            ID: id,
        };

        // console.log("data", data);



        var api_config = {
            method: "post",
            url: config.base_url + "Users/ReceiveAgreementFee",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    //console.log(totalRecords);
                    setLoading(false);
                    setSecretID('');
                }
                else if (response.data.status_message === "Invalid Access Key.") {
                    navigate('/franchise/login')
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };


    return (
        <>
            <FranchiseLoginCheck />
            <ToastContainer />
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            View Agreement
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/franchise/fee-receive">
                                 <button type="button" className="button button-outline-bs">
                Back
              </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Office Addree</th>
                                    <th>Name</th>
                                    <th>Amount</th>
                                    <th>Agreement Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        <td>{Package.AgreementClaim}</td>
                                        <td>{Package.selectedPackage?.Name}</td>
                                        <td>{Package.PackagePrice}</td>
                                        <td>{Package.AgreementStatus}</td>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        </>
    );
}

export default FeeReceive