export const DecimalFormat = (number) => {
  return parseFloat(number).toFixed(2).toLocaleString('en-US');

}
export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export const convertNumberToDecimal = (number) => {
  const formattedNumber = Number(number).toFixed(2);
  if (formattedNumber.endsWith('.00')) {
    return '0.00';
  }
  return formattedNumber;
}


export const NumberFormat6D = (value) => {
  if (value == null) {
    return "";
  }
  else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6,
    }).format(value);

    return formattedNumber;
  }

};

export const NumberFormat = (value) => {
  if (value == null) {
    return "";
  }
  else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    return formattedNumber;
  }

};

export const NumberFormatEmpty = (value) => {
  if (value == null) {
    return "";
  }
  else {
    if (value == 0) {
      return "";
    }
    else {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

      return formattedNumber;
    }

  }

};


export const NumberFormat3D = (value) => {
  if (value == null) {
    return "";
  }
  else {
    if (value == 0) {
      return "";
    }
    else {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }).format(value);

      return formattedNumber;
    }
  }
};


export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};


export const replaceCharactersWithStars = (numberOfStars, inputString) => {

  if (!inputString) {
    return ''; // Return an empty string if inputString is undefined or null
  }

  const stars = '*'.repeat(numberOfStars);
  const replacedString = stars + inputString.slice(numberOfStars);

  return replacedString;
};
export const AmountToWords = (amount) => {
  // Arrays representing words for each digit
  const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  // Function to convert a number less than 1000 to words
  function convertLessThanThousand(num) {
    if (num === 0) {
      return '';
    }
    let result = '';
    if (num >= 100) {
      result += ones[Math.floor(num / 100)] + ' Hundred ';
      num %= 100;
    }
    if (num >= 20) {
      result += tens[Math.floor(num / 10)] + ' ';
      num %= 10;
    }
    if (num > 0) {
      if (num < 10) {
        result += ones[num] + ' ';
      } else {
        result += teens[num - 10] + ' ';
      }
    }
    return result.trim();
  }

  // Function to convert the amount to words
  function convertToWords(amount) {
    if (amount === 0) {
      return 'Zero';
    }
    let result = '';
    const billions = Math.floor(amount / 1000000000);
    const millions = Math.floor((amount % 1000000000) / 1000000);
    const thousands = Math.floor((amount % 1000000) / 1000);
    const ones = amount % 1000;

    if (billions > 0) {
      result += convertLessThanThousand(billions) + ' Billion ';
    }
    if (millions > 0) {
      result += convertLessThanThousand(millions) + ' Million ';
    }
    if (thousands > 0) {
      result += convertLessThanThousand(thousands) + ' Thousand ';
    }
    if (ones > 0) {
      result += convertLessThanThousand(ones);
    }
    return result.trim();
  }

  return convertToWords(amount);
}