import React from "react";
import Logo from "../../../assets/images/logo-h.png";
import { NumberFormat, NumberFormatEmpty, NumberFormat3D } from "../General/Functions";
import moment from "moment";

function EstatementPrint(props) {

    const data = props.data;


    //console.log(props.user);


    const renderLedger = () => {
        if (data.length > 0) {
            return (
                data.map((item, index) => {

                    return (
                        <tr key={index}>

                            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
                            <td>{item.TransactionDetails}</td>
                            <td>{item.ReferenceNo}</td>
                            <td>{NumberFormatEmpty(item.Debit)}</td>
                            <td>{NumberFormatEmpty(item.Credit)}</td>
                            <td>{NumberFormat3D(item.Earned)}</td>
                            <td>{NumberFormat(item.CashBalance)}</td>
                            
                        </tr>
                    )
                })
            )
        }
    }

    return (
        <>
            <div className="book pb-5">
                <div className="page">
                    <div className="subpage">
                        <div className="text-center">
                            <img style={{ width: "180px" }} src={Logo} />
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <p>
                                    To, <br />
                                    {props.user.Full_Name}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="card stat_box">
                                    <div className="head">

                                        <div className="head-box-title">Account statement</div>
                                    </div>
                                    <div className="stat_box_body p-0">
                                        <div className="copy_wrap space-small">
                                            <div className="copy_item">
                                                <div className="option">
                                                    Account ID
                                                </div>
                                                <div className="action">
                                                {props.user.AccountKey}
                                                </div>
                                            </div>
                                            <div className="copy_item">
                                                <div className="option">
                                                    Username
                                                </div>
                                                <div className="action">
                                                {props.user.User_Name}
                                                </div>
                                            </div>
                                            <div className="copy_item">
                                                <div className="option">
                                                    Currency
                                                </div>
                                                <div className="action">
                                                    USD
                                                </div>
                                            </div>
                                            <div className="copy_item">
                                                <div className="option">
                                                    Issue date
                                                </div>
                                                <div className="action">
                                                    {moment().format("DD, MM YYYY")}
                                                </div>
                                            </div>
                                            <div className="copy_item">
                                                <div className="option">
                                                    Statement period
                                                </div>
                                                <div className="action">
                                                {moment(props.startDate).format("DD/MM/YYYY")} to {moment(props.endDate).format("DD/MM/YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card stat_box mt-5">
                            <div className="head">

                                <div className="head-box-title">Account Summary</div>
                            </div>
                            <div className="stat_box_body  bg-white p-0">
                                <table className="table table-sm">

                                    <tbody>
                                        <tr className="filter-row">


                                            <td>

                                                <p className="mb-0 text-primary" style={{ fontSize: "12px" }}>Account Type</p>


                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Active wallet</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Passive Wallet</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Reward Wallet</p>

                                            </td>

                                           
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Total credit</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}> Total earned</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}> Cash balance</p>

                                            </td>
                                        </tr>

                                        <tr>
                                            <td><span className="text-primary">{props.AccountType}</span></td>
                                            <td><div className="gradient-num"><span>${props.activeWallet}</span></div></td>
                                            <td><div className="gradient-num"><span>${props.passiveWallet}</span></div></td>
                                            <td><div className="gradient-num"><span>${props.rewardWallet}</span></div></td>
                                           
                                            <td><div className="gradient-num"><span>${props.totalCredit}</span></div></td>
                                            <td><div className="gradient-num"><span>${props.totalEarned}</span></div></td>
                                            <td><div className="gradient-num"><span>${props.cashBalance}</span></div></td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card stat_box mt-5">
                            <div className="head">

                                <div className="head-box-title">Statement for period {moment(props.startDate).format("DD/MM/YYYY")} to {moment(props.endDate).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="stat_box_body  bg-white p-0">
                                <table className="table table-sm">

                                    <tbody>
                                        <tr className="filter-row">


                                            <td>

                                                <p className="mb-0 text-primary" style={{ fontSize: "12px" }}>Date</p>


                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Transaction</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Reference no</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Debit</p>

                                            </td>

                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Credit </p>

                                            </td>

                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Earned</p>

                                            </td>
                                            <td>

                                                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>Cash Balance</p>

                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td></td>
                                             <td colSpan="5" className="text-primary">OPENING BALANCE</td>
                                             <td className="text-primary"><span className="text-primary">{NumberFormat(props.OpeningBalance)}</span></td>
                                        </tr>

                                        {renderLedger()}
                                        <tr>
                                            <td><span className="text-gray"></span></td>
                                            <td><span className="text-primary">Closing Balance</span></td>
                                            
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><span className="text-primary">{NumberFormat(props.ClosingBalance)}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EstatementPrint;