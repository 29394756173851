import React, { useState, useEffect } from "react";
import config from "../../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../../Dashboard/General/Pagination";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../../AdminRightsCheck";

function CryptoWithdrawalList(props) {
  // const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  // const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  // const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // useEffect(() => {

  //     document.title = "KYC REQUESTS List";

  //     setIsUpdated(false);
  //     setLoading(true);

  //     const data = {
  //         AccessKey: AccessKey,
  //         UserID: UserID,
  //         pageNo: pageNo,
  //         pageSize: pageSize,
  //     };

  //     console.log(data);

  //     var api_config = {
  //         method: 'post',
  //         url: config.base_url + 'Users/GetKYCList',
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         data: data
  //     };

  //     axios(api_config)
  //         .then(function (response) {
  //             if (response.data.status_code === 1) {
  //             console.log("data", response.data);
  //             setLedger(response.data.ListofLedger);
  //             setLoading(false);
  //             setTotalRecords(response.data.totalRecords);
  //             console.log(totalRecords);

  //             }
  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //         });

  // }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    // if (listOfLedger != null && listOfLedger.length > 0) {
    let count = 1;
    return listOfLedger.map((item, index) => {
      console.log(item);
      return (
        <tr key={index}>
          <td>{count++ + pageSize * (pageNo - 1)}</td>
        </tr>
      );
    });
    // }
    // else {
    // return (
    //     <tr>
    //         <td colSpan="9"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
    //     </tr>
    // )
    // }
  };
  return (
    <>
    <AdminRightsCheck /> 
      <div className="admin-content">
        <div className="card card-table mb-5 mt-3 ">
          <div className="card-inner d-flex">
            <h6 className="my-auto mx-4"> Select Status</h6>
            <select
              id="dropdown"
              className="form-select"
              style={{ width: "15rem" }}
              //   value={userstatusVal ? userstatusVal : status}
              //   onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Inactive"> Bitcoin Withdrawal </option>

              <option value="" selected>
                Etherum Withdrawal
              </option>
              <option value="Active"> TRON Withdrawal </option>
              <option value="Active"> USDT ( TRC20 ) </option>

              <option value="Inactive"> USDT( ERC20 ) </option>
              <option value="Inactive"> BUSD Withdrawal </option>
            </select>
          </div>
        </div>
      
        <h3> Crypto Withdrawal</h3>
        <div className="card card-table mb-5 mt-3">
        <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th> User Id</th>
                  <th>Payment Id</th>
                  <th>BTC Address</th>
                  <th>BTC Amount </th>
                  <th>Admin Fee </th>
                  <th>Receivable BTC</th>
                  <th>Status </th>
                  <th>Total withdrawal USD </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CryptoWithdrawalList;
