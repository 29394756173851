import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import config from "../../../Config";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

function DocLicense(props) {


    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

    // front side
    const [ImgFront, setImgFront] = useState("");
    const [frontSideSelected, setfrontSideSelected] = useState(false);
    const [frontCropper, setFrontCropper] = useState();


    // back side
    const [ImgBack, setImgBack] = useState("");
    const [backSideSelected, setbackSideSelected] = useState(false);
    const [backCropper, setBackCropper] = useState();

    const onChangeFronLicence = (e) => {
        e.preventDefault();
        setfrontSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setfrontSideSelected(false);
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImgFront(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }

    const onChangeBackLicence = (e) => {
        e.preventDefault();
        setbackSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setbackSideSelected(false);
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImgBack(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }


    const getCropData = () => {
        if (typeof frontCropper !== "undefined") {
            var cropDataFront = frontCropper.getCroppedCanvas().toDataURL();
            var cleanerBase64Front = cropDataFront.substring(22);
            props.LicenseFrontValue(cleanerBase64Front);
        }

        if (typeof backCropper !== "undefined") {
            var cropDataBack = backCropper.getCroppedCanvas().toDataURL();
            var cleanerBase64Back = cropDataBack.substring(22);
            props.LicenseBackValue(cleanerBase64Back);
        }

        const data = {
            LicenseFront: cleanerBase64Front,
            LicenseBack: cleanerBase64Back
        };

    };



    const [dragging, setDragging] = useState(false);
    const [dragFront, setdragFront] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };
    const handleDragfirst = (event) => {
        event.preventDefault();
        setdragFront(true);
    };

    const handleDropfirst = (event) => {

        event.preventDefault();
        setfrontSideSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {
                setfrontSideSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                setImgFront(e.target.result);
                if (backCropper) {
                    backCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const handleDrop = (event) => {

        event.preventDefault();
        setbackSideSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setbackSideSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                setImgBack(e.target.result);
                if (backCropper) {
                    backCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };



    return (
        <>
            <div className="">
                <div className="mb-4">
                    <div className="examle">
                        <label htmlFor="">Upload Licence</label>
                        <span>Show Example</span>
                    </div>
                </div>

                <div className="kyc-img-card blue mb-3">
                    <div className="card-inner">
                        <div className={`upload-box ${dragFront ? 'dragFront' : ''}`}
                            id="drop-area"
                            onDragOver={handleDragfirst}
                            onDrop={handleDropfirst}
                        >
                            <input id="ChooseLicenceFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFronLicence} />
                            {
                                frontSideSelected ?
                                    <>
                                        <Cropper
                                            style={{ width: '402px', height: '220px', marginTop: '-10px' }}
                                            zoomTo={0}
                                            preview=".img-preview"
                                            src={ImgFront}
                                            dragMode="move"
                                            viewMode={2}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setFrontCropper(instance);
                                            }}
                                            guides={true}
                                        />
                                        <div className="delete" onClick={() => setfrontSideSelected(false)}>
                                            <TrashIcon />

                                        </div>
                                    </>

                                    :
                                    <label htmlFor="ChooseLicenceFront" className='thumnail'>
                                        <ArrowUpTrayIcon />

                                        <p>Upload Front Side</p>
                                    </label>

                            }

                        </div>
                    </div>
                </div>
                <div className="kyc-img-card blue">
                    <div className="card-inner">
                        <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                            id="drop-area"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <input id="ChooseLicenceBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBackLicence} />
                            {
                                backSideSelected ?
                                    <>
                                        <Cropper
                                            style={{ width: '402px', height: '220px', marginTop: '-10px' }}
                                            zoomTo={0}
                                            preview=".img-preview"
                                            src={ImgBack}
                                            dragMode="move"
                                            viewMode={2}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setBackCropper(instance);
                                            }}
                                            guides={true}
                                        />
                                        <div className="delete" onClick={() => setbackSideSelected(false)}>
                                            <TrashIcon />
                                        </div>
                                    </>
                                    :
                                    <label htmlFor="ChooseLicenceBack" className='thumnail'>
                                        <ArrowUpTrayIcon />
                                        <p>Upload Front Side</p>
                                    </label>
                            }
                        </div>
                    </div>
                </div>

                <div className="text-end d-none">
                    <button onClick={getCropData}
                        className="button mt-3 mb-1  button-bs"
                        disabled={!frontSideSelected || !backSideSelected}>Upload License{loading ? '...' : ''}</button>
                </div>
            </div>
        </>
    );
}

export default DocLicense;