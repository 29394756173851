import React, { useState, useEffect } from "react";
import BusinessHeader from "./include/BusinessHeader";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import SupportOffcanvas from "./Support/SupportOffcanvas";

function BusinessMain() {

    const AccessKey = localStorage.getItem("AccessKey");
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        localStorage.setItem("theme", newTheme);
        setTheme(newTheme);
        document.documentElement.setAttribute('data-bs-theme', newTheme);
    }

    useEffect(() => {
        document.documentElement.setAttribute('data-bs-theme', theme);
    }, [theme])


    let navigate = useNavigate();
    const [SupportButton, setSupportButton] = useState(false);


    if (!AccessKey) {
        navigate('/login');
    }


    const closeMenu = () => {
        var business_header = document.getElementById("business_header_wrap");
        var overlay = document.getElementById("overlaymenu");
        var menubutton = document.getElementById("menubutton");
        var body = document.getElementById("body");
        body.classList.remove('overflow-hidden');

        if (business_header) {
            business_header.classList.remove('active');
        }
        if (body) {
            body.classList.remove('active');
        }

        if (overlay) {
            overlay.classList.remove('active');
        }


        if (menubutton) {
            menubutton.classList.remove('active');
        }
    }



    // support canvas
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <SupportOffcanvas show={show} handleClose={handleClose} />
            <div className="business-layout-wrap">
                <BusinessHeader />
                <div className="container">
                    <div className="business-content">
                        <div onClick={closeMenu} id="overlaymenu"></div>
                        <Outlet></Outlet>

                        <div className="theme-icons">
                            <div className={`iocn ${theme == "light" ? 'active' : ''}`} onClick={toggleTheme}>
                                {
                                    theme != "light" ?
                                        <MoonIcon className={`${theme == "light" ? 'moon' : ''}`} />
                                        :
                                        <SunIcon className={`${theme == "light" ? 'sun' : ''}`} />
                                }

                            </div>
                        </div>
                        <div className="support-icons">
                            <div className={`main-iocn ${SupportButton ? 'active' : ''}`}>
                                <NavLink onClick={handleShow}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0C4.486 0 0 4.486 0 10V14.143C0 15.167 0.897 16 2 16H3C3.26522 16 3.51957 15.8946 3.70711 15.7071C3.89464 15.5196 4 15.2652 4 15V9.857C4 9.59178 3.89464 9.33743 3.70711 9.14989C3.51957 8.96236 3.26522 8.857 3 8.857H2.092C2.648 4.987 5.978 2 10 2C14.022 2 17.352 4.987 17.908 8.857H17C16.7348 8.857 16.4804 8.96236 16.2929 9.14989C16.1054 9.33743 16 9.59178 16 9.857V16C16 17.103 15.103 18 14 18H12V17H8V20H14C16.206 20 18 18.206 18 16C19.103 16 20 15.167 20 14.143V10C20 4.486 15.514 0 10 0Z" fill="black" />
                                    </svg>
                                </NavLink>
                            </div>

                            <div className="open-icon" onClick={() => setSupportButton(!SupportButton)}>
                                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 0C2.236 0 0 2.236 0 5C0 5.19891 0.0790175 5.38968 0.21967 5.53033C0.360322 5.67098 0.551088 5.75 0.75 5.75C0.948912 5.75 1.13968 5.67098 1.28033 5.53033C1.42098 5.38968 1.5 5.19891 1.5 5C1.5 3.064 3.064 1.5 5 1.5C6.936 1.5 8.5 3.064 8.5 5C8.5 5.852 8.278 6.42 7.971 6.86C7.647 7.323 7.204 7.683 6.669 8.092L6.531 8.197C5.521 8.965 4.25 9.931 4.25 12V12.25C4.25 12.4489 4.32902 12.6397 4.46967 12.7803C4.61032 12.921 4.80109 13 5 13C5.19891 13 5.38968 12.921 5.53033 12.7803C5.67098 12.6397 5.75 12.4489 5.75 12.25V12C5.75 10.683 6.464 10.137 7.535 9.318L7.581 9.283C8.108 8.88 8.728 8.396 9.201 7.719C9.691 7.018 10 6.149 10 5C10 2.236 7.764 0 5 0ZM5 17C5.26522 17 5.51957 16.8946 5.70711 16.7071C5.89464 16.5196 6 16.2652 6 16C6 15.7348 5.89464 15.4804 5.70711 15.2929C5.51957 15.1054 5.26522 15 5 15C4.73478 15 4.48043 15.1054 4.29289 15.2929C4.10536 15.4804 4 15.7348 4 16C4 16.2652 4.10536 16.5196 4.29289 16.7071C4.48043 16.8946 4.73478 17 5 17Z" fill="black" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BusinessMain;