import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { NumberFormat } from "../../Dashboard/General/Functions";
import BsPagination from "../BsPagination";
import TransactionMenu from "./TransactionMenu";
import RewardMenu from "./RewardMenu";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import Config from "../../../Config";
import LoginCheck from "../General/LoginCheck";

function RanksRewardTransactions(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    const [ledgerOld, setLedgerOld] = useState([]);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Wallet/GetRankReward',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setLedger(response.data.ListofRenewalBonus);
                setLedgerOld(response.data.ListofRenewalBonus);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                // console.log(error);
            });





    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const amountTotal = () => {
        let totalAmount = 0;
        listOfLedger.forEach(item => {
            totalAmount += item.Debit;

        });

        return <span>{NumberFormat(totalAmount)}</span>

    }




    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {


            return (
                listOfLedger.map((item, index) => {
                    const str = item.Details;
                    const words = str.split(" ");
                    const filteredWords = words.filter(word => word !== "Rank" && word !== "Achieved." && word !== "Achieved");
                    const result = filteredWords.join(" ");
                    return (
                        <tr key={index}>
                            <td>{moment(item.Transaction_Date).format('DD/MM/YYYY')}</td>
                            <td>{result}</td>
                            <td>PKR {NumberFormat(item.Debit)}</td>
                            <td>{item.Details}</td>
                            <td>Paid</td>
                            <td>PKR {NumberFormat(item.Debit)}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }



    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <RewardMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Rank </th>
                                <th>Rewards Value</th>
                                <th style={{ minWidth: "190px" }}>Notes</th>
                                <th>Status</th>
                                <th style={{ minWidth: "140px" }}>Total Received </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="6">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <tr>
                                                <td colSpan="5" className="text-end"><strong>Total:</strong></td>
                                                <td><strong>PKR {amountTotal()}</strong></td>
                                            </tr>
                                        }

                                    </>

                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default RanksRewardTransactions;