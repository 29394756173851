import React, { useEffect, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import iphone from "../../../assets/images/partner/iphone15.png";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { ArrowLeftIcon, ArrowPathRoundedSquareIcon, ArrowRightIcon, CheckCircleIcon, ShieldCheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";
import { NumberFormat } from "../General/Functions";

const PackagePurchase = (props) => {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const PackageData = props.PackageData;

    const [packagePurchased, setpackagePurchased] = useState(false);
    const [ListofPackages, setListofPackages] = useState([]);
    const [balance, setBalance] = useState(0);
    // loading states
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [AutoRenew, setAutoRenew] = useState(false);
    const [Compounding, setCompounding] = useState(false);
    const [VoucherID, setVoucherID] = useState(0);
    const [user, setUser] = useState({});

    const [downlineLoading, setDownlineLoading] = useState(false);
    const [downlineUserName, setDownlineUsername] = useState(null);
    const [downlineVerified, setDownlineVerified] = useState(false);

    const [OTP, setOTP] = useState(null);

    const [Verified, setVerified] = useState(null);
    const navigate = useNavigate;

    const [Color, setColor] = useState('');

    // loadings

    const [LoadingPackage, setLoadingPackage] = useState(false);
    const [SelectActive, setSelectActive] = useState('Self');

    let params = useParams();
    const id = params;


    useEffect(() => {
        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetAllPackages',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log("Modal", response.data);
                if (response.data.status_code == 1) {
                    setBalance(response.data);
                    setListofPackages(response.data.ListofPackages);
                }
                setLoading(false);

            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT,
                    //onClose: () => window.alert('Called when I close')
                });
            });

    }, [packagePurchased]);


    const [Conditions, setConditions] = useState(false);

    const VerifyUser = () => {
        if (downlineUserName != null) {

            setDownlineLoading(true);

            const downlineData = {
                Email_Address: downlineUserName,
                AccessKey: AccessKey,
                UserID: UserID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetBalanceTransferInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: downlineData
            };
            // console.log(downlineData);
            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        setUser(response.data);
                        setDownlineVerified(true);
                        setDownlineLoading(false);

                    }
                    else {
                        setDownlineVerified(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setDownlineLoading(false);
                    }

                })
                .catch(function (error) {
                    // console.log(error);
                });

        }
    }

    const handleConfirmPurchase = () => {

        setLoadingButton(true);

        if (SelectActive != 'Others') {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                PinCode: OTP,
                AutoRenew: AutoRenew,
                Compounding: Compounding,
                PackageID: params.id,
                VoucherID: VoucherID

            };
            // console.log("self-json", data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/PurchasePackage',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setpackagePurchased(true);
                        window.location = '/partner/packages';
                        setLoadingButton(false);
                        setTimeout(() => {
                            props.handleClose();
                        }, 2000);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoadingButton(false);
                    setpackagePurchased(false);

                })
                .catch(function (error) {
                    setLoadingButton(false);

                });
        }
        else {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                PinCode: OTP,
                Email_Address: downlineUserName,
                PackageID: params.id,

            };
            // console.log("Others-json", data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/PurchasePackageDownline',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    // console.log("self", response.data)
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setpackagePurchased(true);
                        window.location = '/partner/packages';
                        props.handleClose();
                        setLoadingButton(false);
                    }
                    else {
                        setLoadingButton(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoadingButton(false);
                    setpackagePurchased(false);

                })
                .catch(function (error) {

                });
        }

    }

    const ValueNull = () => {
        setDownlineUsername(null);
        setOTP(null);
        setConditions(false);
        setDownlineVerified(false);
    }

    return (
        <>
            <LoginCheck />
            <Modal show={props.show} onHide={props.handleClose} onExit={ValueNull} className="purchase-now-card">
                <Modal.Header>
                    <Modal.Title>Buy Package</Modal.Title>
                </Modal.Header>

                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <Modal.Body>

                            <div className="package-card-view">
                                <div className="package-card">
                                    <div className="image">
                                        <img src={config.root + 'Images/Packages/' + PackageData.Image} alt="" />
                                    </div>
                                    <div className="content">
                                        <div className="ribbon">
                                            <span className="ribbon-text">{PackageData.Name} Package</span>
                                            <div className="ribbon-corner"></div>
                                        </div>
                                        <div className="package-header">
                                            <h4>{PackageData.TagLine}</h4>
                                        </div>
                                        <div className="detail border-0">
                                            <ul className="list">
                                                <li><label htmlFor="">Total Profit:</label> <span>{PackageData.TotalProfitReturn}</span></li>
                                                <li><label htmlFor="">Product deliver:</label> <span>{PackageData.ProductDelivery}</span></li>
                                                <li><label htmlFor="">Days:</label> <span>{PackageData.NoofDays}</span></li>
                                                <li><label htmlFor="">Price:</label> <span>{(PackageData.Amount)} PKR</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul className="color-chose d-none">
                                <li>
                                    <input id="walk" value="#edf4fa" name="check-color" className="radio-button" type="radio" onChange={(e) => setColor('#edf4fa')} />
                                    <label className="color-label" htmlFor="walk" style={{ backgroundColor: "#edf4fa" }}></label>
                                </li>
                                <li>
                                    <input id="bike" value="#ff3636" name="check-color" className="radio-button" type="radio" onChange={(e) => setColor('#ff3636')} />
                                    <label className="color-label" htmlFor="bike" style={{ backgroundColor: "#ff3636" }}></label>
                                </li>
                                <li>
                                    <input id="drive" value="#daff36" name="check-color" className="radio-button" type="radio" onChange={(e) => setColor('#daff36')} />
                                    <label className="color-label" htmlFor="drive" style={{ backgroundColor: "#daff36" }}></label>
                                </li>
                                <li>
                                    <input id="fly" value="#36b5ff" name="check-color" className="radio-button" type="radio" onChange={(e) => setColor('#36b5ff')} />
                                    <label className="color-label" htmlFor="fly" style={{ backgroundColor: "#36b5ff" }}></label>
                                </li>
                            </ul>

                            <ul className="menu-option mb-3 mx-auto">
                                <li>
                                    <a className={`nav-link ${SelectActive == "Self" ? "active" : ""}`} onClick={(e) => setSelectActive("Self", e)} href="#">Self Purchase</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${SelectActive == "Others" ? "active" : ""}`} onClick={(e) => setSelectActive("Others", e)} href="#">Purchase for Others</a>
                                </li>
                            </ul>

                            {SelectActive === 'Others' ?
                                <>
                                    <div className="condition">
                                        <div className="verify">
                                            <div className="icon-input m-0">
                                                <input type="text"
                                                    value={downlineUserName}
                                                    onChange={(e) => {
                                                        setDownlineUsername(e.target.value);
                                                        setDownlineVerified(false);
                                                    }}
                                                    placeholder="Enter Username/Email"
                                                    autoComplete="off"
                                                />
                                                <div className="icon">
                                                    {
                                                        downlineVerified ?
                                                            <CheckCircleIcon />
                                                            :
                                                            ''

                                                    }
                                                </div>
                                            </div>
                                            <button
                                                onClick={VerifyUser}
                                                className="button button-outline-bs button-icon-bs input-height" type="button"
                                                disabled={downlineVerified}
                                            >
                                                <span>{downlineLoading ? 'Loading..' : 'Validate'}</span>
                                                <ShieldCheckIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="balance">
                                        <label>Holding Wallet Balance</label>
                                        <span>PKR {NumberFormat(balance.MagWallet)}</span>
                                    </div>
                                    <div className="help">
                                        <label htmlFor="">Insufficient Balance?</label>
                                        <Link to={`/partner/wallet`} className="button button-bs button-icon-bs">
                                            Recharge Now <ArrowPathRoundedSquareIcon />
                                        </Link>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="balance">
                                        <label>MAG Wallet Balance</label>
                                        <span>PKR {NumberFormat(balance.member_balance)}</span>
                                    </div>
                                    <div className="help">
                                        <label htmlFor="">Insufficient Balance?</label>
                                        <Link to={`/partner/addmoney`} className="button button-bs button-icon-bs">
                                            Deposit Now <ArrowPathRoundedSquareIcon />
                                        </Link>
                                    </div>

                                </>
                            }


                            <div className="icon-input w-100">
                                <input
                                    type="number"
                                    onChange={(e) => {
                                        if (e.target.value.length <= 6) {
                                            setOTP(e.target.value);
                                        }
                                    }}
                                    value={OTP}
                                    className="text-center"
                                    placeholder="Enter Transaction Pin"
                                    maxLength={6}
                                    required
                                />
                                <div className="icon">
                                    {

                                        Verified == null ?
                                            ""
                                            :
                                            Verified ?
                                                <CheckCircleIcon />
                                                :
                                                <XCircleIcon />
                                    }
                                </div>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={Conditions} id="flexCheckChecked" onChange={() => setConditions(!Conditions)} />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    I Accept <Link className="link" to="#/">Terms & Conditions</Link>
                                </label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-block">
                            <div className="row align-items-center gy-3">
                                <div className="col-md-5 offset-md-1">
                                    <button className="button button-outline-bs button-icon-bs w-100 justify-content-center" onClick={props.handleClose}>
                                        <ArrowLeftIcon />  Cancel
                                    </button>
                                </div>
                                <div className="col-md-5">
                                    <button
                                        className="button button-bs button-icon-bs w-100 justify-content-center"
                                        onClick={handleConfirmPurchase}
                                        disabled={!Conditions || loadingButton || OTP == null || OTP.length <= 0 || (SelectActive === 'Others' ? (downlineVerified ? false : true) : false)}
                                    >
                                        Buy Now {loadingButton ? '...' : ''} <ArrowRightIcon />
                                    </button>

                                </div>
                            </div>
                        </Modal.Footer>
                    </div>
                </div >
            </Modal >
        </>
    )
}

export default PackagePurchase