import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../Config';
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../../assets/images/partner/mag_logo.png';
import { NumberFormat } from '../../Dashboard/General/Functions';
function MembersListPdf(props) {
    document.title = "Mag Holdings Admin";
    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [loading, setLoading] = useState(false);
    const [listofLedger, SetlistofLedger] = useState([])
    const [totalRecords, setTotalRecords] = useState(0);

    const urlParams = new URLSearchParams(window.location.search);
    const StartDate = urlParams.get('StartDate');
    const EndDate = urlParams.get('EndDate');
    const Full_Name = urlParams.get('Full_Name');
    const status = urlParams.get('status');
    const [GrandTotal, setGrandTotal] = useState('');

    useEffect(() => {

        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: 1,
            pageSize: 20000,
            Status: status,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetMembersList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data)
        console.log(api_config);
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    SetlistofLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                    setLoading(false);

                } else {
                    setLoading(false);
                    // Handle error

                }
                //   setLoading(false);
            })
            .catch(function (error) {

                // console.log(error);
            });




    }, []);
    // Create styles
    const styles = StyleSheet.create({
        page: {
            //flexDirection: 'row',
            backgroundColor: '#FFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexDirection: 'column',
            marginTop: 0,
            marginBottom: 20,
            width: '100%',
            //flexGrow: 1
        },
        header: {
            margin: 5,
            marginBottom: 6.5,
            padding: 0,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10
        },
        dataview: {
            fontSize: "8pt"
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderWidth: .5,
            borderColor: '#f0f0f0',
            marginTop: 0,
            marginBottom: 10,
        },
        tableHead: {
            flexDirection: 'row',
            backgroundColor: '#f0f0f0'
        },
        tablefooter: {
            flexDirection: 'row',
            backgroundColor: '#FF1500'
        },
        tabletophead: {
            flexDirection: 'row',
            backgroundColor: '#FF1500',
            color: '#fff',
            borderStyle: 'dashed',
            borderWidth: 1,
            borderColor: '#f0f0f0',
            borderBottomColor: '#fff',
        },
        thValuecenter: {
            fontSize: "10pt",
            textAlign: "center",
            color: '#fff',
            fontWeight: 'bold',
        },
        th: {
            flex: 1,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: .1,
            borderColor: '#f0f0f0',
            color: '#000'
        },
        footerth: {
            flex: 1,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: .1,
            borderColor: '#f0f0f0',
            color: '#fff'
        },
        thValue: {
            fontSize: "8pt",
            fontWeight: "bold"
        },

        row: {
            flexDirection: 'row',
        },
        td: {
            flex: 1,
            padding: 5,
            borderBottom: 1,
            borderStyle: 'solid',
            borderBottomWidth: .5,
            borderColor: '#f0f0f0',
        },
        tdValue: {
            fontSize: "8pt",
        },




    });


    const generateData = () => {
        if (listofLedger != null && listofLedger.length > 0) {
            let count = 1;
            return (
                listofLedger.map((item, index) => {
                    return (
                        <View key={index} style={styles.row} break={index > 1 && index % 15 == 0}>
                            <View style={[styles.td, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.tdValue}>{totalRecords - count++ + 1}</Text></View>
                            <View style={styles.td}>
                                <Text style={styles.tdValue}> {moment(item.Created_Date).format("DD/MM/YYYY")}</Text>
                                <Text style={styles.tdValue}> {moment(item.Updated_Date).format("DD/MM/YYYY")}</Text>
                            </View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{item.User_Name}</Text><Text style={styles.tdValue}>{item.Full_Name}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.Mobile_No)}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{item.Email_Address}</Text></View>
                            <View style={[styles.td, { flexGrow: 1 }]}>
                                <Text style={styles.tdValue}>
                                    {item.Member_Type == 'Standard' ? "Free" : "Paid"}
                                    {item.FreeMember == 'Yes' ? ' / Free' : ''}
                                </Text>
                            </View>
                            <View style={[styles.td, { flexGrow: 1 }]}><Text style={styles.tdValue}>
                                {item.KYCStatus}
                            </Text></View>
                            <View style={[styles.td, { flexGrow: 1 }]}><Text style={styles.tdValue}>
                                {item.Is_Blocked ? 'Yes' : ''}
                            </Text></View>
                        </View>
                    )
                })
            )
        }

    };

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
                <View style={styles.header} fixed>
                    <Image
                        src={Logo}
                        style={{ width: 48, height: 'auto' }}
                    />
                </View>
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tabletophead} fixed>
                            <View style={styles.th}><Text style={styles.thValuecenter}>MAG TOTAL {status.toLocaleUpperCase()} MEMBERS REPORT FROM ({StartDate.length > 0 ? StartDate : '01/01/2024'}) T0 ({EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))})</Text></View>
                        </View>
                        <View style={styles.tableHead} fixed>
                            <View style={[styles.th, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.thValue}>SR.</Text></View>
                            <View style={styles.th}>
                                <Text style={styles.thValue}>Created Date</Text>
                                <Text style={styles.thValue}>Updated Date</Text>
                            </View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Username</Text><Text style={styles.thValue}>Name</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Mobile No</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Email</Text></View>
                            <View style={[styles.th, { flexGrow: 1 }]}><Text style={styles.thValue}>Member Status</Text></View>
                            <View style={[styles.th, { flexGrow: 1 }]}><Text style={styles.thValue}>KYC Status</Text></View>
                            <View style={[styles.th, { flexGrow: 1 }]}><Text style={styles.thValue}>Blocked</Text></View>
                        </View>
                        {generateData()}
                    </View>
                    <View style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }} fixed>
                        <Text style={{ fontSize: '8pt' }} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} />
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                {
                    loading ?
                        "Loading..."
                        :
                        <PDFViewer width="100%" height="100%"
                            filename={`MAG TOTAL ${status.toLocaleUpperCase()} MEMBERS REPORT FROM ${StartDate.length > 0 ? StartDate : '01/01/2024'} T0 ${EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))}'} T0 ${EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))}.pdf`}
                        >
                            <MyDocument />
                        </PDFViewer>
                }

            </div>
        </>
    );
}

export default MembersListPdf