import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import promo from "../../../assets/images/admin/icons/promo.png";
import paid_package from "../../../assets/images/admin/icons/paid_package.png";
import kyc from "../../../assets/images/admin/icons/kyc.png";
import free from "../../../assets/images/admin/icons/free.png";
import freeze from "../../../assets/images/admin/icons/freeze.png";
import expire from "../../../assets/images/admin/icons/expire.png";
import user from "../../../assets/images/admin/icons/user.png";
import downline from "../../../assets/images/admin/icons/downline.png";
import advance_balance_transfer from "../../../assets/images/admin/icons/advance_balance_transfer.png";
import comodity from "../../../assets/images/admin/icons/comodity.png";
import total_packages from "../../../assets/images/admin/icons/total_packages.png";
import binary_packages from "../../../assets/images/admin/icons/binary_packages.png";
import promo_winner from "../../../assets/images/admin/icons/promo_winner.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function TotalPackagesDashboard() {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [Dashboard_data, setDashboard_data] = useState('');

  useEffect(() => {
    document.title = "Mag Holdings Admin";
  }, []);

  let navigate = useNavigate();

  useEffect(() => {


    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetTotalMembershipDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setDashboard_data(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);



  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="TOTAL Packages" />
          <div className="admin-content">
            <div className="row">
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard/Packages-Statistics-list"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalMembership)}
                  label="TOTAL Packages"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>


              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard/Packages-Statistics-list"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.paidMembership)}
                  label="PAID Packages"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard/Packages-Statistics-list"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.freeMembership)}
                  label="FREE Packages"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>


              {/* <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard/Packages-Statistics-list"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.binaryMembership)}
                  label="BINARY MEMBERSHIP"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard/Packages-Statistics-list"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.realestoMembership)}
                  label="REALESTO MEMBERSHIP"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div> */}
              {/* <div className="col-md-3">
              <AdminCard
                link="/admin/total-packages-dashboard/Packages-Statistics-list"
                class="blue"
                value={"PKR " + NumberFormat(Dashboard_data.commodityMembership)}
                label="COMMODITY MEMBERSHIP"
                icon="deployed_code"
                subheading="Members"
                no="(50)"
              />
            </div> */}
              {/* <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard/Packages-Statistics-list"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.expiredMembership)}
                  label="EXPIRED MEMBERSHIPS"
                  icon="warning"
                  subheading="Members"
                  no="(50)"
                />
              </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TotalPackagesDashboard;
