import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "cropperjs/dist/cropper.css";
import BirthDetails from './BirthDetails';
import DocID from './DocID';
import DocPassport from './DocPassport';
import DocLicense from './DocLicense';
import LoginCheck from '../General/LoginCheck';

const KycNew = () => {




    return (
        <>
          <LoginCheck />
          <ToastContainer/>
            <div className="section-title mb-3">KYC (Know your client)</div>
            <div className="dash-bg-card kyc-section">
                <div className="heading mb-3">
                    Personal Details
                </div>
                <BirthDetails />
                <div className="row">
                    <div className="col-md-4">
                        <DocID />
                    </div>
                    <div className="col-md-4">
                        <DocPassport />
                    </div>
                    <div className="col-md-4">
                        <DocLicense />
                    </div>
                </div>
            </div >
        </>
    )
}

export default KycNew