import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import iconUsers from "../../../assets/images/admin/icons/users.png";
import iconBlocked from "../../../assets/images/admin/icons/blocked.png";
import iconPaid from "../../../assets/images/admin/icons/paid.png";
import iconKyc from "../../../assets/images/admin/icons/kyc.png";
import iconInactive from "../../../assets/images/admin/icons/inactive.png";
import iconFreeUser from "../../../assets/images/admin/icons/freeuser.png";
import iconActive from "../../../assets/images/admin/icons/active.png";
import AdminHeader from "../AdminHeader";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../../components/Dashboard/General/Functions";

import "react-toastify/dist/ReactToastify.css";

function UserStatisticsDashboard(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [UserStatics, setUserStatics] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetUserStatistics",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);

        if (response.data.status_code == 1) {
          setUserStatics(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div className="d-flex justify-content-center" style={{ marginTop: "20%" }}>
          <Spinner
            animation="border"
            role="status"
          ></Spinner>
        </div>
      ) : (
        <>

          <ToastContainer />
          <PageTitle title="MEMBER STATISTICS" />
          {UserStatics && (
            <div className="admin-content">
              <div className="row">
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/Total-user"
                    class="blue"
                    value={(UserStatics.totalUsers)}
                    label="Total Members"
                    icon="supervisor_account"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/userstatus/Paid"
                    class="green"
                    value={(UserStatics.paidUsers)}
                    label="Paid Member"
                    icon="attach_money"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/userstatus/Free"
                    class="red"
                    value={(UserStatics.freeUsers)}
                    label="Free Member"
                    icon="person"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/userstatus/Active"
                    class="brown"
                    value={(UserStatics.activeUsers)}
                    label="KYC Verified Members"
                    icon="person_check"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/userstatus/Inactive"
                    class="green"
                    value={(UserStatics.InactiveUsers)}
                    label="KYC Not Verified Member"
                    icon="person_cancel"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/userstatus/Blocked"
                    class="red"
                    value={(UserStatics.blockedUsers)}
                    label="Block Member"
                    icon="account_circle_off"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/KycRequests"
                    class="green"
                    value={(UserStatics.kycPending)}
                    label="Kyc Approval Requests"
                    icon="approval_delegation"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/userstatistics-dashboard/leadership"
                    class="green"
                    value={(UserStatics.LeadersCount)}
                    label="Leadership"
                    icon="social_leaderboard"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )
      }
    </>
  );
}

export default UserStatisticsDashboard;
