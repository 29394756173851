import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";




function NetworkBonusList(props) {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);

  let navigate = useNavigate();

  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [showFilters, setShowFilters] = useState(true);
  const [GrandTotal, setGrandTotal] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [Full_Name, setFull_Name] = useState('');


  useEffect(() => {

    document.title = "Mag Holdings Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
    };

    // console.log(data);

    var api_config = {
      method: 'post',
      url: config.base_url + 'Users/GetNetworkBonusDetails',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log("data", response.data);
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofNetworkBonus);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          setGrandTotal(response.data);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // setLoading(false);
      });

  }, [pageNo, pageSize, isUpdated, StartDate, EndDate]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };


  const renderLedger = () => {

    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return (
        listOfLedger.map((item, index) => {
          return (
            <tr key={index}>
              <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
              <td>{moment(item.Dated).format("DD/MM/YYYY HH:mm")}</td>
              <td>{item.Selected_Member?.User_Name}</td>
              <td>{item.TotalLeftVolume}</td>
              <td>{item.TotalRightVolume}</td>
              <td>{item.Matched}</td>
              <td>{item.CarriedForward}</td>
              <td>{item.Matched !== 0 ? (item.Matched / item.Percentage) : '0'} % </td>
              <td>PKR {item.Percentage}</td>
            </tr>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="9"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
        </tr>
      )
    }

  }

  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUpdated(true);
  };

  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              NETWORK BONUS
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/active-bonus-dashboard">
                 <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Filters Menu */}
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row gy-3 p-3">
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form_default position-relative">
                      <Form onSubmit={handleSearch}>
                        <div className="row align-items-end gy-3 gx-1">
                          <div className="col-md-7">
                            <div className="form-group search_box">
                              <label htmlFor="">Search Member</label>
                              <div className="inputwrap">
                                <input type="text" className="form-control"
                                  placeholder="Find by Username/Member ID/Email"
                                  value={Full_Name}
                                  onChange={(e) => setFull_Name(e.target.value)}
                                />
                                <span className="material-symbols-outlined">search</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-table mb-5 mt-3">
        <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th>Username</th>
                  <th>Total left BV </th>
                  <th>Total Right BV</th>
                  <th>Total Matched </th>
                  <th>Carried Forward </th>
                  <th>Percentage </th>
                  <th>Total Received</th>
                </tr>
              </thead>
              <tbody>

                {
                  loading ?
                    <tr>
                      <td className="text-center" colSpan="9">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                    :
                    <>
                      {renderLedger()}
                      {
                        listOfLedger.length > 0 &&
                        <>
                          <tr style={{ borderButtom: "none !important" }}>
                            <th colSpan={7} className="border-bottom-0 pt-3"></th>
                            <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                            <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Percentage'))}</th>

                          </tr>
                          <tr className="">
                            <th className="pt-3" colSpan={7}></th>
                            <th className="text-end">Grand Total:</th>
                            <th>PKR {NumberFormat(GrandTotal.totalReceived)}</th>
                          </tr>
                        </>
                      }
                    </>
                }
              </tbody>
            </table>
          </div>
        </div>
        {
          totalRecords > 10 &&
          <div className="pagination-wrap">

            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>Page size</label>
                  <div className="form-group">
                    <select className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}>
                      <option value="10" selected>10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>

        }



      </div>


    </>
  );
}

export default NetworkBonusList;
