import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function BonusPanelDashboard() {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [Dashboard_data, setDashboard_data] = useState('');

  useEffect(() => {
    document.title = "Mag Holdings Admin";
  }, []);

  let navigate = useNavigate();

  useEffect(() => {


    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetActiveBonusDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setDashboard_data(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);



  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="Smart Wallet" />
          <div className="admin-content">
            <div className="row">
              <div className="col-md-3">
                <AdminCard
                  link="/admin/active-bonus-dashboard/direct-bonus-list"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalBonus)}
                  label="Total Active Bouns Paid"
                  icon="monetization_on"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/active-bonus-dashboard/direct-bonus-list"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.directBonus)}
                  label="DIRECT BONUS"
                  icon="output_circle"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/active-bonus-dashboard/referral-bonus-list"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.referralBonus)}
                  label="REFERRAL BONUS"
                  icon="autopause"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              
              <div className="col-md-3">
                <AdminCard
                  link="/admin/active-bonus-dashboard/Active_Wallet"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.cashbackBonus)}
                  label="Cashback Bonus"
                  icon="pending"
                  subheading="Members"
                  no="(50)"
                />
              </div>

            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BonusPanelDashboard;
