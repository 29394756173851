import React, { useEffect, useState, useRef } from 'react';
import QRCode from "react-qr-code";
import Logo from "../../../assets/images/logo/mag.svg";
import stamp from "../../../assets/images/partner/stamp.svg";
import html2canvas from "html2canvas";
import axios from "axios";
import moment from "moment";
import Config from '../../../Config';
const TransferInvoice = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const ID = urlParams.get('id');
    const [transfer, settransfer] = useState({});

    const HostUrl = window.location.origin + `/partner/transactions/funds-history/sent/img?id=${ID}`;

    const InvoiceRef = useRef();
    useEffect(() => {

        document.title = "Transfer Invoice";

        const data = {
            Payment_ID: ID,
        }

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/BalanceTrasnferByPaymentID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log('data', data)

        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    settransfer(response.data.BalanceTrasnfer);


                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);


    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");

                // Create a link element
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "Transactions Invoice.jpg";

                // Append the link to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            });
        }
    };


    return (
        <>
            <div className="invoice-parent-page">
                <div className="invoice-page" ref={InvoiceRef}>
                    <div className="subpage">
                        <div className="invoice-top">
                            <img src={Logo} />
                            <div className="title">Transfer Invoice</div>
                            <span>{moment(transfer.Created_Date).format('DD/MM/YYYY - HH:mma')}</span>
                            <div className="qr-code-wrap">
                                <QRCode
                                    size={256}
                                    value={HostUrl}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <div className="invoice-content">
                            <ul>
                                <li>
                                    <label htmlFor="">Sender</label>
                                    <span>{transfer.User_Name}</span>
                                </li>
                                <li>
                                    <label htmlFor="">Beneficiary</label>
                                    <span>{transfer.Beneficiary_Name}</span>
                                </li>
                                <li>
                                    <label htmlFor="">Wallet Type</label>
                                    <span>MAG Wallet</span>
                                </li>
                                <li>
                                    <label htmlFor="">Payment Mode</label>
                                    <span>Internal Transfer</span>
                                </li>
                                <li>
                                    <label htmlFor="">TID</label>
                                    <span>{transfer.Payment_ID}</span>
                                </li>
                                <li>
                                    <label>Amount</label>
                                    <span>{transfer.Transaction_Amount} PKR</span>
                                </li>
                                <li>
                                    <label>Fee</label>
                                    <span>{transfer.Commission_Amount} PKR</span>
                                </li>
                                <li>
                                    <label>Receivable Amount</label>
                                    <span>{transfer.Receiveable_Amount} PKR</span>
                                </li>
                                <li>
                                    <img src={stamp} alt="mag_stamp" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="invoice-footer">
                    <button className='button button-bs w-100' onClick={handleCapture}>
                        Save to Gallery
                    </button>
                </div>
            </div>
        </>
    )
}

export default TransferInvoice