import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import deposit from "../../../assets/images/admin/icons/deposit.png";

import earnings from "../../../assets/images/admin/icons/earnings.png";
import paid_withdrawl from "../../../assets/images/admin/icons/paid_withdrawl.png";

import pending_withdrawl from "../../../assets/images/admin/icons/pending_withdrawl.png";

import AdminHeader from "../AdminHeader";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";

function MonthlyFundsDashboard() {
  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <PageTitle title="MONTHLY FUND STATISTICS" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="/admin/monthly-funds/dashboard/monthly-fund-statistic"
              class="green"
              value="45$"
              label="DEPOSIT"
              icon={deposit}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="90%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="/admin/monthly-funds/dashboard/monthly-fund-statistic"
              class="blue"
              value="80$"
              label="Member
EARNINGS
"
              icon={earnings}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="/admin/monthly-funds/dashboard/monthly-fund-statistic"
              class="green"
              value="50$"
              label="PAID
WITHDRAWAL"
              icon={paid_withdrawl}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="60%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="/admin/monthly-funds/dashboard/monthly-fund-statistic"
              class="red"
              value="30$"
              label="PENDING
WITHDRAWAL"
              icon={pending_withdrawl}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MonthlyFundsDashboard;
