import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import paid from "../../../assets/images/admin/icons/paid.png";
import timeicon from "../../../assets/images/admin/icons/timeicon.png";

import date3 from "../../../assets/images/admin/icons/date3.png";

import date2 from "../../../assets/images/admin/icons/date2.png";
import date1 from "../../../assets/images/admin/icons/date1.png";

import AdminHeader from "../AdminHeader";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";

function AdminCommodityDashboard() {
    return (
        <>
        <AdminRightsCheck />
            <ToastContainer />
            <PageTitle title="COMMODITY" />
            <div className="admin-content">
                <div className="row">
                    <div className="col-md-3">
                        <AdminCard
                            link="#/"
                            class="green"
                            value="45$"
                            label="TOTAL PAID
              ROI"
                            icon={paid}

                            progress="progress"
                            progressbar="progress-bar"
                            progressrole="progressbar"
                            val="10%"
                            arianow="10"
                            ariamin="0"
                            ariamax="100"
                            progressval=""
                        />
                    </div>
                    <div className="col-md-3">
                        <AdminCard
                            link="#/"
                            class="red"
                            value="2$"
                            label="TOTAL PENDING
              ROI"
                            icon={timeicon}
                            progress="progress"
                            progressbar="progress-bar"
                            progressrole="progressbar"
                            val="30%"
                            arianow="10"
                            ariamin="0"
                            ariamax="100"
                            progressval=""
                           
                        />


                    </div>
                    <div className="col-md-3">
                        <AdminCard
                            link="#/"
                            class="blue"
                            value="41$"
                            label="3 MONTH BEFORE 
"
                            icon={date3}
                            progress="progress"
                            progressbar="progress-bar"
                            progressrole="progressbar"
                            val="50%"
                            arianow="10"
                            ariamin="0"
                            ariamax="100"
                            progressval=""
                           
                        />
                    </div>
                    <div className="col-md-3">
                        <AdminCard
                            link="#/"
                            class="brown"
                            value="43$"
                            label="2 MONTH BEFORE
"
                            icon={date2}
                            progress="progress"
                            progressbar="progress-bar"
                            progressrole="progressbar"
                            val="20%"
                            arianow="10"
                            ariamin="0"
                            ariamax="100"
                            progressval=""
                           
                        />
                    </div>

                    <div className="col-md-3">
                        <AdminCard
                            link="#/"
                            class="blue"
                            value="35$"
                            label="1 MONTH BEFORE
"
                            icon={date1}
                            progress="progress"
                            progressbar="progress-bar"
                            progressrole="progressbar"
                            val="90%"
                            arianow="10"
                            ariamin="0"
                            ariamax="100"
                            progressval=""
                          
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminCommodityDashboard;
