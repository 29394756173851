import React, { useEffect, useState, useRef } from 'react';
import QRCode from "react-qr-code";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import Config from '../../../Config';
import { NumberFormat } from '../../Dashboard/General/Functions';
import BsPagination from '../BsPagination';
import HistoryMenu from './HistoryMenu';
import TransactionMenu from './TransactionMenu';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import LoginCheck from '../General/LoginCheck';

function TransferHistoryReceived(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [listOfLedger, setLedger] = useState([]);
    const [ledgerOld, setLedgerOld] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);


    useEffect(() => {

        setLoading(true);
        
        const Parms = {
            UserID: UserID,
            AccessKey: AccessKey,
            RequestType: "1",
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/BalanceTrasnferDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };



        axios(api_config)
            .then(function (response) {

                //console.log(response.data.listofTransfers);
                if (response.data.status_code == 1) {
                    setLedger(response.data.listofTransfers);
                    setLedgerOld(response.data.listofTransfers);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);
                }


            })
            .catch(function (error) {
                console.log(error);
            });


    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    let amount = 0;
                    if (item.Debit != 0) {
                        amount = item.Debit;
                    }
                    else {
                        amount = item.Credit;
                    }

                    return (
                        <tr key={index}>
                            <td>{item.Payment_ID}</td>
                            <td>{moment(item.Created_Date).format('DD/MM/YYYY')}</td>
                            <td>{item.User_Name}</td>
                            <td>{item.Beneficiary_Name}</td>
                            <td style={{ whiteSpace: "nowrap" }}>PKR {NumberFormat(item.Receiveable_Amount)}</td>
                            <td>{item.Notes}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }



    return (
        <>
            <LoginCheck />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Receipt</Modal.Title>
                </Modal.Header>
                <Modal.Body>Under Construction</Modal.Body>

            </Modal>

            <TransactionMenu />
            <div className="partner-table">
                <HistoryMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Transaction ID</th>
                                <th>Date</th>
                                <th>Sender</th>
                                <th>Beneficiary</th>
                                <th>Receivable Amount</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default TransferHistoryReceived;