import React, { useState, useEffect } from "react";

import { Cropper } from "react-cropper";
import { useNavigate } from "react-router-dom";
import SupportMenu from "./SupportMenu";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import config from "../../../Config";
import axios from "axios";
import { Form } from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';
import LoginCheck from "../General/LoginCheck";
import { PaperClipIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";

function NewTicket(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState();
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');

    const [AttachmentString, setAttachmentString] = useState("");
    const [Attachfile, setAttachfile] = useState(false);

    let navigate = useNavigate();

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }


    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            setAttachmentString(cleanerBase64);
            handleCloseModal();
            toast.success("File Attached Successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
            setAttachfile(true);

        }
    };


    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                Subject: subject,
                Description: description,
                TicketType: type,
                FileAttached: AttachmentString,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Support/AddSupportTicket',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setAttachfile(false);
                        setSubject('');
                        setDescription('');
                        setType('');
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };



    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <Modal show={showModal} onHide={handleCloseModal} className="upload-img-model">
                <Modal.Header closeButton>
                    <Modal.Title className="Partner-title">Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-img model-pink">
                        <div className="upload-box">
                            <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                            {
                                imageSelected ?
                                    <>
                                        <Cropper

                                            zoomTo={0.5}
                                            // style={{ width: '300px', height: '220px'}}
                                            preview=".img-preview"
                                            src={image}
                                            dragMode="move"
                                            viewMode={2}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                            guides={true}
                                        />
                                        <div className="delete" onClick={() => setImageSelected(false)}>
                                            <TrashIcon />
                                        </div>
                                    </>
                                    :
                                    <label htmlFor="ChoosePhoto" className="thumnail">
                                        <ViewfinderCircleIcon />
                                        <h6>Browse Photo</h6>
                                    </label>
                            }
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="button button-outline-bs" onClick={handleCloseModal}>Close</button>
                        <button className="button button-bs" onClick={getCropData}>Save Photo</button>
                    </div>

                </Modal.Body>



            </Modal>

            <div className="new-ticket-wrap">

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="icon-input mt-3">
                        <input
                            type="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder='Subject'
                            maxLength={20}
                            className=""
                            required
                        />
                    </div>
                    <div className="icon-input">
                        <select className="form-select" name="type" required
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="">Department</option>
                            <option value="General">General</option>
                            <option value="Priority support">Priority support</option>
                            <option value="Verification">Verification</option>
                            <option value="Deposit">Deposit</option>
                            <option value="Withdrawal">Withdrawal</option>
                            {/* <option value="Transfer">Transfer</option> */}
                        </select>
                    </div>
                    <div className="icon-input">
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            type="text"
                            rows="11"
                            placeholder='Message'
                            className=""
                            required
                        />
                    </div>
                    <a href="#/" type="button" className={`attachment-link mb-3 ${Attachfile ? "active" : ''}`} onClick={handleShowModal}>
                        <PaperClipIcon />
                        <span>Attatch File</span>
                    </a>
                    <div className="text-center">
                        <button className="button button-bs w-100">{loading ? "loading..." : "SUBMIT TICKET"}</button>
                    </div>
                </Form>
            </div>

        </>
    );
}

export default NewTicket;