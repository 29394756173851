import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Common/Util";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import { ArrowPathIcon, AtSymbolIcon, BuildingLibraryIcon, BanknotesIcon, ChatBubbleLeftRightIcon, UserIcon, TrashIcon, ViewfinderCircleIcon, UserCircleIcon, WalletIcon } from "@heroicons/react/24/outline";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Config from "../../Config";

const CashOutForm = () => {


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);


    let navigate = useNavigate();

    const [showFilters, setShowFilters] = useState(true);


    const [Remarks, setRemarks] = useState('');
    const [Amount, setAmount] = useState('');
    const [paymentMode, setpaymentMode] = useState('By Hand');
    const [ExpenseType, setExpenseType] = useState('');

    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState('');

    const [ReceiptImg, setReceiptImg] = useState(null);
    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);

    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

    const DeleteImage = () => {
        setImageSelected(false);
        setImage('');
        setCropper();
    }


    const handelSendCash = (() => {

        let base64_string = "";

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            base64_string = cleanerBase64;


        }


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            AdminRemarks: Remarks,
            Amount: Amount,
            FileAttached: base64_string,
            PaymentMode: paymentMode,
            ExpenseType: ExpenseType,
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: Config.base_url + "Users/AddUserCashOut",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else if (response.data.status_code === 0 || response.data.status_code === 2) {
                    setLoading(false);
                    toast.error(response.data.status_code, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/franchise/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });

    });


    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDrop = (event) => {

        event.preventDefault();
        setImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setImageSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                if (cropper) {
                    cropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setImageSelected(false)
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setLoading(true);
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            setReceiptImg(cleanerBase64);


        }
    };

    useEffect(() => {
        if (imageCropped) {
            getCropData();
        }
    }, [imageCropped]);


    return (
        <>
            <FranchiseLoginCheck />
            <ToastContainer />

            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Cash Out
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/franchise/dashboard">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label htmlFor="">Amount</label>
                                            <div className="icon-input">
                                                <input type="text" placeholder='Amount' value={Amount} onChange={(e) => setAmount(e.target.value)} />
                                                <div className="icon">
                                                    <BanknotesIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label htmlFor="">Payment Mode</label>
                                            <div className="icon-input">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={paymentMode}
                                                    onChange={(e) => setpaymentMode(e.target.value)}
                                                >
                                                    <option value="By Hand">By Hand</option>
                                                    <option value="By Bank">By Bank</option>
                                                    <option value="Office Expense">Office Expense</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        paymentMode == 'By Hand' ?
                                            <div className="col-md-3">
                                                <div className="icon-form-group">
                                                    <label htmlFor="">Person Name</label>
                                                    <div className="icon-input">
                                                        <input type="text" placeholder='Person Name' value={ExpenseType} onChange={(e) => setExpenseType(e.target.value)} />
                                                        <div className="icon">
                                                            <UserCircleIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            (paymentMode == 'By Bank' ?

                                                <div className="col-md-3">
                                                    <div className="icon-form-group">
                                                        <label htmlFor="">Bank Name</label>
                                                        <div className="icon-input">
                                                            <input type="text" placeholder='Bank Name' value={ExpenseType} onChange={(e) => setExpenseType(e.target.value)} />
                                                            <div className="icon">
                                                                <BuildingLibraryIcon />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                (paymentMode == 'Office Expense' ?

                                                    <div className="col-md-6">
                                                        <div className="icon-form-group">
                                                            <label htmlFor="">Expense Type</label>
                                                            <div className="icon-input">
                                                                <input type="text" placeholder='Expense Type' value={ExpenseType} onChange={(e) => setExpenseType(e.target.value)} />
                                                                <div className="icon">
                                                                    <WalletIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                                )
                                            )
                                    }
                                    <div className="col-md-12">
                                        <div className="icon-form-group">
                                            <label htmlFor="">Remarks</label>
                                            <div className="icon-input">
                                                <input type="text" placeholder='Remarks' value={Remarks} onChange={(e) => setRemarks(e.target.value)} />
                                                <div className="icon">
                                                    <ChatBubbleLeftRightIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="upload-img gray my-3 w-100">
                                            <div className="card-inner">
                                                <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                    id="drop-area"
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleDrop}
                                                >
                                                    <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                    {
                                                        imageSelected ?
                                                            <>
                                                                <Cropper
                                                                    // style={{ width: '550px', height: '220px;', marginTop: '-10px' }}
                                                                    zoomTo={0.5}
                                                                    preview=".img-preview"
                                                                    src={image}
                                                                    dragMode="move"
                                                                    viewMode={3}
                                                                    minCropBoxHeight={10}
                                                                    minCropBoxWidth={10}
                                                                    background={false}
                                                                    responsive={true}
                                                                    autoCropArea={1}
                                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                    onInitialized={(instance) => {
                                                                        setCropper(instance);
                                                                    }}
                                                                    guides={true}
                                                                />
                                                                <div className="delete" onClick={DeleteImage}>
                                                                    <TrashIcon />

                                                                </div>
                                                            </>

                                                            :
                                                            <>
                                                                <label htmlFor="ChoosePassportimage" className='thumnail'>
                                                                    <ViewfinderCircleIcon />
                                                                    <h6>Drag and drop your document</h6>
                                                                    <p>Max size 2 MB. Files allowed: JPG, PNG</p>
                                                                </label>

                                                            </>

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-end">
                                        <button className="button button-primary" onClick={handelSendCash} disabled={loading}>
                                            Submit {loading ? '...' : ''}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default CashOutForm