import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AdminRightsCheck from "../AdminRightsCheck";
import { Badge } from "react-bootstrap";
import { NumberFormat } from "../../Dashboard/General/Functions";
import Form from 'react-bootstrap/Form';
import AdminCard from "../AdminCard";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import ReactTooltip from "react-tooltip";

function LeadershipView(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [validated, setValidated] = useState(false);

    const [isUpdated, setIsUpdated] = useState(false);
    const [Updated, seUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [DetailLoading, setDetailLoading] = useState(false);
    const [TargetLoading, setTargetLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    //   console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();


    const [showFilters, setShowFilters] = useState(true);

    const [AllMembers, SetAllMembers] = useState(false);
    const [Status, setStatus] = useState('All');
    const [Amount, setAmount] = useState('');
    const [CommissionRate, setCommissionRate] = useState('');
    const [TargetStartDate, setTargetStartDate] = useState('');
    const [TargetEndDate, setTargetEndDate] = useState('');
    const [TargetUser, setTargetUser] = useState('');
    const [DetailShow, setDetailShow] = useState(false);


    const [OTP, setOTP] = useState('');
    const [CommissionAmount, setCommissionAmount] = useState('');
    const [AmountLoading, setAmountLoading] = useState(false);

    const [IsDelect, setIsDelect] = useState(false);

    const [TargetData, setTargetData] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    const param = useParams();

    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberID: AllMembers ? '0' : param.id,
            RecordType: Status,
            startDate: StartDate,
            endDate: EndDate,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderCommissionList",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofLedger);
                    setLoading(false);
                    setGrandTotal(response.data);
                    setTotalRecords(response.data.totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
            });
    }, [pageNo, pageSize, isUpdated, StartDate, EndDate, TargetLoading, IsDelect, Updated, AmountLoading, Status, AllMembers]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const [showCommissionModal, setshowCommissionModal] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [User_ID, setUser_ID] = useState(null);
    const handleShowModal = (id) => {
        setShowModal(true);
        setDetailLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: id,
            startDate: StartDate,
            endDate: EndDate,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderCommissionStatus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setTargetData(response.data);
                    setDetailLoading(false);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    const handleCloseModal = (closeModal) => {
        setShowModal(closeModal);
        setUser_ID('');
    }
    const CommissionCloseModal = (closeModal) => {
        setshowCommissionModal(false);
        setTargetUser('');
        setOTP('');
        setCommissionAmount('');
    }
    const CommissionShowModal = (ID) => {
        setshowCommissionModal(true);
        setTargetUser(ID);
    }



    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{item?.SelectedMember?.Full_Name}</td>
                        <td>{item?.SelectedMember?.User_Name}</td>
                        <td>PKR {NumberFormat(item.AchievedTarget)}</td>
                        <td>PKR {NumberFormat(item.RemainingTarget)}</td>
                        <td>PKR {NumberFormat(item.TargetAmount)}</td>
                        <td>PKR {NumberFormat(item.AchievedTarget / 100 * item.CommissionPercentage)}</td>
                        <td>{NumberFormat(item.CommissionAmount)}</td>
                        <td>{item.CommissionPaidDate != null && moment(item.CommissionPaidDate).format('L')}</td>
                        <td>{item.CommissionPercentage} %</td>
                        <td>{item.CommissionPaid ? <Badge bg="dark">Paid</Badge> : <Badge bg="danger">Pending</Badge>}</td>
                        <td>{moment(item.StartDate).format('L')} <br /> {moment(item.EndDate).format('L')}</td>
                        <td>
                            <ul
                                className="inline-action justify-content-start flex-nowrap"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                    <button
                                        className="black"
                                        onClick={(id) => handleShowModal(item.ID)}
                                    >
                                        <span class="material-symbols-outlined">
                                            fullscreen
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={(ID, FullName) => handleDetail(item.ID, item?.SelectedMember?.Full_Name)}
                                        className="primary "
                                    >
                                        <i className="ri-edit-line"></i>
                                    </button>
                                </li>
                                <li><Link className="red" onClick={(e) => DelectItem(item.ID)} type="button"><i className="ri-delete-bin-line"></i></Link></li>
                                {item.CommissionPaid ?
                                    ""
                                    :
                                    <li>
                                        <button className="gray"
                                            onClick={(ID) => CommissionShowModal(item.ID)}
                                            type="button" >
                                            <span className="material-symbols-outlined">
                                                send_money
                                            </span>
                                        </button>
                                    </li>
                                }
                            </ul>
                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="16">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleDetail = (ID, FullName) => {
        setDetailShow(true);
        setUser_ID(ID);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderTargetByID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data, 'by id');
                if (response.data.status_code == 1) {
                    setTargetStartDate(moment(response.data.LeaderCommission?.StartDate).format("YYYY-MM-DD"));
                    setTargetEndDate(moment(response.data.LeaderCommission?.EndDate).format("YYYY-MM-DD"));
                    setAmount(response.data.LeaderCommission?.TargetAmount);
                    setCommissionRate(response.data?.LeaderCommission?.CommissionPercentage);

                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    const handleCloseDetail = () => {
        setDetailShow(false);
        setUser_ID('');

    }

    const handleTarget = (event) => {
        setTargetLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            TargetAmount: Amount,
            startDate: TargetStartDate,
            endDate: TargetEndDate,
            CommissionRate: CommissionRate,
            ID: User_ID,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/UpdateLeaderTarget",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setTargetLoading(false);
                    handleNull();
                    handleCloseDetail();
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };
    const handleNull = () => {
        setTargetStartDate('');
        setTargetEndDate('');
        setAmount('');
        setCommissionRate('');
        setUser_ID('');
    }

    const DelectItem = (ID) => {
        if (window.confirm('Are you sure to delete the Target')) {

            setIsDelect(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID,
            };

            // console.log('data', data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'LeaderCommission/DeleteLeaderTargetByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsDelect(false);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsDelect(false);
                    }
                })
                .catch(function (error) {
                    toast.error(error.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                });
        }
    };
    const CommissionTransfer = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setAmountLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                LeaderCommissionID: TargetUser,
                PINCode: OTP,
                Amount: CommissionAmount,
            };


            var api_config = {
                method: 'post',
                url: config.base_url + 'Users/RSMCommissionTransfer',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setAmountLoading(false);
                        setTargetUser('');
                        setshowCommissionModal(false);
                        setOTP('');
                        setCommissionAmount('');
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch(function (error) {
                    toast.error(error.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                });
        }
    };
    return (
        <>
            <ToastContainer />
            <AdminRightsCheck />
            <ReactTooltip />
            <Modal className="bs-modal" show={showCommissionModal} onHide={CommissionCloseModal}>
                <Modal.Header>
                    <Modal.Title>Leadership Commission Transfer</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={CommissionTransfer}>
                    <Modal.Body>

                        <div className="icon-input">
                            <input
                                type="text"
                                value={CommissionAmount}
                                onChange={(e) => setCommissionAmount(e.target.value)}
                                placeholder='Enter Commission Amount'
                                className=""
                                required
                            />
                            <div className="icon">
                                <BanknotesIcon />
                            </div>
                        </div>

                        <div className="icon-input">
                            <input
                                type="number"
                                value={OTP}
                                placeholder='Enter Transaction Pin'
                                onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                        setOTP(e.target.value);
                                    }
                                }}
                                maxLength={6}
                                className="text-center"
                                required
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="button button-outline-bs button-icon-bs" onClick={CommissionCloseModal}>
                            Cancel
                        </button>
                        <button className="button button-bs button-icon-bs"
                            disabled={OTP.length === 0 || CommissionAmount.length === 0 || AmountLoading}
                            type="submit" variant="primary">
                            Transfer {AmountLoading ? '...' : ''}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal
                className="admin-support-modal modal-theme  p-0"
                show={showModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Target Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-start">
                    {DetailLoading ?
                        <Spinner animation="border" role="status"></Spinner>
                        :
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <AdminCard
                                    link="/admin/support-dashboard/new-ticket"
                                    class="brown"
                                    value={"PKR " + NumberFormat(TargetData.selfInvestment)}
                                    label="Self Investment"
                                    icon="add_reaction"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="/admin/support-dashboard/open-ticket"
                                    class="green"
                                    value={"PKR " + NumberFormat(TargetData.teamInvestment)}
                                    label="Team Investment"
                                    icon="open_in_new"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="#/"
                                    class="blue"
                                    value={"PKR " + NumberFormat(TargetData?.TargetDetails?.TargetAmount)}
                                    label="Target Amount"
                                    icon="target"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="#/"
                                    class="red"
                                    value={"PKR " + NumberFormat(TargetData?.TargetDetails?.AchievedTarget)}
                                    label="Achieved Target"
                                    icon="task_alt"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="#/"
                                    class="red"
                                    value={"PKR " + NumberFormat(TargetData?.TargetDetails?.RemainingTarget)}
                                    label="Remaining Target"
                                    icon="pending"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="#/"
                                    class="red"
                                    value={"PKR " + NumberFormat(TargetData?.TargetDetails?.CommissionAmount)}
                                    label="Commission Paid"
                                    icon="how_to_reg"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <Modal
                className="bs-modal"
                show={DetailShow}
                onHide={handleCloseDetail}
                onExit={handleNull}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Target Form</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="icon-form-group">
                        <label htmlFor="">From</label>
                        <div className="icon-input">
                            <input
                                type="date"
                                className="form-control"
                                value={TargetStartDate}
                                onChange={(e) => setTargetStartDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="icon-form-group">
                        <label htmlFor="">To</label>
                        <div className="icon-input">
                            <input
                                type="date"
                                className="form-control"
                                value={TargetEndDate}
                                onChange={(e) => setTargetEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="icon-form-group">
                        <label htmlFor="">Target Amount</label>
                        <div className="icon-input">
                            <input type="text" placeholder='Target Amount' value={Amount} onChange={(e) => setAmount(e.target.value)} />
                            <div className="icon">
                                <BanknotesIcon />
                            </div>
                        </div>
                    </div>
                    <div className="icon-form-group">
                        <label htmlFor="">Commission %</label>
                        <div className="icon-input">
                            <input type="text" placeholder='Commission %' value={CommissionRate} onChange={(e) => setCommissionRate(e.target.value)} />
                            <div className="icon">
                                <b>%</b>
                            </div>
                        </div>
                    </div>
                    <button className="button button-primary w-100" disabled={TargetLoading} type='submit' onClick={handleTarget}>
                        Update Target {TargetLoading ? '...' : ''}
                    </button>
                </Modal.Body>
            </Modal>
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Leadership Commission History
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/userstatistics-dashboard/leadership" className="button button-outline-bs">
                                Back
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Select Status</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value="All"> All </option>
                                                    <option value="Paid">Paid</option>
                                                    <option value="Pending">Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <Form.Check
                                            className="mb-md-3"
                                            onClick={() => SetAllMembers(!AllMembers)}
                                            value={AllMembers}
                                            type={'checkbox'}
                                            id={`AllMembers`}
                                            label={`View All Members`}
                                        />


                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/userstatistics-dashboard/leadership-view/pdf?StartDate=${StartDate}&EndDate=${EndDate}&Full_Name=${Full_Name}&ID=${ AllMembers ? '0' : param.id}&Status=${Status}`}>PDF</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Member Name</th>
                                    <th>Username</th>
                                    <th>Achieved Target</th>
                                    <th>Remaining Target</th>
                                    <th>Target Amount</th>
                                    <th>Commission Calculation</th>
                                    <th>Paid Commission</th>
                                    <th>Paid Commission Date</th>
                                    <th>Commission Percentage</th>
                                    <th>Status</th>
                                    <th>Start Date <br />End Date</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="16">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                        {/* {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th colSpan={4} className="border-bottom-0 pt-3"></th>
                                                    <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Current_Balance'))}</th>
                                                    <td className="border-bottom-0 pt-3"></td>

                                                </tr>
                                                <tr className="">
                                                    <th className="pt-3" colSpan={4}></th>
                                                    <th className="text-center">Grand Total:</th>
                                                    <th>PKR {NumberFormat(GrandTotal.totalBalance)}</th>

                                                    <td style={{ borderButtom: "1px solid !important" }}></td>

                                                </tr>
                                            </>
                                        } */}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
export default LeadershipView