import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import Config from "../../../Config";
import { NumberFormat } from "../../Dashboard/General/Functions";
import ActiveWalletMenu from "./ActiveWalletMenu";
import BsPagination from "../BsPagination";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import TransactionMenu from "./TransactionMenu";
import LoginCheck from "../General/LoginCheck";

function ActiveWallet(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    useEffect(() => {


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Wallet/GetDirectBonus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                }

                setLoading(false);


            })
            .catch(function (error) {
                console.log(error);
            });





    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const amountTotal = () => {
        let totalAmount = 0;
        listOfLedger.forEach(item => {
            totalAmount += item.RewardAmount;

        });

        return <span>{NumberFormat(totalAmount)}</span>

    }



    const numberWithCommas = (number) => {
        return parseFloat(number).toLocaleString('en-US');
    }





    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (

                listOfLedger.map((item, index) => {
                    let amount = 0;

                    if (item.Debit != 0) {
                        amount = item.Debit;
                    }
                    else {
                        amount = item.Credit;
                    }

                    return (
                        <tr key={index}>
                            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                            <td>{moment(item.Transaction_Date).format('DD/MM/YYYY')}</td>
                            <td style={{ width: "100px" }}>{item.PurchaserUserID}</td>
                            <td style={{ width: "160px" }}>{item.Name}</td>
                            <td>PKR {item.PackageAmount != null ? NumberFormat(item.PackageAmount) : ''}</td>
                            <td>{item.RewardPercent != null ? numberWithCommas(item.RewardPercent) + '%' : ''}</td>
                            <td>{item.RewardAmount != null ? "PKR " + NumberFormat(item.RewardAmount) : ''}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="8">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }



    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <ActiveWalletMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Date</th>
                                <th>Referral</th>
                                <th>Package Name</th>

                                <th>Package Amount</th>
                                <th>Percentage</th>
                                <th>Total Received</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <tr className="table-total">
                                                <td colSpan="6" className="text-end"><strong>Total:</strong></td>
                                                <td><strong>PKR {amountTotal()}</strong></td>
                                            </tr>


                                        }
                                    </>

                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&


                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />

                    }
                </div>
            </div>


        </>
    );
}

export default ActiveWallet;