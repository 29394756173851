import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../Config';
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../../assets/images/partner/mag_logo.png';
import { NumberFormat3D, NumberFormatEmpty } from '../../Dashboard/General/Functions';

export const NumberFormat = (value) => {
    if (value == null) {
        return "";
    }
    else {
        const formattedNumber = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);

        return formattedNumber;
    }

};

function StateementsPdf(props) {

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [loading, setLoading] = useState(false);
    const [listofLedger, SetlistofLedger] = useState([])
    const [closingBalance, setClosingBalance] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);

    const [totalDebit, settotalDebit] = useState("");
    const [totalCredit, settotalCredit] = useState("");
    const [cashBalance, setcashBalance] = useState("");


    const [openingBalance, setOpeningBalance] = useState("");

    const urlParams = new URLSearchParams(window.location.search);
    const StartDate = urlParams.get('StartDate');
    const EndDate = urlParams.get('EndDate');
    const Full_Name = urlParams.get('Full_Name');
    const [AllData, setAllData] = useState('');

    useEffect(() => {

        document.title = "Mag Holdings Admin";
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: 1,
            pageSize: 200,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
            UserName: Full_Name
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetAccountStatement',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.warn(data)
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setAllData(response.data);
                    SetlistofLedger(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                    settotalDebit(response.data.totalDebit);
                    settotalCredit(response.data.totalCredit);
                    setcashBalance(response.data.cashBalance);
                    setClosingBalance(response.data.closingBalance);
                    setOpeningBalance(response.data.openingBalance);
                    setLoading(false);

                } else {
                    setLoading(false);
                    // Handle error

                }
                //   setLoading(false);
            })
            .catch(function (error) {

                // console.log(error);
            });




    }, []);


    // Create styles
    const styles = StyleSheet.create({
        page: {
            //flexDirection: 'row',
            backgroundColor: '#FFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexDirection: 'column',
            marginTop: 0,
            marginBottom: 20
            //flexGrow: 1
        },
        header: {
            margin: 5,
            marginBottom: 6.5,
            padding: 0,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10
        },
        dataview: {
            fontSize: "8pt"
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderWidth: .5,
            borderColor: '#f0f0f0',
            marginTop: 0,
            marginBottom: 10,
        },
        tableHead: {
            flexDirection: 'row',
            backgroundColor: '#f0f0f0'
        },
        tablefooter: {
            flexDirection: 'row',
            backgroundColor: '#FF1500'
        },
        tabletophead: {
            flexDirection: 'row',
            backgroundColor: '#FF1500',
            color: '#fff',
            borderStyle: 'dashed',
            borderWidth: 1,
            borderColor: '#f0f0f0',
            borderBottomColor: '#fff',
        },
        thValuecenter: {
            fontSize: "10pt",
            textAlign: "center",
            color: '#fff',
            fontWeight: 'bold',
        },
        th: {
            flex: 1,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: .1,
            borderColor: '#f0f0f0',
            color: '#000'
        },
        footerth: {
            flex: 1,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: .1,
            borderColor: '#f0f0f0',
            color: '#fff'
        },
        thValue: {
            fontSize: "8pt",
            fontWeight: "bold"
        },

        row: {
            flexDirection: 'row',
        },
        td: {
            flex: 1,
            padding: 5,
            borderBottom: 1,
            borderStyle: 'solid',
            borderBottomWidth: .5,
            borderColor: '#f0f0f0',
        },
        textPrimary: {
            flex: 1,
            padding: 5,
            borderBottom: 1,
            borderStyle: 'solid',
            borderBottomWidth: .5,
            borderColor: '#f0f0f0',
            color: '#FF1500'
        },
        tdValue: {
            fontSize: "8pt",
        },
        box: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

        },
        boxleft: {
            width: '50%',
            height: '150px',
            marginTop: '50px'
        },
        boxright: {
            width: '50%',
        },
        invoicecontent: {
            margin: 20,
            marginTop: 5,
            borderRadius: 10,
            border: .5,
            borderWidth: 1,
            borderColor: '#f0f0f0',
            backgroundColor: '#f0f0f0',
            height: '150px'

        },

        li: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            paddingVertical: 6,
            borderBottomWidth: .2,
            borderBottomColor: '#000',
        },
        boxwrap: {
            paddingVertical: 4,
        },
        label: {
            width: '50%',
            fontSize: 11,
            fontWeight: 'extralight',
            color: '#000',
        },
        boxlabel: {
            fontSize: 15,
            fontWeight: 'extralight',
            color: '#000',
        },
        span: {
            width: '50%',
            fontSize: 11,
            fontWeight: '400',
            color: '#000',
            textAlign: 'left',
        },
        li_first: {
            borderRadius: 10,
            borderBottomLeftRadius: .5,
            borderBottomRightRadius: .5,
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            borderBottomWidth: 1,
            backgroundColor: '#FF1500',
            borderBottomColor: '#FF1500',
            paddingVertical: 10,
        },

    });

    const generateData = () => {
        if (listofLedger != null && listofLedger.length > 0) {
            let count = 1;
            return (
                listofLedger.map((item, index) => {
                    return (
                        <View key={index} style={styles.row} break={index > 1 && index % 12 === 0}>
                            <View style={styles.td}>
                                <Text style={styles.tdValue}> {moment(item.Dated).format("DD/MM/YYYY")}</Text>
                            </View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.TransactionDetails)}</Text></View>
                            <View style={styles.td}><Text style={styles.tdValue}>{(item.ReferenceNo)}</Text></View>
                            <View style={styles.td}><Text style={styles.tdValue}>{NumberFormat(item.Debit)}</Text></View>
                            <View style={styles.td}><Text style={styles.tdValue}>{NumberFormat(item.Credit)}</Text></View>
                            <View style={styles.td}><Text style={styles.tdValue}>{NumberFormat(item.CashBalance)}</Text></View>
                        </View>
                    )
                })
            )
        }

    };

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.section}>
                    <View style={[styles.box, { marginTop: "20px" }]}>
                        <View style={styles.boxleft}>
                            <View style={styles.boxwrap}><Text style={[styles.boxlabel, { color: '#FF1500', marginBottom: "20px" }]}>{AllData.Full_Name}</Text></View>
                            <View style={styles.boxwrap}><Text style={styles.boxlabel}>MAG VENTURES (SMC-PVT) LTD</Text></View>
                            <View style={styles.boxwrap}><Text style={[styles.boxlabel, { marginTop: "5px" }]}>P.O.Box</Text></View>
                            <View style={styles.boxwrap}><Text style={styles.boxlabel}>Islamabad</Text></View>
                        </View>
                        <View style={styles.boxright}>
                            <Image
                                src={Logo}
                                style={{ width: 48, height: 'auto', marginLeft: 'auto', marginRight: '40px' }}
                            />
                            <View style={styles.invoicecontent}>
                                <View style={styles.ul}>
                                    <View style={styles.li_first}>
                                        <Text style={[styles.label, { color: "#fff", width: "100%" }]}>Consolidated Relationship Statement</Text>
                                    </View>

                                    <View style={styles.li}>
                                        <Text style={styles.label}>Username</Text>
                                        <Text style={styles.span}>{AllData.User_Name}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Email</Text>
                                        <Text style={styles.span}>{AllData.Email_Address}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Issue Date</Text>
                                        <Text style={styles.span}>{(moment().format('DD/MM/YYYY'))}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Statement Period</Text>
                                        <Text style={styles.span}>
                                            {StartDate.length > 0 ? StartDate : '01/01/2024'} to {EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={[styles.section, { marginTop: "30px" }]}>
                    <View style={styles.table}>
                        <View style={styles.tabletophead}>
                            <View style={styles.th}><Text style={styles.thValuecenter}>MAG ACCOUNT SUMMARY</Text></View>
                        </View>
                        <View style={styles.tableHead}>
                            <View style={styles.th}><Text style={styles.thValue}>Account Type</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Currency</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Total debit</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Total credit</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Cash balance</Text></View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.td}><Text style={styles.thValue}>Business</Text></View>
                            <View style={styles.td}><Text style={styles.thValue}>PKR</Text></View>
                            <View style={styles.td}><Text style={styles.thValue}>PKR {NumberFormat(totalDebit)}</Text></View>
                            <View style={styles.td}><Text style={styles.thValue}>PKR {NumberFormat(totalCredit)}</Text></View>
                            <View style={styles.td}><Text style={styles.thValue}>PKR {NumberFormat(cashBalance)}</Text></View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
                <View style={styles.section} fixed>
                    <View style={[styles.box]}>
                        <View style={styles.boxleft}>
                            <View style={styles.boxwrap}><Text style={[styles.boxlabel, { color: '#FF1500', marginBottom: "20px" }]}>{AllData.Full_Name}</Text></View>
                            <View style={styles.boxwrap}><Text style={styles.boxlabel}>MAG VENTURES (SMC-PVT) LTD</Text></View>
                            <View style={styles.boxwrap}><Text style={[styles.boxlabel, { marginTop: "5px" }]}>P.O.Box</Text></View>
                            <View style={styles.boxwrap}><Text style={styles.boxlabel}>Islamabad</Text></View>
                        </View>
                        <View style={styles.boxright}>
                            <Image
                                src={Logo}
                                style={{ width: 48, height: 'auto', marginLeft: 'auto', marginRight: '40px' }}
                            />
                            <View style={styles.invoicecontent}>
                                <View style={styles.ul}>
                                    <View style={styles.li_first}>
                                        <Text style={[styles.label, { color: "#fff", width: "100%" }]}>Consolidated Relationship Statement</Text>
                                    </View>

                                    <View style={styles.li}>
                                        <Text style={styles.label}>Username</Text>
                                        <Text style={styles.span}>{AllData.User_Name}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Email</Text>
                                        <Text style={styles.span}>{AllData.Email_Address}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Issue Date</Text>
                                        <Text style={styles.span}>{(moment().format('DD/MM/YYYY'))}</Text>
                                    </View>
                                    <View style={styles.li}>
                                        <Text style={styles.label}>Statement Period</Text>
                                        <Text style={styles.span}>
                                            {StartDate.length > 0 ? StartDate : '01/01/2024'} to {EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tabletophead} fixed>
                            <View style={styles.th}><Text style={styles.thValuecenter}>Account Statement</Text></View>
                        </View>
                        <View style={styles.tableHead} fixed>
                            <View style={styles.th}><Text style={styles.thValue}>Date</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Transaction</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Reference No</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Debit</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Credit</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Cash Balance</Text></View>
                        </View>
                        {
                            listofLedger != null && listofLedger.length > 0 &&
                            <>
                                <View style={styles.row} fixed>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={[styles.textPrimary, { flexGrow: 2 }]}>
                                        <Text style={styles.thValue} render={({ pageNumber, totalPages }) => (
                                            (pageNumber === 2) ? 'Opening Balance' : 'Balance Brought Forward'
                                        )}>
                                        </Text>
                                    </View>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.textPrimary}><Text style={styles.thValue}>PKR {NumberFormat(openingBalance)}</Text></View>
                                </View>
                            </>
                        }
                        {generateData()}
                        {
                            listofLedger != null && listofLedger.length > 0 &&
                            <>
                                <View style={styles.row} fixed>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={[styles.textPrimary, { flexGrow: 2 }]}>
                                        <Text style={styles.thValue} render={({ pageNumber, totalPages }) => (
                                            (pageNumber === totalPages) ? 'Closing Balance' : 'Balance Carried Forward'
                                        )}>
                                        </Text>

                                    </View>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.td}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.textPrimary}><Text style={styles.thValue}>PKR {NumberFormat(closingBalance)}</Text></View>
                                </View>
                            </>
                        }

                    </View>
                    <View style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }} fixed>
                        <Text style={{ fontSize: '8pt' }} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} />
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                {
                    loading ?
                        "Loading..."
                        :
                        <PDFViewer width="100%" height="100%" fileName="MAG Account Statement.pdf">
                            <MyDocument />
                        </PDFViewer>
                }

            </div>
        </>
    );
}

export default StateementsPdf