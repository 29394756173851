export const DecimalFormat = (number) => {
    return parseFloat(number).toFixed(2).toLocaleString('en-US');
    
}


export const convertNumberToDecimal = (number) => {
    const formattedNumber = Number(number).toFixed(2);
    if (formattedNumber.endsWith('.00')) {
      return '0.00';
    }
    return formattedNumber;
  }
  

  export const NumberFormat6D = (value) => {
    if(value == null)
    {
      return "";
    }
    else
    {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      }).format(value);
    
      return formattedNumber;
    }
    
  };

  export const NumberFormat = (value) => {
    if(value == null)
    {
      return "";
    }
    else
    {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    
      return formattedNumber;
    }
    
  };

  export const NumberFormatEmpty = (value) => {
    if(value == null)
    {
      return "";
    }
    else
    {
      if(value == 0)
      {
        return "";
      }
      else
      {
        const formattedNumber = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      
        return formattedNumber;
      }
      
    }
    
  };


  export const NumberFormat3D = (value) => {
    if(value == null)
    {
      return "";
    }
    else
    {
      if(value == 0)
      {
        return "";
      }
      else
      {
        const formattedNumber = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        }).format(value);
      
        return formattedNumber;
      }
    }
  };


  export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  };


  export const replaceCharactersWithStars = (numberOfStars, inputString) => {

    if (!inputString) {
      return ''; // Return an empty string if inputString is undefined or null
    }
    
    const stars = '*'.repeat(numberOfStars);
    const replacedString = stars + inputString.slice(numberOfStars);
  
    return replacedString;
  }
  