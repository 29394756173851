import React, { useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import OtpInput from 'react-otp-input';
import LoginCheck from "../General/LoginCheck";


function EmailVerificationLogin(props) {

    const [step, setStep] = useState(1);
    const [OTP, setOTP] = useState(null);
    

 
    const handleOTP = (otp) => {
        setOTP(otp);
    }
   

    return (
        <>
          <LoginCheck />
            <Offcanvas className="offcanvas-custom size-40" show={props.show} placement="end" onHide={props.handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Email verification setup</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="">
                   
                    <div className="change_email_label mt-3">
                        <span>New email verification code</span>
                        <button className="button">Send Code</button>
                    </div>

                    <div className="OTP_wrap p-0 bg-transparent">
                        <OtpInput
                            value={OTP}
                            onChange={handleOTP}
                            numInputs={6}
                            separator=""
                        />
                    </div>


                    <div className="text-center mt-4">
                        <button className="button button-primary w-100" disabled={true}>Confirm Changes</button>
                    </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default EmailVerificationLogin;