import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import { Link, NavLink, useParams } from "react-router-dom";
import { Cropper } from "react-cropper";
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';
import LoginCheck from "../General/LoginCheck";
import Logo from "../../../assets/images/Support/Logo.svg";
import { ArrowLeftIcon, ArrowUturnLeftIcon, PaperAirplaneIcon, PaperClipIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { Average, Excellent, Good, Happy } from "../../Dashboard/UserControls/Icons";
function TicketsListing(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [replies, setReplies] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [ticket, setTicket] = useState({});
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [imageSelected, setImageSelected] = useState(false);
    const [AttachmentString, setAttachmentString] = useState("");

    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);
    const [replySaved, setReplySaved] = useState(false);
    const [ClosedByAdmin, SetClosedByAdmin] = useState("");
    const [ClosedByUser, SetClosedByUser] = useState("");

    const [loadingTickets, setLoadingTickets] = useState(false);
    const [attachmentImg, setAttachmentImg] = useState(null);
    const [tick, settick] = useState(false);

    const [description, setdescription] = useState("");
    // const status = useRef(null);

    // modals
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const [showModalAttachment, setShowModalAttachment] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleShowModalAttachment = (item) => {
        setAttachmentImg(item);
        setShowModalAttachment(true);
    }

    const handleCloseModalAttachment = () => {
        setShowModalAttachment(false);

    }

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }
    const handleShowModal = () => {
        setShowModal(true);
    }



    useEffect(() => {
        setLoadingTickets(true);
        setReplySaved(false);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: props.id

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetTicketByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                setReplies(response.data.ListofReplies);
                setTicket(response.data);
                SetClosedByAdmin(response.data.ClosedByAdmin);
                SetClosedByUser(response.data.ClosedByUser);
                //console.log(response.data.ListofReplies);
                setLoadingTickets(false);
                const element = document.getElementById("chat-thread");
                element.scrollTop = element.scrollHeight;
            })
            .catch(function (error) {
                // console.log(error);
            });

    }, [replySaved]); // Dependency array


    const BackToOpen = () => {
        props.BackToOpen();
    }

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            setAttachmentString(cleanerBase64);
            handleCloseModal();
            setAttachmentShow(false);
            settick(true);
        }
    };


    const SubmitReply = (event) => {
        setLoading(true);
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                ID: props.id,
                Description: description,
                Status: "",
                FileAttached: AttachmentString,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Support/ReplyTicket',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        setReplySaved(true);
                        setdescription("");
                        setAttachmentString("");
                        // console.log(response.data)
                        // status.current.value = null;
                        setValidated(false);
                        settick(false);
                        // Scroll to the bottom after receiving a message

                        const chatThread = document.getElementById("chat-thread");
                        chatThread.scrollTop = chatThread.scrollHeight;
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
            setValidated(true);
        }
    };
    const renderReplies = () => {
        if (replies != null) {
            return (
                replies.map((item, index) => {

                    return (
                        <li key={index} className={item.Member_ID == 0 ? 'admin-reply' : 'user-reply'}>
                            <div className="comment-item">
                                <div className="thumb">
                                    {
                                        item.Member_ID == 0 ?
                                            <span>A</span>
                                            :
                                            <span>{item.Member_Name.charAt(0)}</span>
                                    }
                                </div>
                                <div className="comment">
                                    <div className="comment-message">
                                        <div className="inner">
                                            {/* <h5>{item.Member_ID > 0 ? item.Member_Name : item.Admin_Name}</h5> */}
                                            <p dangerouslySetInnerHTML={{ __html: item.Description }}></p>
                                            <div className="comment-actions">
                                                <span className="date">{moment(item.Created_Date).format('ddd, DD MMM YYYY HH:mm:ss')}</span>
                                            </div>
                                        </div>
                                        <div className="comment-actions">
                                            {
                                                item.FileAttached != null &&
                                                <>
                                                    <PaperClipIcon onClick={(e) => handleShowModalAttachment(item.FileAttached)} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })
            )
        }
    }

    const [feedback, setfeedback] = useState("");
    const [comment, setcomment] = useState("");

    const handleFeedBack = (event) => {
        if (event.target.checked) {
            setfeedback(event.target.value);

        }
    };

    const sendfeedback = () => {
        setShow(false)
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: props.id,
            Feedback: feedback,
            Comments: ''

        };
        // console.log("feedback", data)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/CloseTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                toast.success(response.data.status_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                window.location.reload();
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    const TicketNotClosed = () => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: props.id,
        };
        var api_config = {
            method: 'post',
            url: config.base_url + 'support/TicketNotClosed',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                toast.success(response.data.status_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                window.location.reload();
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    const rendercloseadmin = () => {
        if (ClosedByUser == true && ClosedByAdmin == false) {
            return (
                <>
                    <li className="d-flex justify-content-center gap-5">
                        <button className="button button-gradient text-primary" onClick={handleShow}>
                            <span className="text-primary d-flex justify-content-around"><i className="material-symbols-outlined">done</i> Yes</span>
                        </button>
                        <button className="button button-gradient text-primary" onClick={TicketNotClosed}>
                            <span className="text-primary d-flex justify-content-around"><i className="material-symbols-outlined">close</i> No</span>
                        </button>
                    </li>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == false) {
            return (
                <>
                    <li className="d-flex justify-content-center gap-5">
                        <button className="button button-gradient text-primary" onClick={handleShow}>
                            <span className="text-primary d-flex justify-content-around"><i className="material-symbols-outlined">done</i> Yes</span>
                        </button>
                        <button className="button button-gradient text-primary" onClick={TicketNotClosed}>
                            <span className="text-primary d-flex justify-content-around"><i className="material-symbols-outlined">close</i> No</span>
                        </button>
                    </li>
                </>
            )
        }
        else if (ClosedByAdmin == false && ClosedByUser == false) {
            return (
                <>

                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == true) {
            return (
                <>

                </>
            )
        }
    }

    const [Attachmentshow, setAttachmentShow] = useState(false);
    const handleAttachmentClose = () => setAttachmentShow(false);

    const handleAttachmentshow = () => {
        setAttachmentShow(true);
    }

    const renderreply = () => {
        if (ClosedByUser == true && ClosedByAdmin == false) {
            return (
                <>
                    <div className="reply">
                        <div className="row align-items-center">
                            <div className="col-md-1 col-2 text-center">
                                <div className="attachment-icon" onClick={handleAttachmentshow}>
                                    <div className={` ${tick ? 'active' : ''}`} >
                                        <PaperClipIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-7">
                                <input
                                    value={description}
                                    className="form-control"
                                    onChange={(e) => setdescription(e.target.value)}
                                    placeholder="Type a Reply" type="text" />
                            </div>

                            <div className="col-md-2 col-3">
                                {
                                    loadingTickets ?
                                        <Spinner animation="border" role="status"></Spinner>
                                        :
                                        <div className="d-flex justify-content-end">
                                            <button
                                                disabled={description.length == 0}
                                                onClick={SubmitReply}
                                            >
                                                <PaperAirplaneIcon />
                                            </button>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == false) {
            return (
                <>
                </>
            )
        }
        else if (ClosedByAdmin == false && ClosedByUser == false) {
            return (
                <>
                    <div className="reply">
                        <div className="row align-items-center">
                            <div className="col-md-1 col-2 text-center">
                                <div className="attachment-icon" onClick={handleShowModal}>
                                    <div className={` ${tick ? 'active' : ''}`}>
                                        <PaperClipIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-7">
                                <input
                                    value={description}
                                    className="form-control"
                                    onChange={(e) => setdescription(e.target.value)}
                                    placeholder="Type a Reply" type="text" />
                            </div>

                            <div className="col-md-2 col-3">
                                {
                                    loadingTickets ?
                                        <Spinner animation="border" role="status"></Spinner>
                                        :
                                        <div className="d-flex justify-content-end">
                                            <button
                                                className="button-reply"
                                                disabled={description.length == 0}
                                                onClick={SubmitReply}>
                                                <PaperAirplaneIcon />
                                            </button>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == true) {
            return (
                <>
                </>
            )
        }
    }
    const renderCloseButton = () => {
        if (ClosedByUser == true && ClosedByAdmin == false) {
            return (
                <>
                    <div className="my-3 text-md-start text-center">
                        <button
                            onClick={handleShow}
                            className="button button-bs button-icon-bs"><ArrowUturnLeftIcon /> Close Ticket</button>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == false) {
            return (
                <>
                </>
            )
        }
        else if (ClosedByAdmin == false && ClosedByUser == false) {
            return (
                <>
                    <div className="my-3 text-md-start text-center">
                        <button
                            onClick={handleShow}
                            className="button button-bs button-icon-bs">
                            <ArrowUturnLeftIcon />
                            Close Ticket
                        </button>
                    </div>
                </>
            )
        }
        else if (ClosedByAdmin == true && ClosedByUser == true) {
            return (
                <>
                </>
            )
        }
    }


    return (
        <>
            <LoginCheck />
            <ToastContainer />

            <Modal className="bs-modal" show={show} onHide={handleClose}>
                <div className="modal-header">
                    <div className="modal-title h4">FeedBack</div>
                    <button type="button" className="button-close" onClick={handleClose}>
                        <span className="material-symbols-outlined">close</span>
                    </button>
                </div>
                <Modal.Body>
                    <ul className={`feedback_list`}>
                        <li>
                            <label
                                htmlFor="FeedBackAverage"
                                className={` ${feedback === 'Average' ? 'active' : ''}`}
                            >
                                <Average />
                                <span>Average</span>
                                <input
                                    id="FeedBackAverage"
                                    className="feedback"
                                    type="radio"
                                    value="Average"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Average'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackGood"
                                className={` ${feedback === 'Good' ? 'active' : ''}`}
                            >
                                <Good />
                                <span>Good</span>
                                <input
                                    id="FeedBackGood"
                                    className="feedback"
                                    type="radio"
                                    value="Good"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Good'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackHappy"
                                className={` ${feedback === 'Happy' ? 'active' : ''}`}
                            >
                                 <Happy />
                                <span>Happy</span>
                                <input
                                    id="FeedBackHappy"
                                    className="feedback"
                                    type="radio"
                                    value="Happy"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Happy'}
                                />
                            </label>
                        </li>
                        <li>
                            <label
                                htmlFor="FeedBackExcellent"
                                className={` ${feedback === 'Excellent' ? 'active' : ''}`}
                            >
                                <Excellent />
                                <span>Excellent</span>
                                <input
                                    id="FeedBackExcellent"
                                    className="feedback"
                                    type="radio"
                                    value="Excellent"
                                    onChange={handleFeedBack}
                                    checked={feedback === 'Excellent'}
                                />
                            </label>
                        </li>
                        <li>
                            <button onClick={sendfeedback}
                                disabled={feedback.length === 0}
                                className="button button-bs w-100"><span>Submit</span></button>
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal
                className="admin-support-modal"
                show={showModalAttachment}
                onHide={handleCloseModalAttachment}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={config.root + `SupportTicket/SupportFiles/${attachmentImg}`}
                        className="img-fluid"
                        alt="Attachment"
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} className="upload-img-model">
                <Modal.Header closeButton>
                    <Modal.Title className="Partner-title">Upload Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-img model-pink">
                        <div className="upload-box">
                            <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                            {
                                imageSelected ?
                                    <>
                                        <Cropper
                                            zoomTo={0.5}
                                            preview=".img-preview"
                                            src={image}
                                            dragMode="move"
                                            viewMode={2}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                            guides={true}
                                        />
                                        <div className="delete" onClick={() => setImageSelected(false)}>
                                            <TrashIcon />
                                        </div>
                                    </>
                                    :
                                    <label htmlFor="ChoosePhoto" className="thumnail">
                                        <ViewfinderCircleIcon />
                                        <h6>Browse Photo</h6>
                                    </label>

                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="button button-outline-bs" onClick={handleCloseModal}>Close</button>
                        <button className="button button-bs" onClick={getCropData}>Save Photo</button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="tickte-detail-head">
                <div>
                    <button onClick={BackToOpen}><ArrowLeftIcon /> </button>
                    <h3>{ticket.Subject}</h3>
                </div>
                <span>{ticket.TicketID}</span>
            </div>

            <div className="type-date">
                <div className="type">{ticket.TicketType}</div>
                <div className="date">{moment(ticket.Dated).format('ddd, DD MMM YYYY HH:mm:ss')}</div>
            </div>


            <div className={`comment-posts bs-comment-post`} >
                <div className="post-item">
                    <ul className="post-info-actions">
                        {(ticket.FileAttached != null) ?
                            <>
                                <li className="attachment-link">
                                    <a href="#" onClick={(e) => handleShowModalAttachment(ticket.FileAttached)}>
                                        <PaperClipIcon />
                                        <span> View Attachment</span>
                                    </a>
                                </li>
                            </>
                            :
                            <>
                                <li className="attachment-link">
                                    <a href="#">
                                        <PaperClipIcon />
                                        <span> View Attachment</span>
                                    </a>
                                </li>
                            </>
                        }
                    </ul>
                    <ul className="post-comments" id="chat-thread">
                        {renderReplies()}
                        {rendercloseadmin()}
                    </ul>
                    <Form noValidate validated={validated} onSubmit={SubmitReply}>
                        {renderreply()}
                    </Form>
                </div>
            </div>
            {renderCloseButton()}
        </>
    );
}

export default TicketsListing;