import React, { useState, useEffect } from "react";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../../Config';
import Pagination from '../../Dashboard/General/Pagination';
import moment from 'moment';
import { NumberFormat, NumberFormat3D, NumberFormatEmpty } from "../../Dashboard/General/Functions";
// import EstatementPrint from "../../Dashboard/Ledger/EstatementPrint";
import AdminRightsCheck from "../AdminRightsCheck";
import { Link, NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { JsonToExcel } from "react-json-to-excel";

function Stateements(props) {


    const [Full_Name, setFull_Name] = useState('');
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [AccountType, setAccountType] = useState("");
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [activeWallet, setactiveWallet] = useState("");
    const [passiveWallet, setpassiveWallet] = useState("");
    const [rewardWallet, setrewardWallet] = useState("");
    const [totalDebit, settotalDebit] = useState("");
    const [totalCredit, settotalCredit] = useState("");
    const [totalEarned, settotalEarned] = useState("");
    const [cashBalance, setcashBalance] = useState("");
    const [openingBalance, setOpeningBalance] = useState("");
    const [closingBalance, setClosingBalance] = useState("");
    const [MemberInfo, setMemberInfo] = useState({});



    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [ExcelArray, setExcelArray] = useState([]);

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [showFilters, setShowFilters] = useState(true);

    useEffect(() => {
        document.title = "Statements";

        setLoading(true);
        setIsUpdated(false);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: startDate,
            endDate: endDate,
            UserName: Full_Name
        };

        // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetAccountStatement',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setAccountType(response.data.accountType);
                setactiveWallet(response.data.activeWallet);
                setpassiveWallet(response.data.passiveWallet);
                setrewardWallet(response.data.rewardWallet);
                settotalDebit(response.data.totalDebit);
                settotalCredit(response.data.totalCredit);
                settotalEarned(response.data.totalEarned);
                setcashBalance(response.data.cashBalance);
                setOpeningBalance(response.data.openingBalance);
                setClosingBalance(response.data.closingBalance);
                setLoading(false);

                const ListOfExeclFile = response.data.ListofLedger.map(({ ...item }) => ({
                    Dated: moment(item.Dated).format("DD/MM/YYYY"),
                    TransactionDetails: item.TransactionDetails,
                    ReferenceNo: item.ReferenceNo,
                    Debit: (NumberFormatEmpty(item.Debit)),
                    Credit: (NumberFormatEmpty(item.Credit)),
                    Earned: (NumberFormat3D(item.Earned)),
                    CashBalance: (NumberFormat(item.CashBalance)),

                }));
                setExcelArray(ListOfExeclFile, ListOfExeclFile)


            })
            .catch(function (error) {
                // console.log(error);
            });





    }, [pageNo, pageSize, startDate, endDate, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {

                    return (
                        <tr key={index}>

                            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
                            <td>{item.TransactionDetails}</td>
                            <td>{item.ReferenceNo}</td>
                            <td>{NumberFormat(item.Debit)}</td>
                            <td>{NumberFormat(item.Credit)}</td>
                            <td>{NumberFormat(item.CashBalance)}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="7"><h4 className="mt-3" style={{ fontWeight: "600", fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }
    }


    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };


    const handleDateFilters = (e) => {

        e.preventDefault();
        e.stopPropagation();


        let from = e.target.from.value;
        let to = e.target.to.value;

        setStartDate(from);
        setEndDate(to);


    }

    useEffect(() => {


        const Parms = {
            ID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetMemberInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setMemberInfo(response.data);

                }
                setLoading(false);

            })
            .catch(function (error) {
                // console.log(error);
            });



    }, []);



    return (
        <>
            <AdminRightsCheck />
            <ToastContainer />
            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Account Statement
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <NavLink to="/admin/Members-overview">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button >
                        </div >
                    </div >
                </div >

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <form method="post" onSubmit={handleDateFilters}>

                                            <div className="row gy-3 align-items-center w-100">
                                                <div className="col-md-4">
                                                    <div className="icon-form-group">
                                                        <label>From</label>
                                                        <div className="icon-input">
                                                            <input
                                                                type="date"
                                                                id="from"
                                                                name="from"
                                                                className="form-control"
                                                                placeholder="DD/MM/YY"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                defaultValue={moment().subtract(1, 'days').format(config.date_format_input)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icon-form-group">
                                                        <label>To</label>
                                                        <div className="icon-input">
                                                            <input
                                                                type="date"
                                                                id="to"
                                                                name="to"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                className="form-control"
                                                                placeholder="DD/MM/YY"
                                                                max={moment().format(config.date_format_input)}
                                                                defaultValue={moment().format(config.date_format_input)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-6">
                                                    <button type="submit" className="button button-primary mt-md-4 input-height input-radius">Generate</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bonus_page_head">
                    <div className="row align-items-center my-3 gy-3">
                        <div className="col-md-8">
                            <p className="m-0">Default range of transaction details is 1 day prior to the current date. Select the date range to change the following.</p>
                        </div>
                        <div className="col-md-1 text-md-end text-center">

                        </div>
                        <div className="col-md-3">
                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                <button type="button" className="button button-primary button-smlg">Email</button>
                                <ul className="downloads">
                                    <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                        <Link target="_blank" to={`/admin/userstatistics-dashboard/stateements-pdf/?StartDate=${startDate}&EndDate=${endDate}&Full_Name=${Full_Name}`}>PDF</Link>
                                    </li>
                                    {/* <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                                        <JsonToExcel
                                            title="EXCEL"
                                            data={ExcelArray}
                                            fileName="MAG Stateement REPORT"
                                            btnClassName="excel-button"
                                        />
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="statement-table mt-4 mb-5">
                    <div className="partner-table">
                        <div className="table-wrap">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan='5'><b>Account Summary</b></th>
                                    </tr>
                                    <tr>
                                        <th>Account Type</th>
                                        <th>Currency</th>
                                        <th>Total debit</th>
                                        <th>Total credit</th>
                                        <th>Cash balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listOfLedger != null && listOfLedger.length > 0 ?
                                        <tr>
                                            <td>Business</td>
                                            <td>PKR</td>
                                            <td>PKR {NumberFormat(totalDebit)}</td>
                                            <td>PKR {NumberFormat(totalCredit)}</td>
                                            <td>PKR {NumberFormat(cashBalance)}</td>
                                        </tr>
                                        :
                                        <tr>
                                            <td colSpan="5"><h4 className="mt-3" style={{ fontWeight: "600", fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="statement-table mt-4 mb-5">
                    <div className="partner-table">
                        <div className="table-wrap">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan='3'>Statement for period <b>{startDate}</b> to <b>{endDate}</b></th>
                                        <th colSpan='7' className="text-end">
                                            {
                                                listOfLedger != null && listOfLedger.length > 0 &&
                                                <>
                                                    {
                                                        pageNo == 1 ?
                                                            'Opening Balance'
                                                            :
                                                            'Balance Brought Forward'
                                                    }
                                                    <b> PKR {NumberFormat(openingBalance)}</b>
                                                </>
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Date</th>
                                        <th>Transaction</th>
                                        <th>Reference No</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Cash Balance</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colSpan="6">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    listOfLedger != null && listOfLedger.length > 0 &&
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            {
                                                                pageNo == 1 ?
                                                                    <span className="text-primary">Opening Balance</span>
                                                                    :
                                                                    <span className="text-primary">Balance Brought Forward</span>
                                                            }
                                                        </td>
                                                        <td colSpan={3}></td>
                                                        <td><span className="text-primary"> {NumberFormat(openingBalance)}</span></td>
                                                    </tr>
                                                }

                                                {renderLedger()}
                                                {
                                                    listOfLedger != null && listOfLedger.length > 0 &&
                                                    <tr>
                                                        <td></td>
                                                        <td><span className="text-primary">{Math.ceil(totalRecords / pageSize) == pageNo ? 'Closing Balance' : 'Balance Carried Forward'}</span></td>
                                                        <td colSpan={3}></td>
                                                        <td><span className="text-primary">{NumberFormat(closingBalance)}</span></td>
                                                    </tr>
                                                }


                                            </>

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



                {
                    totalRecords > 10 &&
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontWeight: "600", fontSize: "15px" }}>Page size</label>
                                    <div className="form-group">
                                        <select className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}>
                                            <option value="10" selected>10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>

                }
            </div>
        </>
    );
}

export default Stateements;