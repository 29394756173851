import React, { useEffect, useRef, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ReactTooltip from "react-tooltip";
import FranchiseHeader from "./FranchiseHeader";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
function FranchiseLayout(props) {



  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    document.documentElement.setAttribute('data-bs-theme', newTheme);
  }

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme])




  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [collapse, setcollapse] = useState(false);

  const CollapseMenu = () => {
    setcollapse(!collapse);
    var admin_wrap = document.getElementsByClassName("admin-wrap");
    for (let i = 0; i < admin_wrap.length; i++) {
      admin_wrap[i].classList.toggle('show');
    }
  }

  const closeMenu = () => {
    var admin_side_bar = document.getElementById("admin_side_bar");
    var overlay = document.getElementById("overlaymenu");
    var menubutton = document.getElementById("menubutton");
    var body = document.getElementById("body");
    body.classList.remove('overflow-hidden');
    if (admin_side_bar) {
      admin_side_bar.classList.remove('active');
    }

    if (overlay) {
      overlay.classList.remove('active');
    }

    //console.log(menubutton);

    if (menubutton) {
      menubutton.classList.remove('active');
    }
  }


  let navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/franchise/login");
  }

  // show active class on menu
  const currentLocation = useLocation();
  const isMenuItemActive = (path) => {
    return currentLocation.pathname.startsWith(path);
  };

  const [activeKey, setActiveKey] = useState(null);
  const accordionRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordionRef.current && !accordionRef.current.contains(event.target)) {
        setActiveKey(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <FranchiseLoginCheck />
      <ReactTooltip />
      <div id="admin-body">
        <FranchiseHeader />
        <div onClick={closeMenu} id="overlaymenu"></div>
        <div className="admin-wrap">
          <div className={`admin-left admin_menu`}
            // menustatus={isMenuOpen}
            id="admin_side_bar">

            <ul className="admin_side_nav">
              <li>
                <NavLink onClick={CollapseMenu}>
                  <i className={`material-symbols-outlined admin_menu_arrow ${collapse ? "show" : ""} `}>
                    {collapse ? "arrow_forward_ios" : "arrow_back_ios"}
                  </i>
                  <span>Menu</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/franchise/dashboard" data-tip={collapse ? "Dashboard" : ""} data-place="right" className={isMenuItemActive('/franchise/dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">dashboard</i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              
              <li>
                <NavLink to="/franchise/add-members" data-tip={collapse ? "Add Member" : ""} data-place="right" className={isMenuItemActive('/franchise/add-members') ? 'active' : ''}>
                  <i className="material-symbols-outlined">manage_accounts</i>
                  <span>Add Member</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/franchise/kyc-request" data-tip={collapse ? "Kyc Approval Requests" : ""} data-place="right" className={isMenuItemActive('/franchise/kyc-request') ? 'active' : ''}>
                  <i className="material-symbols-outlined">manage_accounts</i>
                  <span>Kyc Approval Requests</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/franchise/purchase-package" data-tip={collapse ? "Purchase Package" : ""} data-place="right" className={isMenuItemActive('/franchise/Members-overview') ? 'active' : ''}>
                  <i className="material-symbols-outlined">shopping_cart</i>
                  <span>Purchase Package</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/franchise/fee-receive" data-tip={collapse ? "Process Agreement" : ""} data-place="right" className={isMenuItemActive('/franchise/admin-fee-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">credit_card</i>
                  <span>Process Agreement</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/franchise/withdrawal-dashboard" data-tip={collapse ? "Withdrawal Panel" : ""} data-place="right" className={isMenuItemActive('/franchise/withdrawal-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">wallet</i>
                  <span>Withdrawal Panel</span>
                </NavLink>
              </li>
         
              <Accordion className="d-none" ref={accordionRef} activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>


                <li>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header data-tip={collapse ? "Reports" : ""} data-place="right">
                      <i className="material-symbols-outlined">contract</i>
                      <p>Reports</p>
                    </Accordion.Header>
                    <Accordion.Body>
                     
                      <li className="sub-menu">
                        <NavLink to="/franchise/reports/Scheduler" className={isMenuItemActive('/franchise/reports/Scheduler') ? 'active' : ''}>
                          <i></i>
                          <span>Cash Deposit Report</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/franchise/reports/Scheduler" className={isMenuItemActive('/franchise/reports/Scheduler') ? 'active' : ''}>
                          <i></i>
                          <span>Withdrawal Report</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/franchise/reports/Scheduler" className={isMenuItemActive('/franchise/reports/Scheduler') ? 'active' : ''}>
                          <i></i>
                          <span>Package Purchase Report</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/franchise/reports/Scheduler" className={isMenuItemActive('/franchise/reports/Scheduler') ? 'active' : ''}>
                          <i></i>
                          <span>Balance Transfer Report</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/franchise/reports/Scheduler" className={isMenuItemActive('/franchise/reports/Scheduler') ? 'active' : ''}>
                          <i></i>
                          <span>Add Member Report</span>
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </li>

              </Accordion>

              <li>
                <a href="#" onClick={handleSignOut} data-tip={collapse ? "Sign Out" : ""} data-place="right">
                  <i className="material-symbols-outlined">logout</i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>

          </div>
          <div className="admin-right" id="right-side">
            <Outlet></Outlet>
          </div>
        </div>
        {/* <div className="admin-theme-icons d-none">
          <div className={`iocn ${theme == "light" ? 'active' : ''}`} onClick={toggleTheme}>
            {
              theme != "light" ?
                <MoonIcon className={`${theme == "light" ? 'moon' : ''}`} />
                :
                <SunIcon className={`${theme == "light" ? 'sun' : ''}`} />
            }
          </div>
        </div> */}
      </div>
    </>
  );
}

export default FranchiseLayout;
