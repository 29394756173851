import React, { useEffect, useState } from "react";
import NetworkMember from './NetworkMember';
import axios from "axios";
import Config from "../../../Config";
import { Spinner } from "react-bootstrap";
import BsPagination from "../BsPagination";
import { ArrowLeftIcon, ArrowRightIcon, FaceFrownIcon, UserIcon } from "@heroicons/react/24/outline";
import Slider from "react-slick";

function Network(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [ActiveClass, setActiveClass] = useState('');

    const [members, setMembers] = useState([]);
    const [UserInfo, setUserInfo] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [NoOfLevel, setNoOfLevel] = useState('');


    useEffect(() => {
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            LevelNo: NoOfLevel,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/ViewNetworkPage',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log(data);
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setMembers(response.data.ListofMembers);
                    setUserInfo(response.data);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });

    }, [pageNo, pageSize, NoOfLevel]);


    const renderMembers = () => {
        if (members != null && members.length > 0) {
            return (
                members.map((item, index) => {
                    return (
                        <>
                            <NetworkMember
                                key={index}
                                rank={item.RankName}
                                Count={item.DefaultPlacement}
                                photo={Config.img_path + item.Img_File}
                                RankImg={Config.path + 'Images/Ranks/' + item.RankName + '.png'}
                                name={item.Full_Name}
                                username={item.User_Name}
                                KYCStatus={item.KYCStatus}
                                MemberStatus={item.MemberStatus}
                                TeamCount={item.TeamCount}
                                amount={item.Investment_Balance}
                                level={item.Hiera_IN_xx_NodeLevel}
                                TeamInvestment={item.TeamInvestment}
                                Profit_Received={item.Profit_Received}

                            />
                        </>
                    )
                })
            )
        }
        else {
            return (
                <>
                    <div className="else-emoji">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </div>
                </>
            )
        }
    }

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const settings = {
        dots: false,
        atuoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <ArrowRightIcon className="right-arrow" />,
        prevArrow: <ArrowLeftIcon />,
        responsive: [
            {
                breakpoint: 768, // Mobile breakpoint
                settings: {
                    slidesToShow: 5, // Number of slides to show on mobile
                },
            },
        ],
    };

    const levelsArray = Array.from({ length: (UserInfo.maxLevel > 0 ? UserInfo.maxLevel : '') }, (_, index) => index + 1);


    return (
        <>
            <h3 className="section-title">My Team</h3>
            <div className="row align-items-center my-3 gy-3">
                <div className="col-md-3">
                    <div className="tree-user-profile">
                        <div className="photo">
                            <img src={Config.img_path + UserInfo.Img_File} alt="Profile Photo" />
                        </div>
                        <div className="info">
                            <h5>{UserInfo.Full_Name}</h5>
                            <p>{UserInfo.User_Name}</p>
                            <ul>
                                <li><span>{UserInfo.RankName}</span></li>
                                <li><label htmlFor="">Pv</label><span>{UserInfo.Investment_Balance > 500 ? (UserInfo.Investment_Balance / 500) : '0'}</span></li>
                                <li><label htmlFor="">GPV</label><span>{UserInfo.TotalTeamGPV}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 offset-md-1">
                    <ul className="tree-level-list">

                        <Slider {...settings} className="tree-slider">
                            {levelsArray.map((level, index) => (
                                <>
                                    <li key={level}>
                                        <div className={`level-content ${NoOfLevel == level ? 'active' : ''}`} onClick={() => { setNoOfLevel(`${level}`); setActiveClass(index) }}>
                                            <label htmlFor={`level-${level}`}>Level</label>
                                            <span>{level}</span>
                                        </div>
                                    </li>
                                </>
                            ))}
                        </Slider>
                    </ul>
                </div>
                <div className="col-md-1 offset-md-1">
                    <ul className="tree-level-list">
                        <li>
                            <div className="level-content total-level MB-3" onClick={() => setNoOfLevel('')}>
                                <label htmlFor="">All</label><span>Level</span>
                                <p>{UserInfo.maxLevel}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            {
                loading ?
                    <div className="text-center my-5">
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                    :
                    <>
                        <div className="network-list">
                            <ul>
                                {renderMembers()}
                            </ul>
                        </div>
                        <div className="pagination-wrap">
                            {totalRecords > 10 && (
                                <BsPagination
                                    length={members.length}
                                    pageNo={pageNo}
                                    pageSize={pageSize}
                                    totalRecords={totalRecords}
                                    onPageChange={handlePageChange}
                                    onPageSizeChange={(newPageSize) => {
                                        setPageSize(newPageSize);
                                        setPageNo(1)
                                    }}
                                />
                            )}
                        </div>
                    </>
            }

        </>
    );
}

export default Network;