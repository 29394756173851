import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Dashboard/General/Util";
import FranchiseLoginCheck from "./FranchiseLoginCheck";


function ReceivedBalance(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;


  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  //   console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let navigate = useNavigate();

  const [ledgerOld, setLedgerOld] = useState([]);
  const [Records, setRecords] = useState([])

  const [showFilters, setShowFilters] = useState(true);

  const [Type, setType] = useState("Cash In");
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [GrandTotal, setGrandTotal] = useState('');

  useEffect(() => {
    document.title = "MAG Franchise";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      startDate: StartDate,
      endDate: EndDate,
      BalanceType: Type,
    };

    // console.log("data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetBalanceInDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofLedger);
          setLoading(false);
          setGrandTotal(response.data);
          setTotalRecords(response.data.totalRecords);
          //console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, StartDate, EndDate, Type]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };





  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>{moment(item.Transaction_Date).format("DD/MM/YYYY HH:mm")}</td>
            <td>{item.Reference_No}</td>
            <td>{item.Reference_Type}</td>
            <td>{item.Details}</td>
            <td>PKR {NumberFormat(item.Credit)}</td>
          </tr >
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="16">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };



  return (
    <>
      <FranchiseLoginCheck />

      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Received Balance
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/franchise/dashboard">
                 <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="row">
                <div className="col-md-3">
                  <div className="form_default">
                    <div className="form-group search_box">
                      <label htmlFor="">Type</label>
                      <div className="inputwrap">
                        <input type="text" placeholder="Balance In" disabled readOnly />
                        {/* <select
                          className="form-select"
                          value={Type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="Cash In" selected>Balance In</option>
                          <option value="Package Purchased">Balance Transfer</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form_default">
                    <div className="form-group search_box">
                      <label htmlFor="">From</label>
                      <div className="inputwrap">
                        <input
                          type="date"
                          className="form-control"
                          value={StartDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form_default">
                    <div className="form-group search_box">
                      <label htmlFor="">To</label>
                      <div className="inputwrap">
                        <input
                          type="date"
                          className="form-control"
                          value={EndDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th>Reference No</th>
                  <th>Reference Type</th>
                  <th>Details</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="16">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                    {renderLedger()}
                    {
                      listOfLedger != null && listOfLedger.length > 0 &&
                      <>
                        <tr style={{ borderButtom: "none !important" }}>
                          <th colSpan={4} className="border-bottom-0 pt-3"></th>
                          <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Credit'))}</th>

                        </tr>
                        <tr className="">
                          <th className="pt-3" colSpan={4}></th>
                          <th className="text-center">Grand Total:</th>
                          <th>PKR {NumberFormat(GrandTotal.grandTotal)}</th>
                        </tr>
                      </>
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {
          totalRecords > 10 && (
            <div className="pagination-wrap">
              <div className="row align-items-center">
                <div className="col-md-2 form_default">
                  <div className="d-flex align-items-center">
                    <label className="me-2" style={{ fontSize: "15px" }}>
                      Page size
                    </label>
                    <div className="form-group">
                      <select
                        className="form-select"
                        style={{ width: "100px" }}
                        onChange={(e) => {
                          setPageNo(1);
                          setPageSize(parseInt(e.target.value));
                        }}
                      >
                        <option value="10" selected>
                          10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                  </div>
                </div>

                <Pagination
                  pageNo={pageNo}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          )
        }
      </div >
    </>
  );
}

export default ReceivedBalance;
