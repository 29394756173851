import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminRightsCheck from "../AdminRightsCheck";
import { Editor } from "@tinymce/tinymce-react";

const AddPackage = () => {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );

  const [showFilters, setShowFilters] = useState(true);
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ShowPackage, setShowPackage] = useState(true);
  const [listOfRecord, setListOfRecord] = useState([]);

  const [Name, setName] = useState('');
  const [Amount, setAmount] = useState('');
  const [ROI, setROI] = useState('');
  const [PackageType, setPackageType] = useState('');
  const [NoofDays, setNoofDays] = useState('');
  const [TagLine, setTagLine] = useState('');
  const [DirectBonus, setDirectBonus] = useState('');
  const [DailyProfitReturn, setDailyProfitReturn] = useState('');
  const [MonthlyProfit, setMonthlyProfit] = useState('');
  const [TotalProfitReturn, setTotalProfitReturn] = useState('');
  const [ProductDelivery, setProductDelivery] = useState('');
  const [SortOrder, setSortOrder] = useState('0');
  const [GetPackage, setGetPackage] = useState('');

  const [Details, setDetails] = useState('');

  // loadings
  const [LoadingPackage, setLoadingPackage] = useState(false);


  let navigate = useNavigate();
  let params = useParams();


  const DetailRef = useRef(null);
  const [Detail, setDetail] = useState("");
  const handleDetailChange = (content) => {
    setDetail(content);
  };

  const AddPackage = () => {
    document.title = "Total Funds Transfer";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Name: Name,
      Amount: Amount,
      ROI: ROI,
      PackageType: PackageType,
      NoofDays: NoofDays,
      TagLine: TagLine,
      DirectBonus: DirectBonus,
      DailyProfitReturn: DailyProfitReturn,
      MonthlyProfit: MonthlyProfit,
      TotalProfitReturn: TotalProfitReturn,
      ProductDelivery: ProductDelivery,
      Details: Detail,
      SortOrder: SortOrder,
      ShowPackage:ShowPackage,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Package/AddPackage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(data);

    axios(api_config)
      .then(function (response) {
        // console.log("data", response.data);
        if (response.data.status_code === 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setListOfRecord(response.data.ListofRecords);
          setLoading(false);
          setName('');
          setAmount('');
          setROI('');
          setPackageType('');
          setNoofDays('');
          setTagLine('');
          setDirectBonus('');
          setDailyProfitReturn('');
          setMonthlyProfit('');
          setTotalProfitReturn('');
          setProductDelivery('');
          setDetails('');
        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      })
      .catch(function (error) {
        // console.log(error);
      });
  };


  useEffect(() => {

    setLoadingPackage(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Member_Type: MemberType,
      ID: params.id,
    };
    // console.log(data);
    var api_config = {
      method: 'post',
      url: config.base_url + 'Package/GetPackagebyID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data)
        if (response.data.status_code == 1) {
          setGetPackage(response.data.Package);
          setDetails(response.data.Package.Details);
          setLoadingPackage(false);
        }

        else {
          setLoadingPackage(false);
        }
      })
      .catch(function (error) {
      });
  }, []);

  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Add Packages
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to={`/admin/package/manage-package`}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                Manage Package
                <i className="ri-menu-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>

        <div>
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-4 gy-3">
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Name</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Amount</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">ROI</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ROI}
                            onChange={(e) => setROI(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Package Type</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={PackageType}
                            onChange={(e) => setPackageType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">No of Days</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={NoofDays}
                            onChange={(e) => setNoofDays(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Tag Line</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={TagLine}
                            onChange={(e) => setTagLine(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Direct Bonus</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={DirectBonus}
                            onChange={(e) => setDirectBonus(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Daily Profit Return</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={DailyProfitReturn}
                            onChange={(e) => setDailyProfitReturn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Monthly Profit</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={MonthlyProfit}
                            onChange={(e) => setMonthlyProfit(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Total Profit Return</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={TotalProfitReturn}
                            onChange={(e) => setTotalProfitReturn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Product Delivery</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ProductDelivery}
                            onChange={(e) => setProductDelivery(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Sort Order</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={SortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="form-check mt-3">
                        <input className="form-check-input" type="checkbox" defaultChecked={ShowPackage} onChange={(e) => setShowPackage(!ShowPackage)} value={ShowPackage} id="ShowPackage" />
                        <label className="form-check-label" for="ShowPackage">
                          Show Package
                        </label>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    {
                      loading ?
                        <>
                          <Spinner animation="border" role="status"></Spinner>
                        </>
                        :
                        <button
                          className="button w-100 button-primary"
                          onClick={AddPackage}
                        >
                          Add Package
                        </button>
                    }

                  </div>
                  <div className="col-md-12">
                    <Editor
                      apiKey="w81u1otpwt8qqd0odorwvyaol3vsrjipuhdua1mr6kjqnebw"
                      init={{
                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      }}
                      onInit={(editor) => DetailRef.current = editor}
                      onEditorChange={handleDetailChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPackage