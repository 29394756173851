import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom'

import config from "../../../Config";
import axios from "axios";
import { CheckBadgeIcon, EnvelopeOpenIcon, TicketIcon, ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { InboxIcon } from '@heroicons/react/24/solid';



function SupportMenu(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState({});

    const [key, setKey] = useState('home');

    useEffect(() => {


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            NoofRecords: 10,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetSupportTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data);
                }


            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);



    return (
        <>

            <ul className="nav nav-tabs nav-support" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                        <TicketIcon />
                        <p>Create Ticket</p>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                        <InboxIcon />
                        <p>Inbox</p>
                        <span className={`${tickets.AwaitingReply === 0 ? 'd-none' : ''}`}>{tickets.AwaitingReply}</span>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 3.75H6C5.40326 3.75 4.83097 3.98705 4.40901 4.40901C3.98705 4.83097 3.75 5.40326 3.75 6V7.5M16.5 3.75H18C18.5967 3.75 19.169 3.98705 19.591 4.40901C20.0129 4.83097 20.25 5.40326 20.25 6V7.5M20.25 16.5V18C20.25 18.5967 20.0129 19.169 19.591 19.591C19.169 20.0129 18.5967 20.25 18 20.25H16.5M7.5 20.25H6C5.40326 20.25 4.83097 20.0129 4.40901 19.591C3.98705 19.169 3.75 18.5967 3.75 18V16.5M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p>Closed</p>
                        <span className={`${tickets.CloseCount === 0 ? 'd-none' : ''}`}>{tickets.CloseCount}</span>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">

                        <ViewfinderCircleIcon />
                        <p>Open</p>
                        <span className={`${tickets.OpenTickets === 0 ? 'd-none' : ''}`}>{tickets.OpenTickets}</span>
                    </button>
                </li>
            </ul>



            <div className='support-cyan d-none'>
                <ul className='white-cyan'>
                    <li className='support-item'>
                        <NavLink to="/dashboard/support" className="support-link">
                            <TicketIcon />
                            <p>Raise Ticket</p>
                        </NavLink>
                    </li>
                    <li className='support-item'>
                        <NavLink to="/dashboard/Inbox" className="support-link">
                            <InboxIcon />
                            <p>Inbox</p>
                            <span>{tickets.AwaitingReply}</span>
                        </NavLink>
                    </li>
                    <li className='support-item'>
                        <NavLink to="/dashboard/support/TicketsClose" className="support-link">
                            <CheckBadgeIcon />
                            <p>Closed</p>
                            <span>{tickets.CloseCount}</span>
                        </NavLink>
                    </li>
                    <li className='support-item'>
                        <NavLink to="/dashboard/support/Open" className="support-link">
                            <EnvelopeOpenIcon />
                            <p>Open</p>
                            <span>{tickets.OpenTickets}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>


            {/* <NavLink to="/dashboard/support" className="button button-primary button-icon w-100 mb-3">
                NEW TICKET<i className="bx bxs-chevrons-right"></i>
            </NavLink>

            <div className="card">
                <div className="card-body">
                    <ul className="support_left_menu">
                        <li>
                            <a href="#">
                                <i className="material-symbols-outlined">inbox</i>
                                <span>Inbox</span>

                            </a>
                        </li>
                        <li>
                            <NavLink to="/dashboard/support/Open">
                                <i className="material-symbols-outlined">mail</i>
                                <span>Open</span>
                                <span className="count">{tickets.OpenTickets}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard/support/awaiting">

                                <i className="material-symbols-outlined">schedule</i>
                                <span>Awaiting Reply</span>
                                <span className="count waiting">{tickets.AwaitingReply}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard/support/Closed">
                                <i className="material-symbols-outlined">check_circle</i>
                                <span>Closed</span>
                                <span className="count">{tickets.CloseCount}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div> */}
        </>
    );
}

export default SupportMenu;