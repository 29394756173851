import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import ImgDone from "../../../assets/images/done.svg";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ProfileMenu from "./ProfileMenu";
import { Form } from 'react-bootstrap';
import Config from "../../../Config";
import { EnvelopeIcon, IdentificationIcon, MapPinIcon, PencilIcon, PhoneIcon, TrashIcon, UserCircleIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import Codes from "../BusinessKyc/Codes";
import LoginCheck from "../General/LoginCheck";
function Account(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [MemberPhoto, setMemberPhoto] = useState(localStorage.getItem("Img_File"));

    const [profile, setProfile] = useState({});

    const [NewName, setNewName] = useState("");
    const [newNameLoading, setNewNameLoading] = useState(false);

    const [NewAddress, setNewAddress] = useState("");
    const [newAddressLoading, setNewAddressLoading] = useState(false);

    const [NewPhoneNoLoading, setNewPhoneNoLoading] = useState(false);


    const [triggerUpdate, settriggerUpdate] = useState(false);

    const [MobileNo, setMobileNo] = useState("")
    const [CountyCode, setCountyCode] = useState("44")

    const handleMobileNo = (e) => {
        const value = e.target.value;
        const formattedValue = value.startsWith('0') ? value.substring(1) : value;
        const slicedValue = formattedValue.substring(0, 13);
        setMobileNo(slicedValue);
    };


    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }

    const handleCodeChange = (e) => {
        setCountyCode(e.target.value);
        // console.log(e.target.value);
    }



    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setLoading(true);
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);


            var cleanerBase64 = cropData1.substring(22);


            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Img_File: cleanerBase64
            };



            var api_config = {
                method: 'post',
                url: Config.base_url + 'Members/UpdatePhoto',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {


                    if (response.data.status_code == 1) {
                        setIsUpdated(true);
                        setImageSelected(false);
                        localStorage.removeItem("Img_File");
                        localStorage.setItem("Img_File", UserID + ".JPG");
                        window.location.reload();
                    }
                    else {
                        setIsUpdated(false);
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                    // console.log(error);
                });

        }
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <button className="button button button-outline-bs" onClick={handleCloseModal}>Close</button>
                    <button className="button button-bs" disabled={loading} onClick={getCropData}>Save Photo {loading ? '...' : ''}</button>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <button className="button button button-outline-bs" onClick={handleCloseModal}>Close</button>
                </>
            )
        }
    }


    useEffect(() => {

        settriggerUpdate(false);

        const dashboardParms = {
            AccessKey: AccessKey,
            ID: UserID,
            Email_Address: Email

        };


        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetMemberProfile',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setProfile(response.data);
            })
            .catch(function (error) {
                // console.log(error);
            });




    }, [triggerUpdate]);




    const UpdateName = () => {


        if (NewName == "") {
            toast.error('Please enter name', {
                position: toast.POSITION.TOP_RIGHT
            });

            return false;
        }

        setNewNameLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Full_Name: NewName

        };

        // console.log(data);


        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/ChangeFullName',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    settriggerUpdate(true);
                    setNewName('');
                    setNewNameLoading(false);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });

    }
    const UpdatePhone = () => {


        if (MobileNo == "") {
            toast.error('Please enter Phone No', {
                position: toast.POSITION.TOP_RIGHT
            });

            return false;
        }

        setNewPhoneNoLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MobileNo: '+' + CountyCode + MobileNo,
        };

        // console.log(data);


        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/ChangeMobileNo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    settriggerUpdate(true);
                    setMobileNo('');
                    setNewPhoneNoLoading(false);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });

    }


    const updateAddress = () => {


        if (NewAddress == "") {
            toast.error('Please enter address', {
                position: toast.POSITION.TOP_RIGHT
            });

            return false;
        }

        setNewAddressLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Postal_Address: NewAddress

        };

        // console.log(data);


        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/ChangePostalAddress',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    settriggerUpdate(true);
                    setNewAddress('');
                    setNewAddressLoading(false);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });

    }




    return (
        <>
            <ToastContainer />
            <LoginCheck />
            <Modal show={showModal} onHide={handleCloseModal} className="upload-img-model">
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-img model-pink">
                        <div className="upload-box">
                            <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                            {
                                isUpdated ?
                                    <>
                                        <div className="text-center">
                                            <img src={ImgDone} style={{ maxWidth: "300px" }} />
                                            <p>Photo Updated Successfully</p>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="upload-photo-wrap">
                                            {
                                                imageSelected ?
                                                    <>
                                                        <Cropper
                                                            zoomTo={0.5}
                                                            aspectRatio={8 / 8}
                                                            preview=".img-preview"
                                                            src={image}
                                                            dragMode="move"
                                                            viewMode={2}
                                                            minCropBoxHeight={10}
                                                            minCropBoxWidth={10}
                                                            background={false}
                                                            responsive={true}
                                                            autoCropArea={1}
                                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                            onInitialized={(instance) => {
                                                                setCropper(instance);
                                                            }}
                                                            guides={true}
                                                        />
                                                        <div className="delete" onClick={() => setImageSelected(false)}>
                                                            <TrashIcon />
                                                        </div>
                                                    </>
                                                    :
                                                    <label htmlFor="ChoosePhoto" className="thumnail">
                                                        <ViewfinderCircleIcon />
                                                        <h6>Browse Photo</h6>
                                                    </label>
                                            }
                                        </div>
                                    </>

                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        {renderButtons()}
                    </div>
                </Modal.Body>
            </Modal>

            <ProfileMenu />
            <div className="profile-bg-card">
                <div className="row gy-3 pt-3">
                    <div className="col-md-3 text-center">
                        <div className="profile_header">
                            <div className="profile_photo" onClick={handleShowModal}>
                                <img src={Config.img_path + MemberPhoto} />
                                <div className="profile_edit">
                                    <PencilIcon />
                                </div>
                            </div>
                            <span>Edit Photo</span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <ul className="profile-info-list">
                            <li>
                                <div className="icon-form-group">
                                    <label htmlFor="">Enter New Name</label>
                                    <div className="icon-input">
                                        <input className="" placeholder={profile.Full_Name} value={NewName} onChange={(e) => setNewName(e.target.value)} />
                                        <div className="icon">
                                            <UserCircleIcon />
                                        </div>
                                    </div>
                                </div>
                                <button disabled={newNameLoading} className="button button-outline-bs input-height" onClick={UpdateName}>
                                    <span>Save {newNameLoading ? '...' : ''}</span>
                                </button>
                            </li>
                            <li>
                                <div className="profile-info">
                                    <div className="icon">
                                        <IdentificationIcon />
                                    </div>
                                    <div className="content">
                                        <h4>Username</h4>
                                        <span>{profile.User_Name}</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="profile-info">
                                    <div className="icon">
                                        <EnvelopeIcon />
                                    </div>
                                    <div className="content">
                                        <h4>Email</h4>
                                        <span>{profile.Email_Address}</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="icon-form-group">
                                    <label htmlFor="">Phone</label>
                                    <div className="d-flex">
                                        <div className="icon-input country-input">
                                            <Codes
                                                handleCodeChange={handleCodeChange} />
                                        </div>
                                        <div className="icon-input">
                                            <input type="number"
                                                placeholder={profile.Mobile_No != null ? `${profile.Mobile_No}` : 'xxx xxx xxxx'}
                                                className=""
                                                onChange={handleMobileNo}
                                                value={MobileNo}
                                                required
                                                maxLength={"13"}
                                            />
                                            <div className="icon">
                                                <PhoneIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button disabled={NewPhoneNoLoading} className="button button-outline-bs input-height" onClick={UpdatePhone}>
                                    <span>Save {NewPhoneNoLoading ? '...' : ''}</span>
                                </button>
                            </li>
                            <li>
                                <div className="icon-form-group">
                                    <label htmlFor="">Address</label>
                                    <div className="icon-input">
                                        <input className=""
                                            value={NewAddress} onChange={(e) => setNewAddress(e.target.value)}
                                            placeholder={profile && profile.Postal_Address &&
                                                profile.Postal_Address
                                            }
                                        />
                                        <div className="icon">
                                            <MapPinIcon />
                                        </div>
                                    </div>
                                </div>
                                <button disabled={newAddressLoading} className="button button-outline-bs input-height" onClick={updateAddress}>
                                    <span>Save {newAddressLoading ? '...' : ''}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Account;