import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../Config';
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet, Svg, Path, Polygon, } from '@react-pdf/renderer';
import moment from 'moment';
import stamp from '../../../assets/images/partner/mag_stemp.png';
import Logo from '../../../assets/images/partner/mag_logo.png';
import QRCode from 'qrcode.react'; // Import QRCode from 'qrcode.react' instead of 'react-qr-code'
import Config from '../../../Config';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';

function WithdrawInvoicepdf(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const ID = urlParams.get('id');
    const [withdrawByID, setwithdrawByID] = useState([]);


    const HostUrl = window.location.origin + `/partner/transactions/withdraw/pdf?id=${ID}`;


    useEffect(() => {

        document.title = "Mag Holdings Admin";

        setLoading(true);
        // console.log(ID);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Payment_ID: ID,
        }

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetWithdrawlByTransactionID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log('data', data)

        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    setwithdrawByID(response.data);
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row'
        },
        invoice_page: {
            paddingVertical: 30,
        },
        subpage: {
            marginHorizontal: 20,
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.25)',
            width: 360,
            height: 500,
            position: 'relative',
            borderRadius: 5,
        },
        invoicetop: {
            marginVertical: 20,
        },
        Logo: {
            width: 93,
            height: 42,
            marginBottom: 10,
            marginLeft: 20,
        },
        title: {
            fontSize: 10,
            fontWeight: '900',
            letterSpacing: 2,
            textTransform: 'uppercase',
            backgroundColor: '#EEE',
            paddingVertical: 3,
            paddingHorizontal: 20,
            color: '#000',
            marginBottom: 10,
        },
        top_span: {
            color: '#000',
            fontSize: 11,
            marginLeft: 20,
        },
        qr_code_wrap: {
            position: 'absolute',
            top: 10,
            right: 80,
            marginRight: 15,
        },
        invoice_content: {
            margin: 20,
            marginTop: 5,
            borderRadius: 10,
            border: .5,
            borderWidth: 1,
            borderColor: '#bdbaba',
        },
        ul: {

        },
        li: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            paddingVertical: 6,
            borderBottomWidth: .5,
            borderBottomColor: '#bdbaba',
        },
        label: {
            width: '50%',
            fontSize: 11,
            fontWeight: '500',
            color: '#FF1500',
        },
        span: {
            width: '50%',
            fontSize: 11,
            fontWeight: '400',
            color: '#000',
            textAlign: 'left',
        },
        li_last: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: -57,
            paddingTop: 2,
        },
        li_first: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            paddingVertical: 6,
            borderBottomWidth: .5,
            borderBottomColor: '#bdbaba',
            paddingTop: 20,
        },
        stamp: {
            width: 130,
            rotate: '-30deg',
        },
    });




    const PdfWithQrCode = () => {
        const qrCodeComponent = (
            <QRCode
                value={HostUrl}
                renderAs="svg"
                size={86}
            />
        );

        const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent);

        const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup);
        if (!parsedQrCodeSvg) {
            return null;
        }

        return (

            <Svg
                style={{ width: 114, height: 114, position: 'absolute' }}
                viewBox="0 0 50 50"
            >
                {parsedQrCodeSvg.props.children.filter(c => c.type === 'path').map((child, index) => (
                    <Path
                        key={index}
                        d={child.props.d}
                        fill={child.props.fill}
                    />
                ))}
            </Svg>

        );
    }

    const parseQrCodeMarkup = (markup) => {
        let parsedQrCodeSvg = null;

        ReactHtmlParser(markup).forEach(el => {
            const { type } = el;
            if (type === 'svg') {
                parsedQrCodeSvg = el;
            }
        });

        return parsedQrCodeSvg;
    };



    const MyDocument = () => (
        <Document>
            <Page style={styles.page}>
                <View style={styles.invoice_page}>
                    <View style={styles.subpage}>
                        <View style={styles.invoicetop}>
                            <Image style={styles.Logo} src={Logo} />
                            <View><Text style={styles.title}>Withdraw Invoice</Text></View>
                            <Text style={styles.top_span}>{moment(withdrawByID.Dated).format('DD/MM/YYYY - HH:mma')}</Text>
                            <View style={styles.qr_code_wrap}>
                                <PdfWithQrCode />
                            </View>
                        </View>
                        <View style={styles.invoice_content}>
                            <View style={styles.ul}>
                                <View style={styles.li_first}>
                                    <Text style={styles.label}>Beneficiary</Text>
                                    <Text style={styles.span}>{withdrawByID.To}</Text>
                                </View>

                                <View style={styles.li}>
                                    <Text style={styles.label}>Wallet Type</Text>
                                    <Text style={styles.span}>MAG Wallet</Text>
                                </View>
                                <View style={styles.li}>
                                    <Text style={styles.label}>Payment Mode</Text>
                                    <Text style={styles.span}>{withdrawByID.PaymentMode}</Text>
                                </View>
                                <View style={styles.li}>
                                    <Text style={styles.label}>Address / Account No</Text>
                                    <Text style={styles.span}>
                                        {withdrawByID.WalletAddress && withdrawByID.WalletAddress.length > 15
                                            ? "****" + withdrawByID.WalletAddress.substring(withdrawByID.WalletAddress.length - 15)
                                            : withdrawByID.WalletAddress}
                                    </Text>
                                </View>
                                <View style={styles.li}>
                                    <Text style={styles.label}>TID</Text>
                                    <Text style={styles.span}>{withdrawByID.PaymentID}</Text>
                                </View>

                                <View style={styles.li}>
                                    <Text style={styles.label}>Amount</Text>
                                    <Text style={styles.span}>{withdrawByID.Amount} PKR</Text>
                                </View>
                                <View style={styles.li}>
                                    <Text style={styles.label}>Fee</Text>
                                    <Text style={styles.span}>{withdrawByID.Fee} PKR</Text>
                                </View>
                                <View style={styles.li}>
                                    <Text style={styles.label}>Exchange Rate</Text>
                                    <Text style={styles.span}>{withdrawByID.ExchangeRate} {withdrawByID.ReceiveableCurrency}</Text>
                                </View>
                                <View style={styles.li}>
                                    <Text style={styles.label}>Receivable Amount</Text>
                                    <Text style={styles.span}>{withdrawByID.ReceiveableAmount} {withdrawByID.ReceiveableCurrency}</Text>
                                </View>
                                <View style={styles.li_last}>
                                    <Image style={styles.stamp} src={stamp} />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );



    return (
        <>
            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                {
                    loading ?
                        "Loading..."
                        :
                        <PDFViewer width="100%" height="100%" fileName="sample.pdf">
                            <MyDocument />
                        </PDFViewer>
                }

            </div>
        </>
    );
}

export default WithdrawInvoicepdf