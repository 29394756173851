import React, { useEffect, useState, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { ArrowLongLeftIcon, ArrowLongRightIcon, DocumentDuplicateIcon, FaceFrownIcon } from "@heroicons/react/24/outline";
import sendEmail from "../Common/SendEmail";
import { NumberFormat } from "../business/General/Functions";
import Config from "../../Config";
import FranchiseLoginCheck from "./FranchiseLoginCheck";

function FranchiseWithdraw(props) {


    let navigate = useNavigate();

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [amount, setAmount] = useState("");
    const [OTP, setOTP] = useState("");
    const [loading, setLoading] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [CurrentBalance, setCurrentBalance] = useState(0);
    const [wallet, setWallet] = useState('Cash');
    const [finalAmount, setFinalAmount] = useState(0);
    const [pamount, setpAmount] = useState(0);
    const [validated, setValidated] = useState(false);
    const [noWallet, setnoWallet] = useState(false);
    const WalletIDRef = useRef(null);
    const [dData, setDashboardData] = useState({});



    const [timer, setTimer] = useState(60);


    const [AuthCode, setAuthCode] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [Amounterror, setAmounterror] = useState(false);
    const [iswithdrawdisable, setiswithdrawdisable] = useState(false);


    useEffect(() => {


        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        var api_config = {
            method: "post",
            url: Config.base_url + "MemberWallet/GetWallets",
            headers: {
                "Content-Type": "application/json",
            },
            data: dashboardParms,
        };

        axios(api_config)
            .then(function (response) {
                // console.log("dashboardParms", response.data);
                if (response.data.status_code == 1) {
                    setWallets(response.data.ListofWallets);
                } else if (response.data.status_message == "Invalid Access Key.") {
                    localStorage.clear();
                    window.location.href = "/franchise/login";
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                    //onClose: () => window.alert('Called when I close')
                });
            });
    }, []);

    useEffect(() => {

        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log('setDashboardData', response.data);
                setDashboardData(response.data);
                setRemainingBalance(response.data.Member.Current_Balance);
                setCurrentBalance(response.data.Member.Current_Balance);
            })
            .catch(function (error) {
                // console.log(error);
            });


    }, []);

    const handleSubmitWithdraw = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();



        } else {
            if (OTP.length > 0) {

                setLoading(true);
                let CurrencyRate = 0;
                if (wallet == "Cash") {
                    CurrencyRate = 'Cash';
                }

                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    PinCode: OTP,
                    Amount: event.target.amount.value,
                    WalletID: WalletIDRef.current.value,
                    PaymentMode: wallet,
                    SelectedCurrencyRate: CurrencyRate,
                    SelectedCurrency: wallet,
                    AuthorizationCode: AuthCode,
                };


                // console.log(data)

                var api_config = {
                    method: "post",
                    url: Config.base_url + "Members/AddWithdrawlRequest",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };


                const day = moment().format('dddd');
                if (day === "Sunday") {
                    setiswithdrawdisable(true);
                    setLoading(false);
                }
                else {
                    axios(api_config)
                        .then(function (response) {
                            // console.log(response.data)
                            if (response.data.status_code == 1) {
                                toast.success(response.data.status_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                window.location.reload();
                            } else {
                                toast.error(response.data.status_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                            setLoading(false);
                        })
                        .catch(function (error) {
                            setLoading(false);
                            toast.error("Network Error..", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        });
                }

            } else {
                toast.error("Please enter Transaction Pin", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }

        setValidated(true);
    };

    const CalcPercentage = (e) => {
        let amount = e.target.value;

        if (amount.length < 8) {
            // console.log(setpAmount);
            setAmount(amount);
            if (amount > dData.Member?.Current_Balance) {
                toast.error('Amount should be less than balance', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setAmount("");
                return false;
            }
            if (amount.length == 0 || amount == 0 || amount == "") {
                //setRemainingBalance(props.data.Current_Balance);
                setFinalAmount(0);
                setpAmount(0);
                setRemainingBalance(dData?.Member.Current_Balance);
            } else {

                //shafat

                if (amount < 10000) {
                    setAmounterror(true);

                }
                else {
                    setAmounterror(false)
                }

                setRemainingBalance(CurrentBalance - amount);


                // fahad
                if (wallet == 'Cash') {
                    let final1 = amount;
                    let final = amount - final1;
                    setFinalAmount(final);
                    setpAmount(final1);
                }
            }
        }
    };

    const handleSendCode = () => {
        const props = {
            TransactionType: "WithdrawCash",
        };

        sendEmail(props);

        setIsButtonDisabled(true);

        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        setTimer(60);
    };

    const renderFeeBox = () => {
        if (wallet == "Cash") {
            return (
                <>
                    <div className="icon-input">
                        <input type="text" placeholder="Receivable Amount"
                            className="disabled"
                            readOnly={true} />
                        <div className="icon">
                            <b>{pamount > 0 ? pamount : ""}</b>
                        </div>
                    </div>
                </>
            );
        }
    };

    const renderSelectedWallet = () => {
        if (wallet == "Cash") {
            // console.log(wallet)
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Cash" &&
                    item.Network == "PKR" &&
                    item.IsDefault == true

            );
            if (crypto.length > 0) {
                let item = crypto[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-form-group">
                            <label htmlFor="">Address</label>
                            <div className="icon-input">
                                <input type="text" placeholder={item.Address}
                                    className="disabled"
                                    readOnly={true} />
                                <div className="icon">
                                    <DocumentDuplicateIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${item.Address}`);
                                            toast.success('Address Copied', {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isButtonDisabled]);




    return (
        <>
            <ReactTooltip />
            <ToastContainer />
            <FranchiseLoginCheck />
            <div className="admin-content">

                <div className="row align-items-center mb-3">
                    <div className="col-md-6">
                        <div className="section-title">Withdraw</div>
                    </div>
                </div>
                <div className="dash-bg-card mb-3">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 wallet-wrap">
                            <h5 className="text-center section-title mb-4">Withdrawal MODE IS CASH</h5>

                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmitWithdraw}
                            >
                                <div className="available-balance">
                                    <label htmlFor="">Available Balance </label>
                                    <span>PKR {NumberFormat(remainingBalance)}</span>
                                </div>
                                <div className="wallet-body pt-0">
                                    <div className="icon-input">
                                        <input
                                            placeholder="Amount in PKR"
                                            type="number"
                                            className={dData.Member?.Current_Balance > 0 ? '' : 'disable'}
                                            value={amount}
                                            maxLength="7"
                                            name="amount"
                                            disabled={
                                                dData.Member?.Current_Balance > 0 ? false : true
                                            }
                                            onChange={(e) => CalcPercentage(e)}
                                            required
                                        />
                                        <div className="icon">
                                            <b>{finalAmount > 0 ? finalAmount : ""}</b>
                                        </div>
                                    </div>

                                    <ul className="add-condition">
                                        {Amounterror && <li> <p className="Resend m-0"> Minimum amount: PKR 10000</p></li>}
                                    </ul>

                                    {renderSelectedWallet()}
                                    {renderFeeBox()}
                                    <div className="code pb-2 pt-3 mb-3">
                                        <div className="row align-items-top">
                                            <div className="col-md-9">
                                                <div className="icon-form-group">
                                                    <div className="icon-input">
                                                        <input type="text"
                                                            value={AuthCode}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (/^\d{0,6}$/.test(value)) {
                                                                    setAuthCode(value);
                                                                }
                                                            }}
                                                            placeholder='Email Verification Code' name="AuthCode" autoComplete="disable" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <button
                                                    className="button button-outline-bs input-height w-100 text-nowrap"
                                                    style={{ borderRadius: '12px' }}
                                                    onClick={handleSendCode}
                                                    disabled={isButtonDisabled}
                                                >
                                                    GET CODE
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-none">
                                        <div className="form-group mb-3">
                                            <label>2FA </label>
                                            <div className="inputwrap">
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="icon-form-group">
                                        <label htmlFor="">Transaction Pin</label>
                                        <div className="icon-input">
                                            <input type="number"
                                                placeholder='XXXXXX' value={OTP}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 6) {
                                                        setOTP(e.target.value);
                                                    }
                                                }}
                                                maxLength={6} autoComplete="disable" required className="text-center" />
                                        </div>
                                    </div>
                                </div>
                                <div className="wallet-footer">
                                    <Link to={`/partner/dashboard`} className="button button-outline-bs button-icon-bs justify-content-center w-100" ><ArrowLongLeftIcon /><span>BACK</span></Link>
                                    <button className={`button button-bs button-icon-bs justify-content-center w-100`}
                                        type="submit"
                                        disabled={amount == 0 || !AuthCode || OTP.length == 0 || !noWallet || dData.Member?.Current_Balance == 0 || Amounterror == true || loading
                                        }
                                    >
                                        <span>Withdraw {loading ? "..." : ""}</span><ArrowLongRightIcon />
                                    </button>
                                </div>
                                <p className="text-center mt-3" style={{ fontSize: "14px", color: "red" }}>The Withdrawal is paid in working days (Monday to Saturday).</p>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FranchiseWithdraw