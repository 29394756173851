import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import Spinner from "react-bootstrap/Spinner";



import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { NumberFormat } from "../../Dashboard/General/Functions";
import AdminRightsCheck from "../AdminRightsCheck";

function DepositDashboard() {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [depositStatics, setDepositStatics] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Mag Holdings Admin";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetDepositDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);

        if (response.data.status_code === 1) {
          setDepositStatics(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="DEPOSIT PANEL" />
          {depositStatics && (
            <div className="admin-content">
              <div className="row">
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/total-deposit"
                    class="blue"
                    value={"PKR " + NumberFormat(depositStatics.totalDeposit)}
                    label="TOTAL DEPOSIT"
                    icon="vertical_align_bottom"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/crypto-deposit"
                    class="green"
                    value={"PKR " + NumberFormat(depositStatics.cryptoDeposit)}
                    label="CRYPTO DEPOSIT"
                    icon="currency_bitcoin"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/coin-payment"
                    class="red"
                    value={"PKR " + NumberFormat(depositStatics.coinPaymentDeposit)}
                    label="COINPAYMENTS"
                    icon="send_money"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/now-payment"
                    class="green"
                    value={"PKR " + NumberFormat(depositStatics.nowPaymentsDeposit)}
                    label="NOWPAYMENTS"
                    icon="database"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/bank-deposit"
                    class="blue"
                    value={"PKR " + NumberFormat(depositStatics.bankDeposit)}
                    label="TOTAL BANK DEPOSIT"
                    icon="account_balance"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/pending-deposit"
                    class="green"
                    value={"PKR " + NumberFormat(depositStatics.bankPending)}
                    label="BANK PENDING DEPOSIT"
                    icon="account_balance"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <AdminCard
                    link="/admin/deposit-dashboard/rejected-deposit"
                    class="red"
                    value={"PKR " + NumberFormat(depositStatics.bankRejected)}
                    label="BANK REJECTED DEPOSIT"
                    icon="account_balance"
                    subheading="Members"
                    no="(50)"
                  />
                </div>

              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DepositDashboard;
