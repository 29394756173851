import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault('Europe/London');


const UsaTimeComponentb = () => {
    const [currentTime, setCurrentTime] = useState(moment());
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTime(moment());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const formattedTime = currentTime.format('ddd, DD MMM YYYY HH:mm:ss');
  
    return (
        <p className='time'>
        Server Time: &nbsp;
        <span>{formattedTime}  (GMT)</span>
      </p>
    );
  };
  
  export default UsaTimeComponentb;
  
  