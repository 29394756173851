import React, { useEffect, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Step1 from "./Step1";
import LoginCheck from "../General/LoginCheck";
import Step2 from "./Step2";
import { useNavigate } from "react-router-dom";

function BalanceTransfer(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [Email_Address, setEmail_Address] = useState(localStorage.getItem("Email_Address"));
    const [MemberInfo, setMemberInfo] = useState({});

    const [successData, setSuccessData] = useState({});

    let navigate = useNavigate();

    useEffect(() => {
        const Parms = {
            ID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetMemberInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    // console.log(response.data);
                    setMemberInfo(response.data);
                }


            })
            .catch(function (error) {
                console.log(error);
            });


    }, []);


    const handleSubmitEmail = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            setEmail(event.target.ReceiverID.value);

            // console.log(event.target.ReceiverID.value);
            if (event.target.ReceiverID.value == Email_Address) {
                toast.error('You can not transfer balance to yourself.', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
            }
            else if (event.target.ReceiverID.value == "") {
                toast.error('Please entery Email / Pay ID / Username', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
            }
            else {




                const step1Data = {
                    Email_Address: event.target.ReceiverID.value,
                    AccessKey: AccessKey,
                    UserID: UserID
                };



                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/GetBalanceTransferInfo',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: step1Data
                };


                axios(api_config)
                    .then(function (response) {
                        console.log(response.data);
                        if (response.data.status_code == 1) {
                            setHasError(false);
                            setStep(2);
                            setUser(response.data);


                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                        setLoading(false);


                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }


        }

        setValidated(true);
    }



    const [validated2, setValidated2] = useState(false);
    const [step2Data, setStep2Data] = useState({});
    const [deduction, setDeduction] = useState(0);
    const [amountToTransfer, setAmountToTransfer] = useState(0);

    const handleSubmitAmount = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let amount = event.target.amount.value;
            setAmountToTransfer(amount);

            if (amount > MemberInfo.Current_Balance) {
                toast.error('Insufficient Funds', {
                    position: toast.POSITION.TOP_RIGHT
                });

            }
            else {

                setLoading(true);
                const info2 = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    Email_Address: user.Email_Address,
                    Current_Balance: amount,
                    PINCode: event.target.pincode.value,
                    Member_Type: "balance",
                    Extra2: event.target.notes.value,
                    AuthorizationCode: event.target.AuthCode.value,
                };

                // console.log(info2);


                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/BalanceTransfer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: info2
                };

                axios(api_config)
                    .then(function (response) {
                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setSuccessData(response.data);
                            setLoading(false);
                            navigate('/partner/transactions/funds-history/sent');

                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setLoading(false);
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }

        setValidated2(true);
    }



    const renderStep = () => {
        if (step == 1) {
            return (
                <Step1 validated={validated}
                    hasError={hasError}
                    email={email}
                    errorMessage={errorMessage}
                    handleSubmitEmail={handleSubmitEmail}
                    loading={loading}
                />
            )
        }
        else if (step == 2) {

            return (
                <div className="col-md-6 offset-md-3">
                    <Step2 validated2={validated2}
                        user={user}
                        hasError={hasError}
                        errorMessage={errorMessage}
                        toStep1={() => setStep(1)}
                        step2Data={step2Data}
                        handleSubmitAmount={handleSubmitAmount}
                        Commission={user.Commission_Percentage}
                        balance={MemberInfo.Current_Balance}
                        loading={loading} />
                </div>
            )
        }
    }




    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <div className="bonus_page_head mb-4">
                <h3>Funds Transfer</h3>
                <p>Transfer funds through Email or Username.</p>
            </div>
            <div className="partner-table">
                <div className="verification-wrap">
                    {renderStep()}
                </div>
            </div>
        </>
    );
}

export default BalanceTransfer;