import React, { useState, useEffect } from "react";
import axios from "axios";
import PageTitle from "../PageTitle";
import { useNavigate } from "react-router";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NumberFormat } from "../../Dashboard/General/Functions";
import Config from "../../../Config";
import FranchiseLoginCheck from "../FranchiseLoginCheck";
import FranchiseCard from "../FranchiseCard";

function FranchiseWithdrawalDashboard() {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [withdrawDashboard, setwithdrawDashboard] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "MAG Franchise";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: Config.base_url + "Users/GetWithdrawalDashboardFranchise",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);

        if (response.data.status_code === 1) {
          setwithdrawDashboard(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/franchise/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);


  return (
    <>
      <FranchiseLoginCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="WITHDRAWAL PANEL" />
          {withdrawDashboard && (
            <div className="admin-content">
              <div className="row">
                <div className="col-md-3">
                  <FranchiseCard
                    link="/franchise/withdrawal-dashboard/success"
                    class="green"
                    value={"PKR " + NumberFormat(withdrawDashboard.transferredAmount)}
                    label="total withdrawal paid"
                    icon="payments"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <FranchiseCard
                    link="/franchise/withdrawal-dashboard/initiated"
                    class="green"
                    value={"PKR " + NumberFormat(withdrawDashboard.initiatedAmount)}
                    label="INITIATED"
                    icon="playlist_add_check"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <FranchiseCard
                    link="/franchise/withdrawal-dashboard/approved"
                    class="green"
                    value={"PKR " + NumberFormat(withdrawDashboard.approvedAmount)}
                    label="APPROVED"
                    icon="verified"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <FranchiseCard
                    link="/franchise/withdrawal-dashboard/canceled"
                    class="red"
                    value={"PKR " + NumberFormat(withdrawDashboard.canceledAmount)}
                    label="CANCELED"
                    icon="unpublished"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
                <div className="col-md-3">
                  <FranchiseCard
                    link="/franchise/withdrawal-dashboard/rejected"
                    class="red"
                    value={"PKR " + NumberFormat(withdrawDashboard.rejectedAmount)}
                    label="REJECTED"
                    icon="rule"
                    subheading="Members"
                    no="(50)"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )
      }

    </>
  );
}

export default FranchiseWithdrawalDashboard;
