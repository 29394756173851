import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import config from "../../../Config";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import { TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";


function DocPassport(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
    const [imageSelected, setImageSelected] = useState(false);
    const [validated, setValidated] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);
    const [kyc, setkyc] = useState({});
    let navigate = useNavigate();

    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const [PassportImg, setPassportImg] = useState(''); // State to store PassportImg


    useEffect(() => {
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            KYCStatus: "Not Submitted"
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberKYC/GetKYCByMemberIDStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {

                //console.log(response.data);
                if (response.data.status_code == 1) {

                    setkyc(response.data);


                }


            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT,
                    //onClose: () => window.alert('Called when I close')
                });
            });
    }, []);


    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setImageSelected(false)
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };


    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            var cleanerBase64 = cropData1.substring(22);
            setPassportImg(cleanerBase64);
            props.PassportValue(cleanerBase64);
        }
    };

    useEffect(() => {
        if (imageCropped) {
            getCropData();
            console.log(PassportImg)
        }
    }, [imageCropped]);




    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDrop = (event) => {

        event.preventDefault();
        setImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                if (cropper) {
                    cropper.replace(e.target.result);
                    setImageCropped(true);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className="mb-4">
                <div className="examle">
                    <label htmlFor="">Upload Passport</label>
                    <span>Show Example</span>
                </div>
                {
                    kyc.DocumentType == "Passport" &&
                    <div className="mb-2">
                        <img style={{ height: "100px" }} src={config.img_path + kyc.Passport} />
                    </div>
                }
                <div className="kyc-img-card pink">
                    <div className="card-inner">
                        <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                            id="drop-area"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                            {
                                imageSelected ?
                                    <>
                                        <Cropper
                                            style={{ width: '402px', height: '456px', marginTop: '-10px' }}
                                            zoomTo={0.5}
                                            // aspectRatio={8 / 8}
                                            preview=".img-preview"
                                            src={image}
                                            dragMode="move"
                                            viewMode={3}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                            guides={true}
                                        />
                                        <div className="delete" onClick={() => setImageSelected(false)}>
                                            <TrashIcon />

                                        </div>
                                    </>

                                    :
                                    <>
                                        <label htmlFor="ChoosePassportimage" className='thumnail'>
                                            <ViewfinderCircleIcon />
                                            <p>Upload Passport</p>
                                        </label>

                                    </>

                            }

                        </div>
                    </div>
                </div>
            </div>
            <button onClick={getCropData}
                className="button mt-3 mb-1  button-bs"
            >Upload CNIC</button>

        </>
    );
}

export default DocPassport;