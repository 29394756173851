import React from "react";
import { NavLink } from "react-router-dom";
import { capitalizeFirstLetter } from "../Dashboard/General/Functions";

function AdminCard(props) {

  return (
    <div className="admin-card">
      <NavLink to={props.link}>
        <div className={`card-inner ${props.class}`}>
          <div className="icon">
            <span className="material-symbols-outlined">
              {props.icon}
            </span>
          </div>
          <p>{capitalizeFirstLetter(props.label)}</p>
          <div className="value">
            <h2>{props.value}</h2>
          </div>
          {/* <h6>{props.subheading} <span>{props.no}</span></h6> */}
        </div>
      </NavLink>
    </div>
  );
}

export default AdminCard;

{/* <div className={`${props.progress}`} style={{ height: "4px" }}>
          <div
          Members
            className={`${props.progressbar}`}
            role={`${props.progressrole}`}
            style={{ width: `${props.val}` }}
            aria-valuenow={`${props.arianow}`}
            aria-valuemin={`${props.ariamin}`}
            aria-valuemax={`${props.ariamax}`}
            >
            {props.progressval}
            </div>
          </div> */}