import html2pdf from 'html2pdf.js';

const generatePDF = (htmlContent, filename) => {
  const opt = {
    margin: [0, 0, 0.5, 0],
    filename: `${filename}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    
    html2canvas: {
      dpi: 192,
      scale: 4,
      letterRendering: true,
      useCORS: true,
    },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
  };

  html2pdf().set(opt).from(htmlContent).save();
};

export default generatePDF;
