import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";
import { useNavigate } from "react-router-dom";

function SupportDashboard() {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [Open, setOpen] = useState("");
    const [NewTickets, setNewTickets] = useState("");
    const [Close, setClose] = useState("");
    const [Pending, setPending] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Mag Holdings Admin";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetSupportDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log("data", data)

        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    setLoading(false);
                    setPending(response.data.totalPending);
                    setClose(response.data.totalClosed);
                    setOpen(response.data.totalOpen);
                    setNewTickets(response.data.totalNew);
                } else if (response.data.status_code === 0 || response.data.status_code === 2) {
                    setLoading(false);
                    toast.error(response.data.status_code, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/admin/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })

            .catch(function (error) {
                // console.log(error);
            });

    }, []);



    return (
        <>
            <AdminRightsCheck />
            {loading ? (
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "20%" }}
                >
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            ) : (
                <>
                    <ToastContainer />
                    <PageTitle title="SUPPORT" />
                    <div className="admin-content">
                        <div className="row">
                            <div className="col-md-3">
                                <AdminCard
                                    link="/admin/support-dashboard/new-ticket"
                                    class="brown"
                                    value={NewTickets}
                                    label="New Tickets"
                                    icon="add_reaction"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="/admin/support-dashboard/open-ticket"
                                    class="green"
                                    value={Open}
                                    label="Open Tickets"
                                    icon="open_in_new"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="/admin/support-dashboard/pending-ticket"
                                    class="blue"
                                    value={Pending}
                                    label="Pending Tickets"
                                    icon="hdr_strong"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                            <div className="col-md-3">
                                <AdminCard
                                    link="/admin/support-dashboard/close-ticket"
                                    class="red"
                                    value={Close}
                                    label="Closed Tickets"
                                    icon="gpp_bad"
                                    subheading="Members"
                                    no="(50)"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default SupportDashboard;
