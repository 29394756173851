import React, { useEffect, useRef, useState } from "react";
import AdminHeader from "./AdminHeader";
import { NavLink, Outlet } from "react-router-dom";
import AdminLoginCheck from "./AdminLoginCheck";
import AdminRightsCheck from "./AdminRightsCheck";
import { useNavigate, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import ReactTooltip from "react-tooltip";
function AdminLayout(props) {



  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    document.documentElement.setAttribute('data-bs-theme', newTheme);
  }

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme])




  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [collapse, setcollapse] = useState(false);

  const CollapseMenu = () => {
    setcollapse(!collapse);
    var admin_wrap = document.getElementsByClassName("admin-wrap");
    for (let i = 0; i < admin_wrap.length; i++) {
      admin_wrap[i].classList.toggle('show');
    }
  }

  const closeMenu = () => {
    var admin_side_bar = document.getElementById("admin_side_bar");
    var overlay = document.getElementById("overlaymenu");
    var menubutton = document.getElementById("menubutton");
    var body = document.getElementById("body");
    body.classList.remove('overflow-hidden');
    if (admin_side_bar) {
      admin_side_bar.classList.remove('active');
    }

    if (overlay) {
      overlay.classList.remove('active');
    }

    //console.log(menubutton);

    if (menubutton) {
      menubutton.classList.remove('active');
    }
  }


  let navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/admin/login");
  }

  // show active class on menu
  const currentLocation = useLocation();
  const isMenuItemActive = (path) => {
    return currentLocation.pathname.startsWith(path);
  };

  const [activeKey, setActiveKey] = useState(null);
  const accordionRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordionRef.current && !accordionRef.current.contains(event.target)) {
        setActiveKey(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <AdminLoginCheck />
      <AdminRightsCheck />
      <ReactTooltip />
      <div id="admin-body">
        <AdminHeader />
        <div onClick={closeMenu} id="overlaymenu"></div>
        <div className="admin-wrap">
          <div className={`admin-left admin_menu`}
            // menustatus={isMenuOpen}
            id="admin_side_bar">

            <ul className="admin_side_nav">
              <li>
                <NavLink onClick={CollapseMenu}>
                  <i className={`material-symbols-outlined admin_menu_arrow ${collapse ? "show" : ""} `}>
                    {collapse ? "arrow_forward_ios" : "arrow_back_ios"}
                  </i>
                  <span>Menu</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/dashboard" data-tip={collapse ? "Dashboard" : ""} data-place="right" className={isMenuItemActive('/admin/dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">dashboard</i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/package/manage-package" data-tip={collapse ? "Packages" : ""} data-place="right" className={isMenuItemActive('/admin/packages') ? 'active' : ''}>
                  <i className="material-symbols-outlined">package</i>
                  <span>Manage Packages</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/userstatistics-dashboard" data-tip={collapse ? "Member Statistics" : ""} data-place="right" className={isMenuItemActive('/admin/userstatistics-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">monitoring</i>
                  <span>Member Statistics</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/Members-overview" data-tip={collapse ? "Members Overview" : ""} data-place="right" className={isMenuItemActive('/admin/Members-overview') ? 'active' : ''}>
                  <i className="material-symbols-outlined">manage_accounts</i>
                  <span>Members Overview</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/balance-statistics-dashboard" data-tip={collapse ? "Admin Funds Statistics" : ""} data-place="right" className={isMenuItemActive('/admin/balance-statistics-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">bubble_chart</i>
                  <span>Admin Funds Statistics</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/total-transfer-dashboard" data-tip={collapse ? "Internal Transfers" : ""} data-place="right" className={isMenuItemActive('/admin/total-transfer-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">currency_exchange</i>
                  <span>Internal Transfers</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/admin-fee-dashboard" data-tip={collapse ? "Admin Fee" : ""} data-place="right" className={isMenuItemActive('/admin/admin-fee-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">credit_card</i>
                  <span>Admin Fee</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/total-packages-dashboard" data-tip={collapse ? "Total Packages" : ""} data-place="right" className={isMenuItemActive('/admin/total-packages-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">box</i>
                  <span>Total Packages</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/deposit-dashboard" data-tip={collapse ? "Deposit Panel" : ""} data-place="right" className={isMenuItemActive('/admin/deposit-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">score</i>
                  <span>Deposit Panel</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/withdrawal-dashboard" data-tip={collapse ? "Withdrawal Panel" : ""} data-place="right" className={isMenuItemActive('/admin/withdrawal-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">wallet</i>
                  <span>Withdrawal Panel</span>
                </NavLink>
              </li>
              <Accordion ref={accordionRef} activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>

                <li>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header data-tip={collapse ? "Bouns Panel" : ""} data-place="right" >
                      <i className="material-symbols-outlined">date_range</i>
                      <p>Bouns Panel</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <li className="sub-menu">
                        <NavLink to="/admin/active-bonus-dashboard">
                          <i></i>
                          <span>Smart Wallet</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/admin/passive-bonus-dashboard">
                          <i></i>
                          <span>Profit Wallet</span>
                        </NavLink>
                      </li>

                      <li className="sub-menu">
                        <NavLink to="/admin/reward-bonus-dashboard">
                          <i></i>
                          <span>Reward Bouns</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/admin/Promo-dashboard">
                          <i></i>
                          <span>Promo Vouchers</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/admin/userstatistics-dashboard/leadership">
                          <i></i>
                          <span>Leadership</span>
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </li>

                <li>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header data-tip={collapse ? "Reports" : ""} data-place="right">
                      <i className="material-symbols-outlined">contract</i>
                      <p>Reports</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <li className="sub-menu">
                        <NavLink to="/admin/userstatistics-dashboard/Stateements">
                          <i></i>
                          <span>Accounts Statement</span>
                        </NavLink>
                      </li>
                      <li className="sub-menu">
                        <NavLink to="/admin/reports/Scheduler" className={isMenuItemActive('/admin/reports/Scheduler') ? 'active' : ''}>
                          <i></i>
                          <span>Schedulers</span>
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </li>

              </Accordion>
              <li>
                <NavLink to="/admin/franchisees-dashboard" data-tip={collapse ? "Franchisees" : ""} data-place="right" className={isMenuItemActive('/admin/franchisees-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">candlestick_chart</i>
                  <span>Franchisees</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/support-dashboard" data-tip={collapse ? "Support" : ""} data-place="right" className={isMenuItemActive('/admin/support-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">insights</i>
                  <span>Support</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/recent-notification" data-tip={collapse ? "Recent Notification" : ""} data-place="right" className={isMenuItemActive('/admin/recent-notification') ? 'active' : ''}>
                  <i className="material-symbols-outlined">notifications</i>
                  <span>Recent Notification</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/security-dashboard" data-tip={collapse ? "Security" : ""} data-place="right" className={isMenuItemActive('/admin/security-dashboard') ? 'active' : ''}>
                  <i className="material-symbols-outlined">verified_user</i>
                  <span>Security</span>
                </NavLink>
              </li>
              <li>
                <a href="#" onClick={handleSignOut} data-tip={collapse ? "Sign Out" : ""} data-place="right">
                  <i className="material-symbols-outlined">logout</i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>

          </div>
          <div className="admin-right" id="right-side">
            <Outlet></Outlet>
          </div>
        </div>
        {/* <div className="admin-theme-icons d-none">
          <div className={`iocn ${theme == "light" ? 'active' : ''}`} onClick={toggleTheme}>
            {
              theme != "light" ?
                <MoonIcon className={`${theme == "light" ? 'moon' : ''}`} />
                :
                <SunIcon className={`${theme == "light" ? 'sun' : ''}`} />
            }
          </div>
        </div> */}
      </div>
    </>
  );
}

export default AdminLayout;
