import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import UserImg from "../../../assets/images/partner/user.svg";
import verification from "../../../assets/images/partner/verification-icon.svg";
import Accordion from 'react-bootstrap/Accordion';
import Config from '../../../Config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import LoginCheck from '../General/LoginCheck';
import { ArrowLongRightIcon, BookmarkIcon, PencilIcon } from '@heroicons/react/24/outline';
const KycApproved = () => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");

  const [NewAddress, setNewAddress] = useState("");
  const [AddressEdit, setAddressEdit] = useState(false);
  const [PhoneEdit, setPhoneEdit] = useState(false);
  const [Edit, setEdit] = useState('');


  let navigate = useNavigate();


  useEffect(() => {
    // console.log(MemberID);



    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID
    };

    var api_config = {
      method: 'post',
      url: Config.base_url + 'MemberKYC/GetMemberKYC',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setUser(response.data)
          setLoading(false);
        }
        else if (response.data.status_code === 0) {

          navigate("/login")

        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }


      })
      .catch(function (error) {
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT,
          //onClose: () => window.alert('Called when I close')
        });
      });


  }, [loading, isUpdated]);



  const [MobileNo, setMobileNo] = useState("")

  const handleMobileNo = (e) => {
    const value = e.target.value;
    const formattedValue = value.startsWith('0') ? value.substring(1) : value;
    const slicedValue = formattedValue.substring(0, 13);
    setMobileNo(slicedValue);
  };
  const UpdateKYC = () => {

    if (Edit == 'Phone') {
      if (MobileNo == "") {
        toast.error('Please enter Phone No', {
          position: toast.POSITION.TOP_RIGHT
        });

        return false;
      }
    } else {
      if (NewAddress == "") {
        toast.error('Please enter address', {
          position: toast.POSITION.TOP_RIGHT
        });

        return false;
      }
    }
    setIsUpdated(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };
    if (Edit == 'Phone') {
      data.MobileNo = MobileNo;
    } else {
      data.Address = NewAddress
    }


    var api_config = {
      method: 'post',
      url: Config.base_url + 'MemberKYC/UpdateKYC',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data)
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });

          if (Edit == 'Phone') {
            setPhoneEdit(false);
          } else {
            setAddressEdit(false);
          }
          setIsUpdated(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });

  }
  return (
    <>
      <LoginCheck />
      <ToastContainer />
      <div className="section-title mb-3">KYC (Know your client)</div>
      <div className="pb-5">
        <div className="dash-bg-card kyc-submitted">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className='pink-bg'>
                <img src={UserImg} alt="" />
                <h5>KYC Approved</h5>
              </div>
              <div className="verification mt-3">
                <div className="content">
                  <h4>Congratulations! You are a verified member of MAG Ventures.</h4>
                </div>
                <div className="icon">
                  <img src={verification} alt="" />
                </div>
              </div>
              <div className="accordion-section mt-3">
                <Accordion className='border-free-accordion' defaultActiveKey={['0']} flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Documents Detail</Accordion.Header>
                    <Accordion.Body>
                      <ul className="opportunity-icon-list p-0">
                        <li className={`${user.DocumentType == "ID" ? '' : 'close'}`}>
                          <div className="info">
                            {user.DocumentType == "ID" ?
                              <CheckCircleIcon />
                              :
                              <XCircleIcon />
                            }
                            <p>National Identity Card</p>
                          </div>
                          <div className={`image ${user.DocumentType === "ID" ? '' : 'd-none'}`}>
                            {user.DocumentType === "ID" && (
                              <>
                                <div className="user-card">
                                  <div className="front">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.IDFront}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                  <div className="back">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.IDBack}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                        <li className={`${user.DocumentType == "License" ? '' : 'close'}`}>
                          <div className="info">
                            {user.DocumentType == "License" ?
                              <CheckCircleIcon />
                              :
                              <XCircleIcon />
                            }
                            <p>Licence</p>
                          </div>
                          <div className={`image ${user.DocumentType === "License" ? '' : 'd-none'}`}>
                            {user.DocumentType === "License" && (
                              <>
                                <div className="user-card">
                                  <div className="front">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.LicenseFront}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                  <div className="back">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.LicenseBack}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                        <li className={`${user.DocumentType == "Passport" ? '' : 'close'}`}>
                          <div className="info">
                            {user.DocumentType == "Passport" ?
                              <CheckCircleIcon />
                              :
                              <XCircleIcon />
                            }
                            <p>Passport</p>
                          </div>
                          <div className={`image ${user.DocumentType === "Passport" ? '' : 'd-none'}`}>
                            {user.DocumentType === "Passport" && (
                              <>
                                <div className="user-card">
                                  <div className="passport-image">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.Passport}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Profile Information</Accordion.Header>
                    <Accordion.Body>
                      <ul className='kyc-user-info'>
                        <li>
                          <span>First name</span>
                          <h5>{user.FirstName == null ? '---' : user.FirstName}</h5>
                        </li>
                        <li>
                          <span>Last name</span>
                          <h5>{user.LastName}</h5>
                        </li>
                        <li>
                          <span>KYC Type</span>
                          <h5>{user.KYCType == null ? 'Standard' : user.KYCType}</h5>
                        </li>
                        <li>
                          <span>Phone</span>
                          {PhoneEdit ?

                            <input
                              type='text'
                              value={MobileNo}
                              onChange={handleMobileNo}
                            />
                            :
                            <h5>{user.MobileNo != null ? `${user.MobileNo}` : 'xxx xxx xxxx'}</h5>

                          }
                          <button className={`button button-bs button-icon-bs ${PhoneEdit ? 'active' : 'd-none'}`} onClick={(e) => UpdateKYC()}>
                            <ArrowLongRightIcon />
                          </button>

                          <PencilIcon className={PhoneEdit ? 'd-none' : ''} onClick={(e) => { setPhoneEdit(true); setEdit('Phone') }} />

                        </li>
                        <li>
                          <span>Gender</span>
                          <h5>{user.Gender == null ? '---' : user.Gender}</h5>
                        </li>
                        <li>
                          <span>Date of Birth</span>
                          <h5>{moment(user.DateOfBirth).format(Config.date_format)}</h5>
                        </li>
                        <li>
                          <span>Nationality</span>
                          <h5>{user.BirthCountry}</h5>
                        </li>
                        <li>
                          <span>Residency</span>
                          <h5>Pakistan</h5>
                        </li>
                        <li>
                          <span>Occupation</span>
                          <h5>{user.Occupation == null ? '---' : user.Occupation}</h5>
                        </li>
                        <li>
                          <span>Email</span>
                          <h5>{Email}</h5>
                        </li>
                        <li>
                          <span>Address</span>
                          {AddressEdit ?

                            <input
                              type='text'
                              value={NewAddress}
                              onChange={(e) => setNewAddress(e.target.value)}
                            />
                            :
                            <h5>{user.Address == null ? '---' : user.Address}</h5>

                          }
                          <button className={`button button-bs button-icon-bs ${AddressEdit ? 'active' : 'd-none'}`} onClick={(e) => UpdateKYC()}>
                            <ArrowLongRightIcon />
                          </button>

                          <PencilIcon className={AddressEdit ? 'd-none' : ''} onClick={(e) => { setAddressEdit(true); setEdit('Address') }} />
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <Link to={`/partner/dashboard`} className='button button-outline-bs w-100 mt-3'>
                Go to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KycApproved