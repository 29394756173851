import React from 'react';
import { NavLink } from 'react-router-dom';

function PassiveMenu(props) {
    return (
        <>
            <ul className="menu-option">
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/profit-wallet/roi-income">Profit Return</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/profit-wallet/tree-income">Royalty Income</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/profit-wallet/internal-transaction">Internal Transactions</NavLink>
                </li>
            </ul>
        </>
    );
}

export default PassiveMenu;