import React, { useState, useEffect, useRef } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Common/Util";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import { ArrowPathIcon, AtSymbolIcon, BuildingLibraryIcon, CalculatorIcon, ChatBubbleLeftRightIcon, UserIcon } from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";

const CashInList = () => {


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [GrandTotal, setGrandTotal] = useState('');

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [listOfLedger, setLedger] = useState([]);
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [SearchMember, setSearchMember] = useState('');
    const [show, setShow] = useState(false);
    const [Rejectedshow, setRejectedShow] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [BalaceLoading, setBalaceLoading] = useState(false);


    const [RecieptImg, setRecieptImg] = useState('');
    
    const [Remarks, setRemarks] = useState('');
    const [CollectionStatus, setCollectionStatus] = useState('');
    const [ChequeNo, setChequeNo] = useState('');
    const [BankName, setBankName] = useState('');
    const [UserName, setUserName] = useState('');
    const [Email, setEmail] = useState('');
    const [SelectedUserID, setSelectedUserID] = useState('');


    const handlePageChange = (page) => {
        setPageNo(page);
    };


    useEffect(() => {


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            UserName: SearchMember,
            startDate: StartDate,
            endDate: EndDate,
        };

        // console.log("data data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "UserCollection/GetUserCollections",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data)
                    setLoading(false);
                } else if (response.data.status_code === 0 || response.data.status_code === 2) {
                    setLoading(false);
                    toast.error(response.data.status_code, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/franchise/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, SearchMember, StartDate, EndDate, BalaceLoading]);
 

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Dated).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{item.User_Name}</td>
                        <td>PKR {item.Amount}</td>
                        <td>{item.CollectionType}</td>
                        <td>{item.ChequeStatus}</td>
                        <td>{item.BankName}</td>
                        <td>{item.ChequeNo}</td>
                        <td>{item.Extra1}</td>
                        <td>{item.Description}</td>
                        <td>
                            <ul
                                className="inline-action"
                            >
                                {(item.CollectionType == 'Cheque' && item.ChequeStatus == 'Pending') &&
                                    <>
                                        <li>
                                            <button
                                                onClick={(Bank_Name, ChequeNo, User_Name, E_mail, User_ID, Collection_Status) => {
                                                    handleShow(item.BankName, item.ChequeNo, item.User_Name, item.Email_Address, item.ID, item.ChequeStatus)
                                                }}
                                                className="primary"
                                            >
                                                <span className="material-symbols-outlined">
                                                    view_in_ar
                                                </span>
                                            </button>
                                        </li>
                                    </>
                                }
                            </ul>

                        </td>
                        <td>
                            <ul
                                className="inline-action"
                            >
                                {(item.Extra1 == 'Agreement Claimed Fee Received') &&
                                    <li>
                                        <Link to={`/franchise/cash-in-list-print/${item.ID}`} target="_blank" className="black">
                                            <span className="material-symbols-outlined">
                                                print
                                            </span>
                                        </Link>
                                    </li>
                                }
                                <li>
                                    <Link to={`/franchise/cash-in-list-print/${item.ID}`} target="_blank" className="black">
                                        <span className="material-symbols-outlined">
                                            print
                                        </span>
                                    </Link>
                                </li>
                            </ul>

                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="10">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (Bank_Name, ChequeNo, User_Name, E_mail, User_ID, Collection_Status) => {
        setShow(true);
        setChequeNo(ChequeNo);
        setBankName(Bank_Name);
        setUserName(User_Name);
        setEmail(E_mail);
        setSelectedUserID(User_ID);
        setCollectionStatus(Collection_Status);
    }


    const valueNull = () => {
        setRemarks('');
        setUserName('');
        setEmail('');
        setChequeNo('');
        setBankName('');
        setSelectedUserID('');
        setCollectionStatus('');
    }

    const handleSendBalace = () => {
        setBalaceLoading(true);
        const Data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: SelectedUserID,
            AdminRemarks: Remarks,
            ChequeStatus: 'Cleared',

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'UserCollection/UpdateCollectionStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Data
        };
        // console.log(Data, 'Data');

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setBalaceLoading(false);
                    setShow(false);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setBalaceLoading(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    const handleRejectedSendBalace = () => {
        setBalaceLoading(true);
        const Data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: SelectedUserID,
            AdminRemarks: Remarks,
            ChequeStatus: 'Rejected',

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'UserCollection/UpdateCollectionStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Data
        };
        // console.log(Data, 'Data');

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setBalaceLoading(false);
                    setRejectedShow(false);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setBalaceLoading(false);
                    setRejectedShow(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    const InvoiceRef = useRef;
    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");
                setRecieptImg(imgData);
                // console.log(RecieptImg)
            });
        }
    };

    return (
        <>
            <FranchiseLoginCheck />
            <ToastContainer />
            <Modal
                className="modal-theme size-md p-0"
                show={show} onHide={handleClose} onExit={valueNull}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cash Collection</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-start">

                    <div className="icon-input">
                        <input type="text" placeholder='Name' disabled value={UserName} readOnly />
                        <div className="icon">
                            <UserIcon />
                        </div>
                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder='Email Address' disabled value={Email} readOnly />
                        <div className="icon">
                            <AtSymbolIcon />
                        </div>
                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder='Collection Status' disabled value={CollectionStatus} readOnly />
                        <div className="icon">
                            <ArrowPathIcon />
                        </div>
                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder='Bank Name' disabled value={BankName} readOnly />
                        <div className="icon">
                            <BuildingLibraryIcon />
                        </div>
                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder='cheque No' disabled value={ChequeNo} onChange={(e) => setChequeNo(e.target.value)} readOnly />
                        <div className="icon">
                            <CalculatorIcon />
                        </div>
                    </div>
                    <div className="icon-input">
                        <textarea type="text" placeholder='Remarks' value={Remarks} onChange={(e) => setRemarks(e.target.value)} />
                        <div className="icon">
                            <ChatBubbleLeftRightIcon />
                        </div>
                    </div>
                    <div className="button-row justify-content-end">
                        <button className="button button-outline-primary" onClick={handleRejectedSendBalace} disabled={BalaceLoading}>Rejected {BalaceLoading ? '...' : ''}</button>

                        <button className="button button-primary" onClick={handleSendBalace} disabled={BalaceLoading}>Approved / Send Balance {BalaceLoading ? '...' : ''}</button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Cash Collection
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/franchise/dashboard">
                                 <button type="button" className="button button-outline-bs">
                Back
              </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">

                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">SEARCH MEMBER</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Find by Username/Member ID/Email"
                                                        value={SearchMember}
                                                        onChange={(e) => setSearchMember(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Collection Date</th>
                                    <th>Username</th>
                                    <th>Cash In</th>
                                    <th>Collection Type</th>
                                    <th>Status</th>
                                    <th>Bank Name</th>
                                    <th>Cheque No</th>
                                    <th>Details</th>
                                    <th>Admin Remarks</th>
                                    <th className="text-center">Action</th>
                                    <th className="text-center">Print</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="10">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger.length > 0 &&
                                            <>
                                                <tr>
                                                    <th colSpan={2} className="border-bottom-0 pt-3"></th>
                                                    <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</th>
                                                    <th className="border-bottom-0 pt-3"></th>
                                                    <th className="border-bottom-0 pt-3"></th>

                                                </tr>
                                                <tr className="">
                                                    <th className="pt-3" colSpan={2}></th>
                                                    <th className="text-end">Grand Total: </th>
                                                    <th>PKR {NumberFormat(GrandTotal.totalCollection)}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default CashInList