import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import { NavLink, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import LoginCheck from "../General/LoginCheck";
import Pagination from "../General/Pagination";
import { ChevronRightIcon, FaceFrownIcon } from "@heroicons/react/24/outline";
import TicketsDetail from "./TicketsDetail";

function Open(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [loading, setLoading] = useState(false);


    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [TicketID, setTicketId] = useState(0);

    let params = useParams();


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Status: "Open"
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetSupportTicket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                    setTotalRecords(response.data.totalRecords);
                    // console.log(response.data.ListofTickets)
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });



    }, [pageNo, pageSize]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLogs = () => {
        if (tickets.length > 0) {


            let count = 1;
            return (
                tickets.map((item, index) => {

                    return (
                        <>
                            <li key={index}>
                                <h2>
                                    {item.Subject}
                                </h2>
                                <div className="ticket-id">{item.TicketID}</div>
                                <div className="d-flex mt-2 mb-2 justify-content-between">
                                    <div>{item.TicketType}</div>
                                    <div>{moment(item.Dated).format('DD MMM, YYYY HH:mm:ss')}</div>
                                </div>





                                <div>
                                    <button type="button" onClick={(e) => handleTicketDetail(item.ID)} className="button button-icon-bs button-outline-gray">
                                        Check Status <ChevronRightIcon />
                                    </button>
                                </div>
                            </li>
                        </>
                    )
                })
            )
        }

        else {
            return (
                <div className="else">
                    <FaceFrownIcon />
                    <h4>No Data Found</h4>
                </div>
            )
        }
    }


    const handleTicketDetail = (TicketID) => {
        setTicketId(TicketID);
    }

    const BackToOpen = () => {
        setTicketId(0);
    }

    return (
        <>
            <LoginCheck />
            {
                loading ?
                    <div className="p-5">
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                    :

                    TicketID == 0 ?
                        <ul className="list-support-tickets">
                            {renderLogs()}
                        </ul>
                        :
                        <TicketsDetail id={TicketID} BackToOpen={BackToOpen} />


            }


        </>
    );
}

export default Open;