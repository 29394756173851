export function checkNumber(num) {
  if (num > 0) {
    return num + " $";
  } else {
    return "-";
  }
}
// export function calculateColumnTotal(data, columnName) {
//   let total = 0;

//   data.forEach((row) => {
//     total += row[columnName];
//   });

//   return total;
// }
export function calculateColumnTotal(data, columnName) {
  let total = 0;

  data.forEach((row) => {
    const columnValue = parseFloat(row[columnName]);
    total += isNaN(columnValue) ? 0 : columnValue;
  });

  return total;
}


export function FirstColumnData(data, columnName) {
  let total = 0;

  const firstRow = data[0]; // Get the first row of data

  const columnValue = parseFloat(firstRow[columnName]);
  total += isNaN(columnValue) ? 0 : columnValue;

  return total;
}
