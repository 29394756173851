import { ArrowDownOnSquareStackIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, ArrowUpOnSquareStackIcon, ArrowsRightLeftIcon, ChartPieIcon, CreditCardIcon, CurrencyDollarIcon, GiftIcon, SwatchIcon, WalletIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const TransactionMenu = (props) => {

    // show active class on menu
    const currentLocation = useLocation();
    const isMenuItemActive = (path) => {
        return currentLocation.pathname.startsWith(path);
    };

    return (
        <>
        <div className="section-title mb-3">Transactions</div>
            <div className="active-link-menu">
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/partner/transactions/membership">
                            <CreditCardIcon />
                            <span>Packages</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link ${isMenuItemActive('/partner/transactions/smart-wallet/') ? 'active' : ''}`} to="/partner/transactions/smart-wallet/direct-bonus">
                            <WalletIcon />
                            <span>Smart Wallet</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link ${isMenuItemActive('/partner/transactions/profit-wallet/') ? 'active' : ''}`} to="/partner/transactions/profit-wallet/roi-income">
                            <SwatchIcon />
                            <span>Profit Wallet</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link ${isMenuItemActive('/partner/transactions/treasure-wallet/') ? 'active' : ''}`} to="/partner/transactions/treasure-wallet/ranks-reward">
                            <GiftIcon />
                            <span>Treasure Wallet</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link ${isMenuItemActive('/partner/transactions/activation-history/history') ? 'active' : ''}`} to="/partner/transactions/activation-history/history/Recharge">
                            <ChartPieIcon />
                            <span>Holding Wallet</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className={`nav-link`} to="/partner/transactions/Others">
                            <ArrowPathRoundedSquareIcon />
                            <span>Others</span>
                        </NavLink>
                    </li>


                    <li className="nav-item">
                        <NavLink className={`nav-link`} to="/partner/transactions/deposits">
                            <ArrowDownOnSquareStackIcon />
                            <span>Deposits</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className={`nav-link`} to="/partner/transactions/withdraw">
                            <ArrowUpOnSquareStackIcon />
                            <span>Withdrawal</span>
                        </NavLink>
                    </li>
                   
                    <li className="nav-item">
                        <NavLink className={`nav-link ${isMenuItemActive('/partner/transactions/funds-history/') ? 'active' : ''}`} to="/partner/transactions/funds-history/sent">
                            <ArrowPathIcon />
                            <span>Funds History</span>
                        </NavLink>
                    </li>
                   
                </ul>
            </div>
        </>
    )
}

export default TransactionMenu