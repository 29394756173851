import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SupportMenu from "./SupportMenu";
import NewTicket from "./NewTicket";
import Inbox from "./Inbox";
import Open from "./Open";
import TicketsClose from "./TicketsClose";
import LoginCheck from "../General/LoginCheck";

function SupportOffcanvas(props) {





    return (
        <>
            <LoginCheck />
            <Offcanvas className='support-pannel-offcanvas' show={props.show} onHide={props.handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Support</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SupportMenu />
                    <div className="tab-content support-pannel" id="myTabContent">
                        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                            <NewTicket />
                        </div>
                        <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                            <Inbox />
                        </div>
                        <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                            <TicketsClose />
                        </div>
                        <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">
                            <Open />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default SupportOffcanvas;