import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import config from "../../Config";
import axios from "axios";
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/partner/login-bg.png';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";



import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { generateRandomString } from "../Dashboard/General/Functions";
import { ArrowLeftEndOnRectangleIcon, UserCircleIcon } from "@heroicons/react/24/outline";

function Forgot(props) {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [Usercaptcha, setUsercaptcha] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    let navigate = useNavigate();



    useEffect(() => {
        document.title = "Forgot Password";
        setCaptcha(generateRandomString(6));
    }, []);

    const handleSubmit = (event) => {


        setCaptcha(generateRandomString(6));

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            setLoading(true);
            const signInData = {
                Email_Address: event.target.email.value,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/ForgotPassword',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: signInData
            };



            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });


                        setIsButtonDisabled(true);

                        setTimeout(() => {
                            setIsButtonDisabled(false);
                        }, 60000);


                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });


        }

        setValidated(true);
    };


    return (
        <>
            <ToastContainer />
            <section className='business-signin-form login-page'>
                <img src={background} />
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className='container'>
                        <div className="login_box">
                            <div className="login_box_head">
                                <img src={logo} />
                                <h4>Forgot Password</h4>
                            </div>
                            <div className="login_box_body">
                                <div className="icon-input">
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder='Email/Username'
                                        className=""
                                        required
                                    />
                                    <div className="icon">
                                        <UserCircleIcon />
                                    </div>
                                </div>
                                <button type="submit" className="button button-bs w-100"
                                    disabled={isButtonDisabled || loading}
                                >
                                    Get Reset Link {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                                </button>
                                <div className='mt-3 d-flex m-auto justify-content-center'>
                                    <p className='m-0 fs-6'>I already have an account<NavLink to={'/login'} className='fs-6'> SIGN IN</NavLink></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </section>
        </>
    );
}

export default Forgot;