import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "../PageTitle";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import FranchiseLoginCheck from "../FranchiseLoginCheck";

function FranchiseUserDetails(props) {
  const navigate = useNavigate();
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [listOfLedger, setLedger] = useState([]);
  const [user, setUser] = useState("");
  const [status, setStatus] = useState("Verified");

  const [cnic, setCnic] = useState();
  const [pasportno, setPasportno] = useState();
  const [licenseno, setLicenseno] = useState();
  const [adminRemarks, setAdminRemarks] = useState();
  const [MemberID, setMemberID] = useState("");

  const [stateValidate, setStateValidate] = useState(false);

  const { KYCID } = useParams();

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  const [Imgpopup, setImgpopup] = useState("");



  const [validateLoading, setvalidateLoading] = useState(false);
  const [validationCode1, setValidationCode1] = useState(false);
  const [validationCode0, setValidationCode0] = useState(false);

  const [nextId, setNextId] = useState(KYCID);

  useEffect(() => {
    // console.log(KYCID);

    document.title = "MAG Franchise";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: KYCID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMemberKYCByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // console.log(data);

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setUser(response.data);
          setMemberID(response.data.MemberID);

          // console.log("user data", response.data);
          // setLedger(response.data.ListofLedger);
          setLoading(false);
          // setTotalRecords(response.data.totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/franchise/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pageSize, nextId]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleUpdate = () => {
    setLoadingUpdate(true);
    const dataforUpdate = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: KYCID,
      CurrentStatus: status,
      IDCardNumber: cnic,
      PassportNumber: pasportno,
      LicenseNumber: licenseno,
      AdminRemarks: adminRemarks,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/UpdateMemberKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataforUpdate,
    };

    axios(api_config)
      .then(async function (response) {
        if (response.data.status_code === 1) {
          try {
            const Response = await axios.post(
              config.base_url + "Users/GetNextKYCID",
              {
                UserID: UserID,
                AccessKey: AccessKey,
                ID: KYCID,
              }
            );
            // console.log(response.data)
            if (Response.data.status_code === 1 && Response.data.NextKYCID !== 0) {
              setNextId(Response.data.NextKYCID);
              navigate(`/franchise/kyc-request/${Response.data.NextKYCID}`);
              setPasportno('');
              setLicenseno('')
            } else if (Response.data.status_code === 0 || Response.data.status_code === 2) {
              navigate("/franchise/login");
            } else {
              toast.error("No More Request Found", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/franchise/kyc-request");
            }
          } catch (error) {
            console.log(error);
          }
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters")
        ) {
          navigate("/franchise/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoadingUpdate(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleNext = async () => {
    try {
      const response = await axios.post(
        config.base_url + "Users/GetNextKYCID",
        {
          UserID: UserID,
          AccessKey: AccessKey,
          ID: KYCID,
        }
      );

      if (response.data.status_code === 1 && response.data.NextKYCID !== 0) {
        setNextId(response.data.NextKYCID);
        navigate(`/franchise/kyc-request/${response.data.NextKYCID}`);
      } else if (
        response.data.status_code === 0 ||
        response.data.status_code === 2
      ) {
        navigate("/franchise/login");
      } else {
        toast.error("No More Request Found", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      console.log(error);
    }
  };

  const VerifyNo = async () => {
    setvalidateLoading(true);

    const dataforValidation = {
      UserID: UserID,
      AccessKey: AccessKey,
      MemberID: MemberID,
      KYCDocType: user.DocumentType,
      IDCardNumber: cnic,
      PassportNumber: pasportno,
      LicenseNumber: licenseno,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/ValidateKYC",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataforValidation,
    };
    // console.log(dataforValidation);

    axios(api_config)
      .then(function (response) {
        // console.log(response.data)
        if (response.data.status_code === 1) {
          setvalidateLoading(false);
          setStateValidate(true);
          setValidationCode1(true);
          setValidationCode0(false);
          // setLoading(false);
        } else if (response.data.status_code === 0) {
          setvalidateLoading(false);
          setValidationCode1(false);
          setValidationCode0(true);
          setStateValidate(true);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getimgsource = (e) => {
    const src = e.target.src;
    setImgpopup(src);
  };
  const renderLedger = () => {
    if (user) {
      return (
        <>
          <div className="form_admin">
            <div className="row gy-3">
              <div className="col-md-3">
                <div className="card card-table">
                  <div className="section-title">Personal details.</div>
                  <div className="form-group">
                    <label>First Name</label>
                    <div className="inputwrap">
                      <div className="form-control disabled">
                        {user.FirstName}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <div className="inputwrap">

                      <div className="form-control disabled">
                        {user.LastName}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Father Name</label>
                    <div className="inputwrap">

                      <div className="form-control disabled">
                        {user.FatherName}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Birth Country</label>
                    <div className="inputwrap">

                      <div className="form-control disabled">
                        {user.BirthCountry}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Username</label>
                    <div className="inputwrap">

                      <div className="form-control disabled">
                        {user.User_Name}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="inputwrap">
                      <div className="form-control disabled">
                        {user.Email_Address}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Mobile No</label>
                    <div className="inputwrap">
                      <div className="form-control disabled">
                        {user.MobileNo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="row gy-2">
                      <div className="section-title">
                        Document Item (
                        {user.DocumentType == "License" ? (
                          <>
                            <span>Driving license</span>
                          </>
                        ) : user.DocumentType === "ID" ? (
                          <>
                            <span>ID</span>
                          </>
                        ) : user.DocumentType === "Passport" ? (
                          <>
                            <span>Passport</span>
                          </>
                        ) : (
                          <></>
                        )}
                        )
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Document Type</label>
                          <div className="value">
                            <div className="form-control disabled">
                              {user.DocumentType}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {user.DocumentType === "License" && (

                          <>
                            <br />
                            <div className="user-card">
                              <div className="front">
                                <img
                                  onClick={(e) => getimgsource(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.LicenseFront}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                              <div className="back">
                                <img
                                  onClick={(e) => getimgsource(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.LicenseBack}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                            </div>
                            <br />
                            <div className="icon-form-group">
                              <label>Driving license</label>
                              <div className="input-button-row">
                                <div className="icon-input">
                                  <input
                                    placeholder="License Number"
                                    className="form-control"
                                    value={licenseno}
                                    onChange={(e) => {
                                      setLicenseno(e.target.value);
                                      setStateValidate(false);
                                    }}
                                  />

                                  <div className="icon">
                                    {validationCode1 &&
                                      stateValidate === true &&
                                      licenseno ? (
                                      <CheckCircleIcon />
                                    ) : validationCode0 &&
                                      stateValidate === true &&
                                      licenseno ? (
                                      <XCircleIcon />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <button
                                  onClick={VerifyNo}
                                  className="button button-primary input-height"
                                  type="button"
                                  // disabled={
                                  //   licenseno === validLicense ? false : true
                                  // }
                                  disabled={licenseno ? false : true}
                                >
                                  <span>
                                    {validateLoading
                                      ? "Loading.."
                                      : "VALIDATE"}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {user.DocumentType === "ID" && (
                          <>
                            <br />
                            <div className="user-card">
                              <div className="front">
                                <img
                                  onClick={(e) => getimgsource(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.IDFront}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                              <div className="back">
                                <img
                                  onClick={(e) => getimgsource(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.IDBack}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                            </div>
                            <br />
                            <div className="icon-form-group">
                              <label>ID</label>
                              <div className="input-button-row">
                                <div className="icon-input">
                                  <input
                                    placeholder="CNIC Number"
                                    className="form-control"
                                    value={cnic}
                                    onChange={(e) => {
                                      setCnic(e.target.value);
                                      setStateValidate(false);
                                    }}
                                  />

                                  <div className="icon">
                                    {validationCode1 &&
                                      stateValidate === true &&
                                      cnic ? (
                                      <CheckCircleIcon />
                                    ) : validationCode0 &&
                                      stateValidate === true &&
                                      cnic ? (
                                      <XCircleIcon />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>


                                <button
                                  onClick={VerifyNo}
                                  className="button button-primary input-height"
                                  type="button"
                                  // disabled={cnic === validCnic ? false : true}
                                  disabled={cnic ? false : true}
                                >
                                  <span>
                                    {validateLoading
                                      ? "Loading.."
                                      : "VALIDATE"}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {user.DocumentType === "Passport" && (
                          <>
                            <br />
                            <div className="user-card">
                              <div className="front">
                                <img
                                  onClick={(e) => getimgsource(e)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  src={`${config.imge}/${user.Passport}`}
                                  alt={user.DocumentType}
                                />
                              </div>
                            </div>
                            <div className="icon-form-group">
                              <label>Passport</label>
                              <div className="input-button-row">
                                <div className="icon-input">
                                  <input
                                    placeholder="Passport Number"
                                    className="form-control"
                                    value={pasportno}
                                    onChange={(e) => {
                                      setPasportno(e.target.value);
                                      setStateValidate(false);
                                    }}
                                  />

                                  <div className="icon">
                                    {validationCode1 &&
                                      stateValidate === true &&
                                      pasportno ? (
                                      <CheckCircleIcon />
                                    ) : validationCode0 &&
                                      stateValidate === true &&
                                      pasportno ? (
                                      <XCircleIcon />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <button
                                  onClick={VerifyNo}
                                  className="button button-primary input-height"
                                  type="button"
                                  // disabled={
                                  //   pasportno === validPassport ? false : true
                                  // }
                                  disabled={pasportno ? false : true}
                                >
                                  <span>
                                    {validateLoading
                                      ? "Loading.."
                                      : "VALIDATE"}
                                  </span>
                                </button>
                              </div>

                            </div>
                          </>
                        )}
                      </div>

                      <div className="col-md-12">
                        <div className="icon-form-group">
                          <label>Current Status</label>
                          <div className="icon-input">
                            <select
                              id="dropdown"
                              className="form-select"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="Verified">Verified</option>
                              <option value="Declined">Declined</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="icon-form-group">
                          <label>Admin Remarks</label>
                          <div className="icon-input">
                            <textarea
                              rows={3}
                              className="form-control"
                              placeholder="admin remarks"
                              value={adminRemarks}
                              onChange={(e) => setAdminRemarks(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3 gy-3">
                        <div className="col-md-4">
                          <button
                            className="button w-100 button-outline-primary"
                            onClick={() => navigate("/franchise/userstatistics-dashboard/KycRequests")}
                          >
                            Back
                          </button>
                        </div>
                        <div className="col-md-4">
                          {loadingUpdate ? (
                            <div className="text-center">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            <button
                              className="button w-100 button-primary"
                              onClick={handleUpdate}
                              disabled={
                                (validationCode0 && status === "Verified") ||
                                  (!stateValidate && status === "Verified")
                                  ? // || stateValidate === false
                                  true
                                  : (stateValidate && status === "Declined") ||
                                    validationCode1
                                    ? false
                                    : ""
                              }
                            // disabled={
                            //   validationCode1 &&
                            //     stateValidate === true &&
                            //     (cnic || pasportno || licenseno)
                            //     ? false
                            //     : true
                            // }
                            >
                              Update
                            </button>
                          )}
                        </div>
                        <div className="col-md-4">
                          <button
                            className="button w-100 button-primary"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <h5
          className="mt-3 mr-3"
          style={{ fontSize: "20px", color: "rgba(0,0,0,0.6)" }}
        >
          No Data Found
        </h5>
      );
    }
  };
  return (
    <>
      <ToastContainer />
      <PageTitle title="KYC REQUEST" />
      <FranchiseLoginCheck />
      <div className="admin-content">
        <div>
          {loading ? (
            <p className="text-center" colSpan="10">
              <Spinner animation="border" role="status"></Spinner>
            </p>
          ) : (
            <>
              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" style={{ maxWidth: "fit-content" }}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <img src={Imgpopup} alt="user card" style={{ maxWidth: "100%", height: "auto" }} />
                    </div>
                  </div>
                </div>
              </div>
              {renderLedger()}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FranchiseUserDetails;
