import React from 'react';
import { NavLink } from 'react-router-dom';

function HistoryMenu(props) {
    return (
        <>
            <ul className="menu-option">
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/funds-history/sent">Transfer History</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/partner/transactions/funds-history/received" >Received History</NavLink>
                </li>
            </ul>
        </>
    );
}

export default HistoryMenu;