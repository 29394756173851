import React from 'react';

function PageTitle(props) {
    return (
        <>
            <div className="admin-title">
                <div className="row">
                    <div className="col-md-6">
                        <h4>{capitalizeFirstLetter(props.title)}</h4>
                        {/* <h4>{props.subtitle}</h4> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageTitle;

function capitalizeFirstLetter(title) {
    return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
  }