import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { NumberFormat, NumberFormat6D } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import AdminRightsCheck from "../AdminRightsCheck";
import csvDownload from "json-to-csv-export";
import { JsonToExcel } from "react-json-to-excel";
import Form from 'react-bootstrap/Form';
import ReactTooltip from "react-tooltip";
import AllFranchiseesDropDown from "../../Common/Dropdowns/AllFranchiseesDropDown";

function Approved(props) {


  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [ledgerOld, setLedgerOld] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState(false);

  const [status, setStatus] = useState("Approved");
  const [allStatus, setallStatus] = useState("");

  const [statusChangedSuccess, setStatusChangedSuccess] = useState(false);

  const [showFilters, setShowFilters] = useState(true);
  const [Channel, setChannel] = useState('');

  //   console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilteredByDate, setisFilteredByDate] = useState(false);


  const [ExcelPageSize, setExcelPageSize] = useState(20000);
  const [ExcelArray, setExcelArray] = useState([]);


  const [loaddata, setLoaddata] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [Full_Name, setFull_Name] = useState('');
  const [GrandTotal, setGrandTotal] = useState('');

  const [paymentMode, setpaymentMode] = useState("All");

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setStatusChangedSuccess(false);
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: status,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
      PaymentMode: paymentMode,
      Channel: Channel,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/users/GetWithdrawalByStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code === 1) {
          setGrandTotal(response.data);
          //to remove all null values
          const filtered = response.data.ListofRecords.map((obj) => {
            const convertobj = { ...obj };
            for (let key in convertobj) {
              if (convertobj[key] === null) {
                convertobj[key] = "";
              }
            }
            return convertobj;
          });
          setLedger(filtered);
          setLedgerOld(filtered);
          // setLedger(response.data.ListofRecords);
          // setLedgerOld(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          // console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [
    pageNo, pageSize, isUpdated, StartDate, EndDate, paymentMode, status, statusChangedSuccess, Channel
  ]);

  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUpdated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  // update individual row status ...................
  const UpdateStatus = (e, ID) => {
    const val = e.target.value;
    const confirm = window.confirm(`Are you sure you want to proceed with ${val} status?`);
    if (confirm) {
      const updated = listOfLedger.map((row) => {
        if (row.ID === ID) {
          return { ...row, Status: val };
        } else return row;
      });
      setLedger(updated);

      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: ID,
      };

      // REJECTED................
      if (val === "Rejected") {

        var api_config = {
          method: "post",
          url: config.base_url + "/Users/RejectWithdrawlRequest",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(api_config)
          .then(function (response) {
            // console.log("rejected...................", response.data);
            if (response.data.status_code === 1) {
              // setStatusById("Rejected")
              setStatusChangedSuccess(true);
              toast.error("Cancled Successfully !", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (
              response.data.status_code === 0 &&
              response.data.status_message === "Failed to cancel the request!"
            ) {
              toast.error("Failed  To Reject !", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (
              (response.data.status_code === 0 &&
                response.data.status_message === "Invalid Access Key.") ||
              (response.data.status_code === 2 &&
                response.data.status_message === "Invalid Request Parameters")
            ) {
              navigate("/admin/login");
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      }


      //TRANSFER..................
      if (val === "Success") {
        // console.log(val);
        var api_config = {
          method: "post",
          url: config.base_url + "/users/TransferWithdrawlRequest",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        // console.log(row);
        // setStatusChangedSuccess(false);
        axios(api_config)
          .then(function (response) {
            // console.log("transfered data ", response.data);
            if (response.data.status_code === 1) {
              // setStatusById("Rejected")
              setStatusChangedSuccess(true);
              toast.success("Success Successfully !", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (
              (response.data.status_code === 0 &&
                response.data.status_message ===
                "Failed to Success the request!") ||
              (status === "Initiated" && val === "Success")
            ) {
              toast.error(
                "Success Failed ! First Approve it to Transfer.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            } else if (
              (response.data.status_code === 0 &&
                response.data.status_message === "Invalid Access Key.") ||
              (response.data.status_code === 0 &&
                response.data.status_message === "Invalid Request Parameters")
            ) {
              navigate("/admin/login");
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    }
  };
  // update all rows status ...................

  const UpdateAllStatus = (e, ID) => {

    const val = e.target.value;
    const confirm = window.confirm(`Are you sure you want to proceed with ${val} status?`);

    // if (confirm) {
    //   const inputsStudents = document.querySelectorAll("input.userCheck");
    //   const valuesSelected = [];
    //   inputsStudents.forEach((input) => {
    //     if (input.checked == true) {
    //       const value = parseInt(input.value);
    //       if (value > 0) {
    //         valuesSelected.push(value);
    //       }
    //     }
    //   });
    //   // console.log([...valuesSelected]);
    //   // setallStatus( e.target.value);
    //   let val = e.target.value;
    //   setallStatus(val);
    //   // console.log(allStatus);

    //   const updatedTableData = listOfLedger.map((row, index) => {
    //     if (valuesSelected.includes(row.ID)) {
    //       return {
    //         ...row,
    //         Status: val,
    //       };
    //     } else {
    //       return row;
    //     }
    //   });
    //   setLedger(updatedTableData);
    // }
    if (confirm) {
      if (listOfLedger.length > 0) {
        setLoading(true);
        listOfLedger.map((item, index) => {
          const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: item.ID,
          };

          // REJECTED................
          if (val === "Rejected") {

            var api_config = {
              method: "post",
              url: config.base_url + "/Users/RejectWithdrawlRequest",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios(api_config)
              .then(function (response) {
                if (response.data.status_code === 1) {
                  toast.success("Cancled Successfully !", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
                else {
                  setLoading(false);
                  return false;
                }
              })
              .catch(function (error) {
              });
          }

          // Success................

          if (val === "Success") {
            var api_config = {
              method: "post",
              url: config.base_url + "/users/TransferWithdrawlRequest",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios(api_config)
              .then(function (response) {
                if (response.data.status_code === 1) {
                  toast.success("Approved Successfully !", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
                else {
                  return false;
                }
              })
              .catch(function (error) {
              });
          }

        })
      }
    }
  };

  // filter by date ...................

  const filterByDate = (e) => {
    let date = moment(e.target.value).format("MM DD YYYY");

    if (date != "") {
      let member = ledgerOld.filter(
        //console.log(moment(member.Transaction_Date).format('MM DD YYYY, h:mm:ss a'))
        (member) => moment(member.Created_Date).format("MM DD YYYY") == date
      );

      setLedger(member);
    } else {
      setLedger(ledgerOld);
    }

    setisFilteredByDate(true);
  };
  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      // console.log("filtered ", filteredArray);

      setLedger(filteredArray);
    } else {
      setLedger(ledgerOld);
    }
  };

  // check and uncheck
  const handleCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("userCheck");

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        // console.log(item);
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>
              <div style={{ textAlign: "center" }}>
                <input
                  type="checkbox"
                  className="form-check-input userCheck"
                  value={item.ID}
                />
              </div>
            </td>
            <td>
              {moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}
              <br />
              {moment(item.Updated_Date).format("DD/MM/YYYY HH:mm")}
            </td>
            <td>{item.User_Name}</td>
            <td>{item.Channel}</td>
            <td>{item.PaymentID}</td>
            <td>{item.PaymentMode}</td>
            <td>{item.BankName}</td>
            <td>{item.AccountTitle}</td>
            <td className="">
              <div className="d-inline-flex justify-content-between align-items-center w-100">
                <p className="m-0">
                  {item.Address}
                </p>
                <button
                  className="button "
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(item.Address);
                    toast.success("Address Copied !", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }}
                >
                  <span>
                    <i
                      style={{ fontSize: "18px" }}
                      className="material-symbols-outlined"
                    >
                      content_copy
                    </i>
                  </span>
                </button>
              </div>
            </td>

            <td>{item.NetReturnableInCurrency}</td>
            <td>{item.Status}</td>


            <td>
              <div className="icon-input m-0">
                <select
                  className="form-select"
                  value={item.Status}
                  onChange={(e) => UpdateStatus(e, item.ID)}
                >
                  <option selected value="Approved">Approved</option>
                  <option value="Success"> Success</option>
                  <option value="Rejected"> Rejected</option>
                </select>
              </div>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  // update multiple rows  after select multiple rows
  const handleUpdateUsers = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const inputsStudents = document.querySelectorAll("input.userCheck");
    const valuesSelected = [];
    inputsStudents.forEach((input) => {
      if (input.checked == true) {
        const value = parseInt(input.value);
        if (value > 0) {
          valuesSelected.push(value);
        }
      }
    });
    const commaSeparatedValues = valuesSelected.join(", ");

    // console.log(commaSeparatedValues);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      selectedValues: commaSeparatedValues,
    };


    // REJECTED................
    if (allStatus === "Rejected") {
      setLoadingBtn(true)
      var api_config = {
        method: "post",
        url: config.base_url + "users/RejectAllWithdrawlRequest",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log(row);
      // setStatusChangedSuccess(false);
      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code === 1) {
            // setStatusById("Rejected")
            setStatusChangedSuccess(true);
            setLoadingBtn(false)
            toast.success("Cancled Successfully !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            response.data.status_code === 2 &&
            response.data.status_message === "Sorry, something went wrong."
          ) {
            toast.error("Failed  To Reject !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            (response.data.status_code === 0 &&
              response.data.status_message === "Invalid Access Key.") ||
            (response.data.status_code === 2 &&
              response.data.status_message === "Invalid Request Parameters")
          ) {
            navigate("/admin/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    // TRANSFERD................
    if (allStatus === "Success") {
      setLoadingBtn(true)
      var api_config = {
        method: "post",
        url: config.base_url + "users/SuccessAllWithdrawlRequest",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log(row);
      // setStatusChangedSuccess(false);
      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code === 1) {
            // setStatusById("Rejected")
            setStatusChangedSuccess(true);
            setLoadingBtn(false)

            toast.success("Success Successfully !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            response.data.status_code === 2 &&
            response.data.status_message === "Sorry, something went wrong."
          ) {
            toast.error("Failed  To Reject !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            (response.data.status_code === 0 &&
              response.data.status_message === "Invalid Access Key.") ||
            (response.data.status_code === 2 &&
              response.data.status_message === "Invalid Request Parameters")
          ) {
            navigate("/admin/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  const handleSubmitForCsv = (event) => {
    setLoaddata(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: ExcelPageSize,
      Status: status,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
      PaymentMode: paymentMode,
      Channel: Channel
    };

    var api_config = {
      method: "post",
      url: config.base_url + "/users/GetWithdrawalByStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        setLedgerforcvs(response.data.ListofRecords);
        let data = response.data.ListofRecords;
        let count = 0;
        const listOfLedgerNew = data.map(({ ...rest }) => ({
          Currency: rest.PaymentMode,
          Currency: rest.Channel,
          Address: rest.Address,
          Memo_Destination_Tag_etc: (""),
          Amount: NumberFormat6D(rest.NetReturnableInCurrency),
          Fiat_Amount: NumberFormat(rest.Amount - rest.FeeAmount),
          Fiat_Currency: "USD",
        }));

        const dataToConvert = {
          data: listOfLedgerNew,
          filename: "Approved_Withdrawal_List.csv",
          delimiter: ",",
          headers: [
            "Currency",
            "Address",
            "Extra ID (Memo Destination Tag etc.)",
            "Amount",
            "Fiat Amount",
            "Fiat Currency",
          ],
        };

        csvDownload(dataToConvert);
        setLoaddata(false);
      })
      .catch(function (error) {
        setLoaddata(false);
      });
  };


  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: ExcelPageSize,
      Status: status,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
      PaymentMode: paymentMode,
      Channel: Channel,
    };
    // console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "/users/GetWithdrawalByStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("response",response.data.ListofRecords)
        let data = response.data.ListofRecords;
        let Records = response.data.totalRecords;
        let count = 1;
        const listOfLedgerNew = data.map(({ ...item }) => ({
          Serial_No: Records - count++ + 1,
          CreatedDate: moment(item.Created_Date).format("DD/MM/YYYY HH:mm"),
          UpdatedDate: moment(item.Updated_Date).format("DD/MM/YYYY HH:mm"),
          UserName: item.User_Name,
          UserName: item.Channel,
          Transaction_ID: item.PaymentID,
          Payment_Mode: item.PaymentMode,
          Crypto_Address: item.Address,
          Receivable_Amount: item.NetReturnableInCurrency,
          Status: item.Status,
        }));
        setExcelArray(listOfLedgerNew)
      })
      .catch(function (error) {
      });
  }, [pageNo, ExcelPageSize, isUpdated, status, statusChangedSuccess, StartDate, EndDate, paymentMode, Channel]);

  return (
    <>
      <ReactTooltip />
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Approved Withdrawal List
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/withdrawal-dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Filters Menu */}
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3 align-items-end gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Select Payment Mode</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={paymentMode}
                          onChange={(e) => setpaymentMode(e.target.value)}
                        >
                          <option value="All"> All </option>
                          <option value="USDT">TRC20 - USDT</option>
                          <option value="TRX">TRON - TRX</option>
                          <option value="BTC">Bitcoin - BTC</option>
                          <option value="Bank Account">Bank Account</option>
                          <option value="Cash">Cash</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">SELECT CHANNEL</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={Channel}
                          onChange={(e) => setChannel(e.target.value)}
                        >
                          <option value="All"> All </option>
                          <AllFranchiseesDropDown />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <ul className="downloads">
                      <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                        <Link target="_blank" to={`/admin/withdrawal-dashboard/Approved-List-pdf/?StartDate=${StartDate}&EndDate=${EndDate}&paymentMode=${paymentMode}&Full_Name=${Full_Name}&status=${status}&Channel${Channel}`}>PDF</Link>
                      </li>
                      <li className="round-button" onClick={handleSubmitForCsv} data-tip={'Download as Csv'} data-place="bottom">
                        <span>CSV</span>
                      </li>
                      <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                        <JsonToExcel
                          title="EXCEL"
                          data={ExcelArray}
                          fileName="MAG INITIATED WITHDRAWAL LIST"
                          btnClassName="excel-button"
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="form_default position-relative">
                      <Form onSubmit={handleSearch}>
                        <div className="row align-items-end gy-3 gx-1">
                          <div className="col-md-7">
                            <div className="form-group search_box">
                              <label htmlFor="">Search Member</label>
                              <div className="inputwrap">
                                <input type="text" className="form-control"
                                  placeholder="Find by Username/Member ID/Email"
                                  value={Full_Name}
                                  onChange={(e) => setFull_Name(e.target.value)}
                                />
                                <span className="material-symbols-outlined">search</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="button w-100 button-primary input-height input-radius">
                              search
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleUpdateUsers}>
          <div className="card card-table mb-5 mt-3">
            <div className="card-inner responsive-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>select All</th>
                    <th>Created Date
                      <br />
                      Updated Date
                    </th>
                    <th>Username</th>
                    <th>Channel</th>
                    <th>Transaction ID</th>
                    <th style={{ minWidth: "140px" }}>Payment Mode</th>
                    <th>Bank Name</th>
                    <th>Account Title</th>
                    <th>Crypto Address / IBAN</th>
                    <th>Receivable amount</th>
                    <th>Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="filter-row">
                    <td></td>
                    <td>
                      <div style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          name="allSelect"
                          onChange={handleCheckAll}
                          className=" check"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="inputwrap">
                          <input
                            type="date"
                            onChange={filterByDate}
                            className="form-control"
                          />
                        </div>
                        {isFilteredByDate === true && (
                          <button
                            className="button p-0 bg-transparent"
                            onClick={() => {
                              setisFilteredByDate(false);
                              setLedger(ledgerOld);
                            }}
                          >
                            <span
                              style={{ lineHeight: "unset" }}
                              className="material-symbols-outlined"
                            >
                              close
                            </span>
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Username"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>

                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Channel"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>


                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Transaction ID"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Payment Mode"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Bank Name"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Account Title"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Crypto Address / IBAN"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>

                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Receivable Amount"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Status"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>


                    <td>
                      <select
                        className="form-select"
                        style={{ width: "10rem" }}
                        value={allStatus}
                        onChange={(e) => UpdateAllStatus(e)}
                      >
                        <option selected value="Approved">
                          Approved
                        </option>
                        <option value="Success">Success</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </td>
                  </tr>

                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="10">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}
                      {
                        listOfLedger != null && listOfLedger.length > 0 &&
                        <>
                          <tr style={{ borderButtom: "none !important" }}>
                            <th colSpan={9} className="border-bottom-0 pt-3"></th>
                            <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                            <th className="border-bottom-0 pt-3">
                              {paymentMode != "All" && (
                                <span>{NumberFormat(calculateColumnTotal(listOfLedger, 'NetReturnableInCurrency'))}</span>
                              )}
                            </th>
                            <th className="border-bottom-0 pt-3"></th>
                          </tr>
                          <tr className="">
                            <th className="pt-3" colSpan={9}></th>
                            <th className="text-end">Grand Total: </th>
                            <th>
                              {paymentMode != "All" && (
                                <span>{NumberFormat(GrandTotal.receiveableTotal)}</span>
                              )}
                            </th>
                            <td style={{ borderButtom: "1px solid !important" }}></td>

                          </tr>
                        </>
                      }

                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {loadingBtn ? (
            <div className="text-end">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <div className="text-end mb-3">
              <button type="submit" className="button button-primary">
                Update
              </button>
            </div>
          )}
        </form>

        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                        setExcelPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Approved;
