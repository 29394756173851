import React, { useState } from 'react';
import { ArrowTrendingUpIcon, CheckCircleIcon, ClockIcon, ExclamationCircleIcon, SparklesIcon, UsersIcon } from '@heroicons/react/24/outline';
import "../../../assets/css/circle.css";
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/24/solid';
function NetworkMember(props) {


    return (
        <>
            <li className={`level${props.level}`}>
                <div className='tree-card-wrap'>
                    <div className="tree-user-profile">
                        <img src={props.photo} alt="" />
                        <div className="info">
                            <h5>{props.name}</h5>
                            <div className="status">
                                <span>
                                    {props.KYCStatus === 'Under Review' ?
                                        <ClockIcon />
                                        :
                                        props.KYCStatus === 'Verified' ?
                                            <CheckBadgeIcon />
                                            :
                                            <ExclamationCircleIcon />

                                    }
                                    {/* {props.KYCStatus === "Verified" ?
                                        <CheckBadgeIcon /> : props.KYCStatus === "Not Verified" ?
                                            <XCircleIcon />
                                            :
                                            props.KYCStatus === "Under Review" ?
                                                <ExclamationCircleIcon />
                                                :
                                                ''
                                    } */}

                                </span>
                                <p>{props.username}</p>
                            </div>
                            <ul>
                                <li><label htmlFor="">{props.rank}</label></li>
                                <li><span>{props.MemberStatus}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className='rank-img d-none d-md-block'>
                        <div className="photo">
                            <img src={props.RankImg} alt='' />
                        </div>

                    </div>
                    <div className='tree-card-center'>
                        <ul>
                            <li>
                                <div className="icon pink">
                                    <UsersIcon />
                                </div>
                                <div className='tree-info'>
                                    <span>{props.TeamCount}</span>
                                    <p>Team</p>
                                </div>
                            </li>
                            <li>
                                <div className="icon orange">
                                    <SparklesIcon />
                                </div>
                                <div className='tree-info'>
                                    <span>PKR {props.amount}</span>
                                    <p>Self Sale</p>
                                </div>
                            </li>
                            <li>
                                <div className="icon red">
                                    <ArrowTrendingUpIcon />
                                </div>
                                <div className='tree-info'>
                                    <span>PKR {props.TeamInvestment}</span>
                                    <p>Team Sale </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="counting-div d-none d-md-flex">
                        {props.Count != 0 ?
                            <span>{props.Count == 1 ? '1st' : props.Count == 2 ? '2nd' : props.Count == 3 ? '3rd' : '4th'} Line</span>
                            :
                            ''
                        }
                    </div>
                    <div className="tree-card-end">
                        <ul>
                            <li className='team-point'>
                                <label htmlFor="">Pv</label><span>{props.amount > 500 ? (props.amount / 500) : '0'}</span>
                                <label htmlFor="">GPV</label><span>{props.TeamInvestment > 500 ? (props.TeamInvestment / 500) : '0'}</span>
                            </li>

                            <li>
                                <div className='level'>
                                    <label htmlFor="">Level</label>
                                    <span>{props.level}</span>
                                </div>
                                <img src={props.RankImg} className='d-md-none d-block' alt='' />
                                <div className="counting-div d-md-none d-block">
                                    {props.Count != 0 ?
                                        <span>{props.Count == 1 ? '1st' : props.Count == 2 ? '2nd' : props.Count == 3 ? '3rd' : '4th'} Line</span>
                                        :
                                        ''
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li >
        </>
    );
}

export default NetworkMember;