import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown";
import { calculateColumnTotal } from "../Dashboard/General/Util";
import { NumberFormat } from "../Dashboard/General/Functions";
import AdminRightsCheck from "./AdminRightsCheck";

function TotalMemberEarning(props) {
    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );

    const [showFilters, setShowFilters] = useState(true);

    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfRecord, setListOfRecord] = useState([]);
    const [packageName, setPackageName] = useState("");

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    let navigate = useNavigate();


    useEffect(() => {
        document.title = "Mag Holdings Admin";
        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            UserName: Full_Name,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetTotalMemberEarnings",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        // console.log(data);

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {

                    console.log("data", response.data);
                    setListOfRecord(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                }
                else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfRecord != null && listOfRecord.length > 0) {
            let count = 1;
            return listOfRecord.map((item, index) => {
                // console.log(item);
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{item.User_Name}</td>
                        <td>PKR {NumberFormat(item.Profit_Received)}</td>
                        <td>PKR {NumberFormat(item.AchievedAmount)}</td>
                        <td>PKR {NumberFormat(item.totalPaid)}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };
    return (
        <>
            <AdminRightsCheck />
            <div className="admin-content">

                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Total Member Earning
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <button type="button" className="button button-primary" onClick={() => window.history.back()}>
                                Back
                            </button>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row gy-3 align-items-end p-3">
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Username</th>
                                    <th>Binary</th>
                                    <th>Commodity</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}

                                        {
                                            listOfRecord != null && listOfRecord.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th colSpan={2} className="border-bottom-0 text-end pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfRecord, 'Profit_Received'))}</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfRecord, 'AchievedAmount'))}</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfRecord, 'totalPaid'))}</th>
                                                </tr>
                                                <tr className="" style={{ borderButtom: "1px solid !important" }}>
                                                    <th colSpan={2} className="text-end border-bottom-0">Grand Total: </th>
                                                    <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.binaryTotal)}</th>
                                                    <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.commodityTotal)}</th>
                                                    <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.totalReceived)}</th>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}

export default TotalMemberEarning