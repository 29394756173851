import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../Dashboard/General/Pagination";
import FranchiseLoginCheck from "./FranchiseLoginCheck";
import { Dropdown, Form, Modal } from "react-bootstrap";
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { NumberFormat } from "../Dashboard/General/Functions";
import { calculateColumnTotal } from "../Common/Util";


function FeeReceive(props) {


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [AgreementFeeLoading, setAgreementFeeLoading] = useState(false);
    const [PackageSecretID, setPackageSecretID] = useState('');
    const [SelectedPackageID, setSelectedPackageID] = useState('');
    const [SelectedPackage, setSelectedPackage] = useState('');
    const [SelectedUser, setSelectedUser] = useState('');
    const [Packagestatus, setPackagestatus] = useState('');
    const [AgreementStatus, setAgreementStatus] = useState('');
    const [MemberID, setMemberID] = useState('');
    const [ID, setID] = useState('');

    const [GrandTotal, setGrandTotal] = useState('');

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [listOfLedger, setLedger] = useState([]);
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [SearchMember, setSearchMember] = useState('');

    const [showFilters, setShowFilters] = useState(true);


    const [SecretID, setSecretID] = useState('');


    const [show, setShow] = useState(false);
    const [showUserDetail, setShowUserDetail] = useState(false);

    const handleCloseUserDetail = () => setShowUserDetail(false);
    const handleShowUserDetail = () => {
        setShow(false);
        setShowUserDetail(true);
    };

    const handleClose = () => setShow(false);

    const handleShow = (Secret_ID, package_ID, status, user_id, id, username, package_name) => {
        setShow(true);
        setPackageSecretID(Secret_ID);
        setSelectedPackageID(package_ID);
        setPackagestatus(status);
        setMemberID(user_id);
        setID(id);
        setSelectedUser(username);
        setSelectedPackage(package_name);
    };

    useEffect(() => {
        document.title = "MAG Franchise";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            SecretID: SecretID,
            UserName: SearchMember,
            startDate: StartDate,
            endDate: EndDate,
            AgreementStatus: AgreementStatus,
        };


        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetClaimedPackagesList",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    // console.log(response.data)
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate('franchise/login')
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [isUpdated, EndDate, StartDate, SecretID, pageSize, pageNo, AgreementStatus, AgreementFeeLoading]);

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Updated_Date).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.Name}</td>
                        <td>PKR {NumberFormat(item.Amount)}</td>
                        <td>{item.SecretID}</td>
                        <td>{item.AgreementClaim}</td>
                        <td>PKR 1000</td>
                        <td>{item.AgreementStatus}</td>
                        <td>
                            <button className="button button-primary"
                                onClick={(Secret_ID, package_ID, status, user_id, ID, username, package_name) =>
                                    handleShow(item.SecretID, item.PackageID, item.AgreementStatus, item.MemberID, item.ID, item.User_Name, item.Name)}>
                                Action
                            </button>
                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleAgreementFee = (id) => {
        const confirm = window.confirm(`Fee Receive?`);
        if (confirm) {
            setAgreementFeeLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Member_Type: 'Admin',
                ID: ID,
            };


            // console.log(data);

            var api_config = {
                method: "post",
                url: config.base_url + "Users/ReceiveAgreementFee",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    // console.log(response.data)
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setAgreementFeeLoading(false);
                        setPackageSecretID('');
                        setSelectedPackageID('');
                        setSelectedPackage('');
                        setSelectedUser('');
                        setPackagestatus('');
                        setMemberID('');
                        setID('');
                        setShowUserDetail(false);
                    }
                    else if (response.data.status_message === "Invalid Access Key.") {
                        navigate('/franchise/login')
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setAgreementFeeLoading(false);
                    }
                })
                .catch(function (error) {
                });
        }
    };
    const CancelAgreement = (id) => {
        const confirm = window.confirm(`Are You shower to Cancel Request?`);
        if (confirm) {
            setAgreementFeeLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "Users/CancelClaimAgreement",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            // console.log(data)
            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setAgreementFeeLoading(false);
                        setShow(false)
                    }
                    else if (response.data.status_message === "Invalid Access Key.") {
                        navigate('/franchise/login')
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setAgreementFeeLoading(false);
                    }
                })
                .catch(function (error) {
                });
        }
    };
    const handlePageChange = (page) => {
        setPageNo(page);
    };
    const nullValue = () => {
        setPackageSecretID('');
        setSelectedPackageID('');
        setSelectedPackage('');
        setSelectedUser('');
        setPackagestatus('');
        setMemberID('');
        setID('');
    };

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };


    return (
        <>
            <FranchiseLoginCheck />
            <ToastContainer />
            <Modal className="bs-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Process Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="button-row justify-content-between">
                        <button className="button button-primary" style={{ width: '47%' }} disabled={Packagestatus != "Claimed"} onClick={() => handleShowUserDetail()}>Receive Fee</button>
                        {Packagestatus != "Fee Received" ?
                            <button disabled style={{ width: '47%' }} className="button button-primary">
                                Print Agreemen
                            </button>
                            :
                            <Link target="_blank" className="button button-primary" style={{ width: '47%' }}
                                to={`/package/agreement/${SelectedPackageID}/?PackageID=${ID}&SecretID=${PackageSecretID}`}

                            >Print Agreement</Link>
                        }
                        {Packagestatus === "Fee Received" ? (
                            <Link
                                target="_blank"
                                to={`/franchise/print-address/${MemberID}`}
                                className="button button-primary"
                                style={{ width: '47%' }}
                            >
                                Print Address
                            </Link>
                        ) : (
                            <button disabled style={{ width: '47%' }} className="button button-primary">
                                Print Address
                            </button>
                        )}
                        {Packagestatus === "Fee Received" ?
                            <Dropdown style={{ width: '47%' }}>
                                <Dropdown.Toggle variant="success" className='button button-primary w-100' id="dropdown-basic" >
                                    Share Agreement
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/">
                                        <EmailShareButton url={`/package/agreement/${SelectedPackageID}/?PackageID=${SelectedPackageID}&SecretID=${PackageSecretID}`}>
                                            Email
                                        </EmailShareButton>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/">
                                        <FacebookShareButton url={`/package/agreement/${SelectedPackageID}/?PackageID=${SelectedPackageID}&SecretID=${PackageSecretID}`}>
                                            Facebook
                                        </FacebookShareButton>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/">
                                        <TwitterShareButton url={`/package/agreement/${SelectedPackageID}/?PackageID=${SelectedPackageID}&SecretID=${PackageSecretID}`}>
                                            Twitter
                                        </TwitterShareButton>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/">
                                        <WhatsappShareButton url={`/package/agreement/${SelectedPackageID}/?PackageID=${SelectedPackageID}&SecretID=${PackageSecretID}`}>
                                            Whatsapp
                                        </WhatsappShareButton>
                                    </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            <button disabled className="button button-primary" style={{ width: '47%' }}>Share Agreement</button>
                        }
                        <button className="button button-primary w-100" disabled={Packagestatus != "Claimed"} onClick={CancelAgreement}>Cancel Agreement</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="bs-modal" show={showUserDetail} onHide={handleCloseUserDetail} onExit={nullValue}>
                <Modal.Header closeButton>
                    <Modal.Title>Receive Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="icon-input">
                        <input type="text" placeholder='Username' readOnly />
                        <div className="icon">
                            <b>{SelectedUser}</b>
                        </div>
                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder='Package Name' readOnly />
                        <div className="icon">
                            <b>{SelectedPackage}</b>
                        </div>
                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder='Package ID' readOnly />
                        <div className="icon">
                            <b>{PackageSecretID}</b>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button button-primary" style={{ width: '47%' }} disabled={Packagestatus != "Claimed"} onClick={handleAgreementFee}>Fee Received</button>
                </Modal.Footer>
            </Modal>
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Process Agreement
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/franchise/dashboard">
                                 <button type="button" className="button button-outline-bs">
                Back
              </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Search by category</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={AgreementStatus}
                                                    onChange={(e) => setAgreementStatus(e.target.value)}
                                                >
                                                    <option value="" selected>All</option>
                                                    <option value="Fee Received">Fee Received</option>
                                                    <option value="Claimed"> Claimed</option>
                                                    <option value="Canceled">Canceled</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">Package Id</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Package ID"
                                                        value={SecretID}
                                                        onChange={(e) => setSecretID(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={SearchMember}
                                                                    onChange={(e) => setSearchMember(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Claimed Date</th>
                                    <th>Username</th>
                                    <th>Package Name</th>
                                    <th>Amount</th>
                                    <th>Package ID</th>
                                    <th>Claim Mode</th>
                                    <th>Fee</th>
                                    <th>Agreement Status</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="9">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <>
                                                <tr>
                                                    <th colSpan={6} className="border-bottom-0 pt-3"></th>
                                                    <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(listOfLedger.length * 1000)}</th>
                                                    <th className="border-bottom-0 pt-3"></th>
                                                    <th className="border-bottom-0 pt-3"></th>

                                                </tr>
                                                <tr className="">
                                                    <th className="pt-3" colSpan={6}></th>
                                                    <th className="text-end">Grand Total: </th>
                                                    <th>PKR {NumberFormat(totalRecords * 1000)}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    );
}

export default FeeReceive