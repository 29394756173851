import React, { useState, useEffect } from "react";
import axios from "axios";
import stamp_paper_img from "../../assets/images/partner/stamp_paper.png";
import stamp from "../../assets/images/partner/mag_stemp_png.png";
import moment from "moment";
import Config from "../../Config";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Agreement_AppleWatch = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const Full_Name = useState(localStorage.getItem("Full_Name"));
  const [loading, setLoading] = useState(false);
  const [AgreementData, setAgreementData] = useState([]);
  const [Kyc, setKyc] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);

    const PackageID = urlParams.get("PackageID");
    const SecretID = urlParams.get("SecretID");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: PackageID,
      SecretID: SecretID,
    };

    // console.log(data);
    var api_config = {
      method: "post",
      url: Config.base_url + "Package/GetPackagebySecretID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(data);

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          // console.log(response.data);
          setAgreementData(response.data.PackagePurchase);
          setKyc(response.data.KYC);
          setLoading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div className="page agreement-print">
          <img src={stamp_paper_img} alt="" />
          <div className="subpage">
            <h3 className="text-center"><b>Purchase Agreement</b></h3>
            <br />
            {/* <h5>Agreement Date: {moment(AgreementData?.BuyingDate).format(Config.date_format)}.</h5> */}
            <p>
              This Purchase Agreement is made at Islamabad on this <strong>{moment(AgreementData?.BuyingDate).format(Config.date_format)}</strong> by and Between <strong> MAG Ventures ( SMC-PVT ) LTD, Corporate
                Unique Identification No. ( 0250862 )</strong> having Address, <strong>"Lower Ground
                  Floor, Plaza No. 49, Spring North Commercial, Phase 7, Bahria
                  Town, Rawalpindi."</strong> (hereinafter called the <strong>"Owner/ Seller"</strong> ) of the
              one part.
              <br /> <div className="text-center"> <strong>AND</strong> </div>
              <strong>{Full_Name}</strong> S/O <strong>{Kyc.FatherName}</strong>
              {Kyc.DocumentType == "License" ? (
                <>
                  License No. <strong>{Kyc.LicenseNumber}</strong>
                </>
              ) : Kyc.DocumentType === "ID" ? (
                <>
                  CNIC No. <strong>{Kyc.IDCardNumber}</strong>
                </>
              ) : Kyc.DocumentType === "Passport" ? (
                <>
                  Passport No. <strong>{Kyc.PassportNumber}</strong>
                </>
              ) : (
                <></>
              )} Resident of <strong>{Kyc.Address} </strong>
              (hereinafter called the <strong>"Purchaser/ Stakeholder"</strong> ) of the other part.
            </p>
            <p>
              The objectives of this Agreement are to encourage expansion and
              diversification of trade between the Parties;
            </p>
            <ol>
              <li>
                The Stakeholder agrees to purchase Apple Watch (SE) for <strong>PKR 1
                  lac (1,00,000).</strong>
              </li>
              <li>
                This purchase agreement is dated{" "}
                <strong> {moment(AgreementData?.BuyingDate).format(Config.date_format)}{" "}</strong>
                and the product delivery date is{" "}
                <strong> {moment(AgreementData?.ClosingDate).format(Config.date_format)}.</strong>
              </li>
              <li>
                The Stakeholder agrees to pay the specified purchase amount for
                the chosen product that is <strong>Apple Watch (SE).</strong>
              </li>
              <li>
                The Company will generate revenue through <strong>dropshipping</strong>;
                different businesses of Mag Ventures and through the wholesale
                and retail trade of <strong>Apple Products</strong>, specifically <strong>Mobile phones </strong>
                in the <strong>Pakistan market</strong>, and will pay the profit to the
                Stakeholder on the <strong>1st date of every month,</strong> as per the time
                commitment specified for the <strong>chosen product.</strong> Profit distribution
                shall commence from the <strong>first month</strong> following the payment date.
              </li>
              <li>
                The Company will be responsible to deliver the product,i.e.
                <strong> Apple Watch (SE)</strong> on time and in case of any damage the company
                will recover it.
              </li>
              <li>
                Both Parties agree not to breach this contract under any
                circumstances. This agreement is <strong>governed by the laws of
                  Pakistan.</strong> Any disputes arising out of or in connection with this
                agreement shall be settled through arbitration in accordance
                with the rules of the Pakistan Arbitration Association. This
                agreement constitutes the entire understanding between the
                Parties and supersedes all prior agreements, negotiations, and
                discussions. Amendments to this agreement shall be valid only if
                made in writing and signed by both Parties. This agreement shall
                be binding upon and inure to the benefit of the Parties and
                their respective successors and assigns.
              </li>
            </ol>
            <p>
              This agreement constitutes the entire agreement between the
              parties with respect to the subject matter hereof, and supersedes
              all pre-existing agreements and understandings between them with
              respect thereto.
            </p>
            <p>
              This Agreement creates a joint venture relationship between the
              Company and the User.
            </p>
            <p>
              In witness whereof, the Parties have executed this agreement as of
              the date first above written
            </p>

            <div className="agreement-footer">
              <div className="left">
                <p className="lable">Name of Stakeholder</p>
                <div className="line">
                  <p>{Full_Name}</p>
                </div>
              </div>
              <div className="right">
                <p className="lable">Name of Company Representative</p>
                <div className="line">
                  <p>Syed Mohsin Sultan (CEO)</p>
                </div>
              </div>
            </div>
            <div className="agreement-footer">
              <div className="left">
                <p className="lable">
                  Signature of Stakeholder
                </p>
                <div className="line">
                  <p className="opacity-0">wwwwwwwwwwwwwww</p>
                </div>
              </div>
              <div className="right">
                <p className="lable">Signature of Company</p>
                <div className="line">
                  <p className="opacity-0">wwwwwwwwwwwwwww</p>
                </div>
              </div>
            </div>
            <div className="agreement-footer mt-5">
              <div className="left">

              </div>
              <div className="right">
                <img src={stamp} alt="stamp" className="stamp" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Agreement_AppleWatch;
